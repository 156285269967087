@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
:root,
[data-bs-theme=light] {
  --wlp-bs-blue: #0d6efd;
  --wlp-bs-indigo: #6610f2;
  --wlp-bs-purple: #6f42c1;
  --wlp-bs-pink: #d63384;
  --wlp-bs-red: #dc3545;
  --wlp-bs-orange: #fd7e14;
  --wlp-bs-yellow: #ffc107;
  --wlp-bs-green: #198754;
  --wlp-bs-teal: #20c997;
  --wlp-bs-cyan: #0dcaf0;
  --wlp-bs-black: #000;
  --wlp-bs-white: #fff;
  --wlp-bs-gray: #6c757d;
  --wlp-bs-gray-dark: #343a40;
  --wlp-bs-gray-100: #f8f9fa;
  --wlp-bs-gray-200: #e9ecef;
  --wlp-bs-gray-300: #dee2e6;
  --wlp-bs-gray-400: #ced4da;
  --wlp-bs-gray-500: #adb5bd;
  --wlp-bs-gray-600: #6c757d;
  --wlp-bs-gray-700: #495057;
  --wlp-bs-gray-800: #343a40;
  --wlp-bs-gray-900: #212529;
  --wlp-bs-primary: #f43369;
  --wlp-bs-secondary: #6c757d;
  --wlp-bs-success: #198754;
  --wlp-bs-info: #0dcaf0;
  --wlp-bs-warning: #ffc107;
  --wlp-bs-danger: #dc3545;
  --wlp-bs-light: #f8f9fa;
  --wlp-bs-dark: #212529;
  --wlp-bs-primary-rgb: 244, 51, 105;
  --wlp-bs-secondary-rgb: 108, 117, 125;
  --wlp-bs-success-rgb: 25, 135, 84;
  --wlp-bs-info-rgb: 13, 202, 240;
  --wlp-bs-warning-rgb: 255, 193, 7;
  --wlp-bs-danger-rgb: 220, 53, 69;
  --wlp-bs-light-rgb: 248, 249, 250;
  --wlp-bs-dark-rgb: 33, 37, 41;
  --wlp-bs-primary-text-emphasis: #62142a;
  --wlp-bs-secondary-text-emphasis: #2b2f32;
  --wlp-bs-success-text-emphasis: #0a3622;
  --wlp-bs-info-text-emphasis: #055160;
  --wlp-bs-warning-text-emphasis: #664d03;
  --wlp-bs-danger-text-emphasis: #58151c;
  --wlp-bs-light-text-emphasis: #495057;
  --wlp-bs-dark-text-emphasis: #495057;
  --wlp-bs-primary-bg-subtle: #fdd6e1;
  --wlp-bs-secondary-bg-subtle: #e2e3e5;
  --wlp-bs-success-bg-subtle: #d1e7dd;
  --wlp-bs-info-bg-subtle: #cff4fc;
  --wlp-bs-warning-bg-subtle: #fff3cd;
  --wlp-bs-danger-bg-subtle: #f8d7da;
  --wlp-bs-light-bg-subtle: #fcfcfd;
  --wlp-bs-dark-bg-subtle: #ced4da;
  --wlp-bs-primary-border-subtle: #fbadc3;
  --wlp-bs-secondary-border-subtle: #c4c8cb;
  --wlp-bs-success-border-subtle: #a3cfbb;
  --wlp-bs-info-border-subtle: #9eeaf9;
  --wlp-bs-warning-border-subtle: #ffe69c;
  --wlp-bs-danger-border-subtle: #f1aeb5;
  --wlp-bs-light-border-subtle: #e9ecef;
  --wlp-bs-dark-border-subtle: #adb5bd;
  --wlp-bs-white-rgb: 255, 255, 255;
  --wlp-bs-black-rgb: 0, 0, 0;
  --wlp-bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --wlp-bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --wlp-bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --wlp-bs-body-font-family: var(--wlp-bs-font-sans-serif);
  --wlp-bs-body-font-size: 1rem;
  --wlp-bs-body-font-weight: 400;
  --wlp-bs-body-line-height: 1.5;
  --wlp-bs-body-color: #212529;
  --wlp-bs-body-color-rgb: 33, 37, 41;
  --wlp-bs-body-bg: #fff;
  --wlp-bs-body-bg-rgb: 255, 255, 255;
  --wlp-bs-emphasis-color: #000;
  --wlp-bs-emphasis-color-rgb: 0, 0, 0;
  --wlp-bs-secondary-color: rgba(33, 37, 41, 0.75);
  --wlp-bs-secondary-color-rgb: 33, 37, 41;
  --wlp-bs-secondary-bg: #e9ecef;
  --wlp-bs-secondary-bg-rgb: 233, 236, 239;
  --wlp-bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --wlp-bs-tertiary-color-rgb: 33, 37, 41;
  --wlp-bs-tertiary-bg: #f8f9fa;
  --wlp-bs-tertiary-bg-rgb: 248, 249, 250;
  --wlp-bs-heading-color: inherit;
  --wlp-bs-link-color: #f43369;
  --wlp-bs-link-color-rgb: 244, 51, 105;
  --wlp-bs-link-decoration: underline;
  --wlp-bs-link-hover-color: #c32954;
  --wlp-bs-link-hover-color-rgb: 195, 41, 84;
  --wlp-bs-code-color: #d63384;
  --wlp-bs-highlight-color: #212529;
  --wlp-bs-highlight-bg: #fff3cd;
  --wlp-bs-border-width: 1px;
  --wlp-bs-border-style: solid;
  --wlp-bs-border-color: #dee2e6;
  --wlp-bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --wlp-bs-border-radius: 0.375rem;
  --wlp-bs-border-radius-sm: 0.25rem;
  --wlp-bs-border-radius-lg: 0.5rem;
  --wlp-bs-border-radius-xl: 1rem;
  --wlp-bs-border-radius-xxl: 2rem;
  --wlp-bs-border-radius-2xl: var(--wlp-bs-border-radius-xxl);
  --wlp-bs-border-radius-pill: 50rem;
  --wlp-bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --wlp-bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --wlp-bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --wlp-bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --wlp-bs-focus-ring-width: 0.25rem;
  --wlp-bs-focus-ring-opacity: 0.25;
  --wlp-bs-focus-ring-color: rgba(244, 51, 105, 0.25);
  --wlp-bs-form-valid-color: #198754;
  --wlp-bs-form-valid-border-color: #198754;
  --wlp-bs-form-invalid-color: #dc3545;
  --wlp-bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --wlp-bs-body-color: #dee2e6;
  --wlp-bs-body-color-rgb: 222, 226, 230;
  --wlp-bs-body-bg: #212529;
  --wlp-bs-body-bg-rgb: 33, 37, 41;
  --wlp-bs-emphasis-color: #fff;
  --wlp-bs-emphasis-color-rgb: 255, 255, 255;
  --wlp-bs-secondary-color: rgba(222, 226, 230, 0.75);
  --wlp-bs-secondary-color-rgb: 222, 226, 230;
  --wlp-bs-secondary-bg: #343a40;
  --wlp-bs-secondary-bg-rgb: 52, 58, 64;
  --wlp-bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --wlp-bs-tertiary-color-rgb: 222, 226, 230;
  --wlp-bs-tertiary-bg: #2b3035;
  --wlp-bs-tertiary-bg-rgb: 43, 48, 53;
  --wlp-bs-primary-text-emphasis: #f885a5;
  --wlp-bs-secondary-text-emphasis: #a7acb1;
  --wlp-bs-success-text-emphasis: #75b798;
  --wlp-bs-info-text-emphasis: #6edff6;
  --wlp-bs-warning-text-emphasis: #ffda6a;
  --wlp-bs-danger-text-emphasis: #ea868f;
  --wlp-bs-light-text-emphasis: #f8f9fa;
  --wlp-bs-dark-text-emphasis: #dee2e6;
  --wlp-bs-primary-bg-subtle: #310a15;
  --wlp-bs-secondary-bg-subtle: #161719;
  --wlp-bs-success-bg-subtle: #051b11;
  --wlp-bs-info-bg-subtle: #032830;
  --wlp-bs-warning-bg-subtle: #332701;
  --wlp-bs-danger-bg-subtle: #2c0b0e;
  --wlp-bs-light-bg-subtle: #343a40;
  --wlp-bs-dark-bg-subtle: #1a1d20;
  --wlp-bs-primary-border-subtle: #921f3f;
  --wlp-bs-secondary-border-subtle: #41464b;
  --wlp-bs-success-border-subtle: #0f5132;
  --wlp-bs-info-border-subtle: #087990;
  --wlp-bs-warning-border-subtle: #997404;
  --wlp-bs-danger-border-subtle: #842029;
  --wlp-bs-light-border-subtle: #495057;
  --wlp-bs-dark-border-subtle: #343a40;
  --wlp-bs-heading-color: inherit;
  --wlp-bs-link-color: #f885a5;
  --wlp-bs-link-hover-color: #f99db7;
  --wlp-bs-link-color-rgb: 248, 133, 165;
  --wlp-bs-link-hover-color-rgb: 249, 157, 183;
  --wlp-bs-code-color: #e685b5;
  --wlp-bs-highlight-color: #dee2e6;
  --wlp-bs-highlight-bg: #664d03;
  --wlp-bs-border-color: #495057;
  --wlp-bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --wlp-bs-form-valid-color: #75b798;
  --wlp-bs-form-valid-border-color: #75b798;
  --wlp-bs-form-invalid-color: #ea868f;
  --wlp-bs-form-invalid-border-color: #ea868f;
}

:where([data-cnx-widget], [data-cnx-container]) {
  /*!
   * Bootstrap Reboot v5.3.3 (https://getbootstrap.com/)
   * Copyright 2011-2024 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  /* rtl:begin:remove */
  /* rtl:end:remove */
}
:where([data-cnx-widget], [data-cnx-container]) :root,
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=light] {
  --wlp-bs-blue: #0d6efd;
  --wlp-bs-indigo: #6610f2;
  --wlp-bs-purple: #6f42c1;
  --wlp-bs-pink: #d63384;
  --wlp-bs-red: #dc3545;
  --wlp-bs-orange: #fd7e14;
  --wlp-bs-yellow: #ffc107;
  --wlp-bs-green: #198754;
  --wlp-bs-teal: #20c997;
  --wlp-bs-cyan: #0dcaf0;
  --wlp-bs-black: #000;
  --wlp-bs-white: #fff;
  --wlp-bs-gray: #6c757d;
  --wlp-bs-gray-dark: #343a40;
  --wlp-bs-gray-100: #f8f9fa;
  --wlp-bs-gray-200: #e9ecef;
  --wlp-bs-gray-300: #dee2e6;
  --wlp-bs-gray-400: #ced4da;
  --wlp-bs-gray-500: #adb5bd;
  --wlp-bs-gray-600: #6c757d;
  --wlp-bs-gray-700: #495057;
  --wlp-bs-gray-800: #343a40;
  --wlp-bs-gray-900: #212529;
  --wlp-bs-primary: #f43369;
  --wlp-bs-secondary: #6c757d;
  --wlp-bs-success: #198754;
  --wlp-bs-info: #0dcaf0;
  --wlp-bs-warning: #ffc107;
  --wlp-bs-danger: #dc3545;
  --wlp-bs-light: #f8f9fa;
  --wlp-bs-dark: #212529;
  --wlp-bs-primary-rgb: 244, 51, 105;
  --wlp-bs-secondary-rgb: 108, 117, 125;
  --wlp-bs-success-rgb: 25, 135, 84;
  --wlp-bs-info-rgb: 13, 202, 240;
  --wlp-bs-warning-rgb: 255, 193, 7;
  --wlp-bs-danger-rgb: 220, 53, 69;
  --wlp-bs-light-rgb: 248, 249, 250;
  --wlp-bs-dark-rgb: 33, 37, 41;
  --wlp-bs-primary-text-emphasis: #62142a;
  --wlp-bs-secondary-text-emphasis: #2b2f32;
  --wlp-bs-success-text-emphasis: #0a3622;
  --wlp-bs-info-text-emphasis: #055160;
  --wlp-bs-warning-text-emphasis: #664d03;
  --wlp-bs-danger-text-emphasis: #58151c;
  --wlp-bs-light-text-emphasis: #495057;
  --wlp-bs-dark-text-emphasis: #495057;
  --wlp-bs-primary-bg-subtle: #fdd6e1;
  --wlp-bs-secondary-bg-subtle: #e2e3e5;
  --wlp-bs-success-bg-subtle: #d1e7dd;
  --wlp-bs-info-bg-subtle: #cff4fc;
  --wlp-bs-warning-bg-subtle: #fff3cd;
  --wlp-bs-danger-bg-subtle: #f8d7da;
  --wlp-bs-light-bg-subtle: #fcfcfd;
  --wlp-bs-dark-bg-subtle: #ced4da;
  --wlp-bs-primary-border-subtle: #fbadc3;
  --wlp-bs-secondary-border-subtle: #c4c8cb;
  --wlp-bs-success-border-subtle: #a3cfbb;
  --wlp-bs-info-border-subtle: #9eeaf9;
  --wlp-bs-warning-border-subtle: #ffe69c;
  --wlp-bs-danger-border-subtle: #f1aeb5;
  --wlp-bs-light-border-subtle: #e9ecef;
  --wlp-bs-dark-border-subtle: #adb5bd;
  --wlp-bs-white-rgb: 255, 255, 255;
  --wlp-bs-black-rgb: 0, 0, 0;
  --wlp-bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --wlp-bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --wlp-bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --wlp-bs-body-font-family: var(--wlp-bs-font-sans-serif);
  --wlp-bs-body-font-size: 1rem;
  --wlp-bs-body-font-weight: 400;
  --wlp-bs-body-line-height: 1.5;
  --wlp-bs-body-color: #212529;
  --wlp-bs-body-color-rgb: 33, 37, 41;
  --wlp-bs-body-bg: #fff;
  --wlp-bs-body-bg-rgb: 255, 255, 255;
  --wlp-bs-emphasis-color: #000;
  --wlp-bs-emphasis-color-rgb: 0, 0, 0;
  --wlp-bs-secondary-color: rgba(33, 37, 41, 0.75);
  --wlp-bs-secondary-color-rgb: 33, 37, 41;
  --wlp-bs-secondary-bg: #e9ecef;
  --wlp-bs-secondary-bg-rgb: 233, 236, 239;
  --wlp-bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --wlp-bs-tertiary-color-rgb: 33, 37, 41;
  --wlp-bs-tertiary-bg: #f8f9fa;
  --wlp-bs-tertiary-bg-rgb: 248, 249, 250;
  --wlp-bs-heading-color: inherit;
  --wlp-bs-link-color: #f43369;
  --wlp-bs-link-color-rgb: 244, 51, 105;
  --wlp-bs-link-decoration: underline;
  --wlp-bs-link-hover-color: #c32954;
  --wlp-bs-link-hover-color-rgb: 195, 41, 84;
  --wlp-bs-code-color: #d63384;
  --wlp-bs-highlight-color: #212529;
  --wlp-bs-highlight-bg: #fff3cd;
  --wlp-bs-border-width: 1px;
  --wlp-bs-border-style: solid;
  --wlp-bs-border-color: #dee2e6;
  --wlp-bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --wlp-bs-border-radius: 0.375rem;
  --wlp-bs-border-radius-sm: 0.25rem;
  --wlp-bs-border-radius-lg: 0.5rem;
  --wlp-bs-border-radius-xl: 1rem;
  --wlp-bs-border-radius-xxl: 2rem;
  --wlp-bs-border-radius-2xl: var(--wlp-bs-border-radius-xxl);
  --wlp-bs-border-radius-pill: 50rem;
  --wlp-bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --wlp-bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --wlp-bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --wlp-bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --wlp-bs-focus-ring-width: 0.25rem;
  --wlp-bs-focus-ring-opacity: 0.25;
  --wlp-bs-focus-ring-color: rgba(244, 51, 105, 0.25);
  --wlp-bs-form-valid-color: #198754;
  --wlp-bs-form-valid-border-color: #198754;
  --wlp-bs-form-invalid-color: #dc3545;
  --wlp-bs-form-invalid-border-color: #dc3545;
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] {
  color-scheme: dark;
  --wlp-bs-body-color: #dee2e6;
  --wlp-bs-body-color-rgb: 222, 226, 230;
  --wlp-bs-body-bg: #212529;
  --wlp-bs-body-bg-rgb: 33, 37, 41;
  --wlp-bs-emphasis-color: #fff;
  --wlp-bs-emphasis-color-rgb: 255, 255, 255;
  --wlp-bs-secondary-color: rgba(222, 226, 230, 0.75);
  --wlp-bs-secondary-color-rgb: 222, 226, 230;
  --wlp-bs-secondary-bg: #343a40;
  --wlp-bs-secondary-bg-rgb: 52, 58, 64;
  --wlp-bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --wlp-bs-tertiary-color-rgb: 222, 226, 230;
  --wlp-bs-tertiary-bg: #2b3035;
  --wlp-bs-tertiary-bg-rgb: 43, 48, 53;
  --wlp-bs-primary-text-emphasis: #f885a5;
  --wlp-bs-secondary-text-emphasis: #a7acb1;
  --wlp-bs-success-text-emphasis: #75b798;
  --wlp-bs-info-text-emphasis: #6edff6;
  --wlp-bs-warning-text-emphasis: #ffda6a;
  --wlp-bs-danger-text-emphasis: #ea868f;
  --wlp-bs-light-text-emphasis: #f8f9fa;
  --wlp-bs-dark-text-emphasis: #dee2e6;
  --wlp-bs-primary-bg-subtle: #310a15;
  --wlp-bs-secondary-bg-subtle: #161719;
  --wlp-bs-success-bg-subtle: #051b11;
  --wlp-bs-info-bg-subtle: #032830;
  --wlp-bs-warning-bg-subtle: #332701;
  --wlp-bs-danger-bg-subtle: #2c0b0e;
  --wlp-bs-light-bg-subtle: #343a40;
  --wlp-bs-dark-bg-subtle: #1a1d20;
  --wlp-bs-primary-border-subtle: #921f3f;
  --wlp-bs-secondary-border-subtle: #41464b;
  --wlp-bs-success-border-subtle: #0f5132;
  --wlp-bs-info-border-subtle: #087990;
  --wlp-bs-warning-border-subtle: #997404;
  --wlp-bs-danger-border-subtle: #842029;
  --wlp-bs-light-border-subtle: #495057;
  --wlp-bs-dark-border-subtle: #343a40;
  --wlp-bs-heading-color: inherit;
  --wlp-bs-link-color: #f885a5;
  --wlp-bs-link-hover-color: #f99db7;
  --wlp-bs-link-color-rgb: 248, 133, 165;
  --wlp-bs-link-hover-color-rgb: 249, 157, 183;
  --wlp-bs-code-color: #e685b5;
  --wlp-bs-highlight-color: #dee2e6;
  --wlp-bs-highlight-bg: #664d03;
  --wlp-bs-border-color: #495057;
  --wlp-bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --wlp-bs-form-valid-color: #75b798;
  --wlp-bs-form-valid-border-color: #75b798;
  --wlp-bs-form-invalid-color: #ea868f;
  --wlp-bs-form-invalid-border-color: #ea868f;
}
:where([data-cnx-widget], [data-cnx-container]) *,
:where([data-cnx-widget], [data-cnx-container]) *::before,
:where([data-cnx-widget], [data-cnx-container]) *::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :where([data-cnx-widget], [data-cnx-container]) :root {
    scroll-behavior: smooth;
  }
}
:where([data-cnx-widget], [data-cnx-container]) body {
  margin: 0;
  font-family: var(--wlp-bs-body-font-family);
  font-size: var(--wlp-bs-body-font-size);
  font-weight: var(--wlp-bs-body-font-weight);
  line-height: var(--wlp-bs-body-line-height);
  color: var(--wlp-bs-body-color);
  text-align: var(--wlp-bs-body-text-align);
  background-color: var(--wlp-bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
:where([data-cnx-widget], [data-cnx-container]) hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--wlp-bs-border-width) solid;
  opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) h6, :where([data-cnx-widget], [data-cnx-container]) .h6, :where([data-cnx-widget], [data-cnx-container]) h5, :where([data-cnx-widget], [data-cnx-container]) .h5, :where([data-cnx-widget], [data-cnx-container]) h4, :where([data-cnx-widget], [data-cnx-container]) .h4, :where([data-cnx-widget], [data-cnx-container]) h3, :where([data-cnx-widget], [data-cnx-container]) .h3, :where([data-cnx-widget], [data-cnx-container]) h2, :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) h1, :where([data-cnx-widget], [data-cnx-container]) .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--wlp-bs-heading-color);
}
:where([data-cnx-widget], [data-cnx-container]) h1, :where([data-cnx-widget], [data-cnx-container]) .h1 {
  font-size: 2em;
}
:where([data-cnx-widget], [data-cnx-container]) h2, :where([data-cnx-widget], [data-cnx-container]) .h2 {
  font-size: 1.5em;
}
:where([data-cnx-widget], [data-cnx-container]) h3, :where([data-cnx-widget], [data-cnx-container]) .h3 {
  font-size: 1.17em;
}
:where([data-cnx-widget], [data-cnx-container]) h4, :where([data-cnx-widget], [data-cnx-container]) .h4 {
  font-size: 1em;
}
:where([data-cnx-widget], [data-cnx-container]) h5, :where([data-cnx-widget], [data-cnx-container]) .h5 {
  font-size: 1.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) h6, :where([data-cnx-widget], [data-cnx-container]) .h6 {
  font-size: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) p {
  margin-top: 0;
  margin-bottom: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}
:where([data-cnx-widget], [data-cnx-container]) address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) ol,
:where([data-cnx-widget], [data-cnx-container]) ul {
  padding-left: 2rem;
}
:where([data-cnx-widget], [data-cnx-container]) ol,
:where([data-cnx-widget], [data-cnx-container]) ul,
:where([data-cnx-widget], [data-cnx-container]) dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) ol ol,
:where([data-cnx-widget], [data-cnx-container]) ul ul,
:where([data-cnx-widget], [data-cnx-container]) ol ul,
:where([data-cnx-widget], [data-cnx-container]) ul ol {
  margin-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) dt {
  font-weight: 700;
}
:where([data-cnx-widget], [data-cnx-container]) dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) blockquote {
  margin: 0 0 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) b,
:where([data-cnx-widget], [data-cnx-container]) strong {
  font-weight: bolder;
}
:where([data-cnx-widget], [data-cnx-container]) small, :where([data-cnx-widget], [data-cnx-container]) .small {
  font-size: 0.875em;
}
:where([data-cnx-widget], [data-cnx-container]) mark, :where([data-cnx-widget], [data-cnx-container]) .mark {
  padding: 0.1875em;
  color: var(--wlp-bs-highlight-color);
  background-color: var(--wlp-bs-highlight-bg);
}
:where([data-cnx-widget], [data-cnx-container]) sub,
:where([data-cnx-widget], [data-cnx-container]) sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
:where([data-cnx-widget], [data-cnx-container]) sub {
  bottom: -0.25em;
}
:where([data-cnx-widget], [data-cnx-container]) sup {
  top: -0.5em;
}
:where([data-cnx-widget], [data-cnx-container]) a {
  color: rgba(var(--wlp-bs-link-color-rgb), var(--wlp-bs-link-opacity, 1));
  text-decoration: underline;
}
:where([data-cnx-widget], [data-cnx-container]) a:hover {
  --wlp-bs-link-color-rgb: var(--wlp-bs-link-hover-color-rgb);
}
:where([data-cnx-widget], [data-cnx-container]) a:not([href]):not([class]), :where([data-cnx-widget], [data-cnx-container]) a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
:where([data-cnx-widget], [data-cnx-container]) pre,
:where([data-cnx-widget], [data-cnx-container]) code,
:where([data-cnx-widget], [data-cnx-container]) kbd,
:where([data-cnx-widget], [data-cnx-container]) samp {
  font-family: var(--wlp-bs-font-monospace);
  font-size: 1em;
}
:where([data-cnx-widget], [data-cnx-container]) pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
:where([data-cnx-widget], [data-cnx-container]) pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
:where([data-cnx-widget], [data-cnx-container]) code {
  font-size: 0.875em;
  color: var(--wlp-bs-code-color);
  word-wrap: break-word;
}
a > :where([data-cnx-widget], [data-cnx-container]) code {
  color: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--wlp-bs-body-bg);
  background-color: var(--wlp-bs-body-color);
  border-radius: 0.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) kbd kbd {
  padding: 0;
  font-size: 1em;
}
:where([data-cnx-widget], [data-cnx-container]) figure {
  margin: 0 0 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) img,
:where([data-cnx-widget], [data-cnx-container]) svg {
  vertical-align: middle;
}
:where([data-cnx-widget], [data-cnx-container]) table {
  caption-side: bottom;
  border-collapse: collapse;
}
:where([data-cnx-widget], [data-cnx-container]) caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--wlp-bs-secondary-color);
  text-align: left;
}
:where([data-cnx-widget], [data-cnx-container]) th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
:where([data-cnx-widget], [data-cnx-container]) thead,
:where([data-cnx-widget], [data-cnx-container]) tbody,
:where([data-cnx-widget], [data-cnx-container]) tfoot,
:where([data-cnx-widget], [data-cnx-container]) tr,
:where([data-cnx-widget], [data-cnx-container]) td,
:where([data-cnx-widget], [data-cnx-container]) th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) label {
  display: inline-block;
}
:where([data-cnx-widget], [data-cnx-container]) button {
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) button:focus:not(:focus-visible) {
  outline: 0;
}
:where([data-cnx-widget], [data-cnx-container]) input,
:where([data-cnx-widget], [data-cnx-container]) button,
:where([data-cnx-widget], [data-cnx-container]) select,
:where([data-cnx-widget], [data-cnx-container]) optgroup,
:where([data-cnx-widget], [data-cnx-container]) textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) button,
:where([data-cnx-widget], [data-cnx-container]) select {
  text-transform: none;
}
:where([data-cnx-widget], [data-cnx-container]) [role=button] {
  cursor: pointer;
}
:where([data-cnx-widget], [data-cnx-container]) select {
  word-wrap: normal;
}
:where([data-cnx-widget], [data-cnx-container]) select:disabled {
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) [list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) button,
:where([data-cnx-widget], [data-cnx-container]) [type=button],
:where([data-cnx-widget], [data-cnx-container]) [type=reset],
:where([data-cnx-widget], [data-cnx-container]) [type=submit] {
  -webkit-appearance: button;
}
:where([data-cnx-widget], [data-cnx-container]) button:not(:disabled),
:where([data-cnx-widget], [data-cnx-container]) [type=button]:not(:disabled),
:where([data-cnx-widget], [data-cnx-container]) [type=reset]:not(:disabled),
:where([data-cnx-widget], [data-cnx-container]) [type=submit]:not(:disabled) {
  cursor: pointer;
}
:where([data-cnx-widget], [data-cnx-container]) ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) textarea {
  resize: vertical;
}
:where([data-cnx-widget], [data-cnx-container]) fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
:where([data-cnx-widget], [data-cnx-container]) legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) legend {
    font-size: 1.5rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) legend + * {
  clear: left;
}
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-fields-wrapper,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-text,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-minute,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-hour-field,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-day-field,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-month-field,
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-datetime-edit-year-field {
  padding: 0;
}
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-inner-spin-button {
  height: auto;
}
:where([data-cnx-widget], [data-cnx-container]) [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-search-decoration {
  -webkit-appearance: none;
}
:where([data-cnx-widget], [data-cnx-container]) ::-webkit-color-swatch-wrapper {
  padding: 0;
}
:where([data-cnx-widget], [data-cnx-container]) ::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}
:where([data-cnx-widget], [data-cnx-container]) output {
  display: inline-block;
}
:where([data-cnx-widget], [data-cnx-container]) iframe {
  border: 0;
}
:where([data-cnx-widget], [data-cnx-container]) summary {
  display: list-item;
  cursor: pointer;
}
:where([data-cnx-widget], [data-cnx-container]) progress {
  vertical-align: baseline;
}
:where([data-cnx-widget], [data-cnx-container]) [hidden] {
  display: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
:where([data-cnx-widget], [data-cnx-container]) .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-1 {
    font-size: 5rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-2 {
    font-size: 4.5rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-3 {
    font-size: 4rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-4 {
    font-size: 3.5rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-5 {
    font-size: 3rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .display-6 {
    font-size: 2.5rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .list-unstyled {
  padding-left: 0;
  list-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) .list-inline {
  padding-left: 0;
  list-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) .list-inline-item {
  display: inline-block;
}
:where([data-cnx-widget], [data-cnx-container]) .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
:where([data-cnx-widget], [data-cnx-container]) .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) .blockquote > :last-child {
  margin-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
:where([data-cnx-widget], [data-cnx-container]) .blockquote-footer::before {
  content: "— ";
}
:where([data-cnx-widget], [data-cnx-container]) .img-fluid {
  max-width: 100%;
  height: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .img-thumbnail {
  padding: 0.25rem;
  background-color: var(--wlp-bs-body-bg);
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-border-color);
  border-radius: var(--wlp-bs-border-radius);
  max-width: 100%;
  height: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .figure {
  display: inline-block;
}
:where([data-cnx-widget], [data-cnx-container]) .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .figure-caption {
  font-size: 0.875em;
  color: var(--wlp-bs-secondary-color);
}
:where([data-cnx-widget], [data-cnx-container]) .container,
:where([data-cnx-widget], [data-cnx-container]) .container-fluid,
:where([data-cnx-widget], [data-cnx-container]) .container-xxl,
:where([data-cnx-widget], [data-cnx-container]) .container-xl,
:where([data-cnx-widget], [data-cnx-container]) .container-lg,
:where([data-cnx-widget], [data-cnx-container]) .container-md,
:where([data-cnx-widget], [data-cnx-container]) .container-sm {
  --wlp-bs-gutter-x: 1.5rem;
  --wlp-bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--wlp-bs-gutter-x) * 0.5);
  padding-left: calc(var(--wlp-bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .container-sm, :where([data-cnx-widget], [data-cnx-container]) .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .container-md, :where([data-cnx-widget], [data-cnx-container]) .container-sm, :where([data-cnx-widget], [data-cnx-container]) .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .container-lg, :where([data-cnx-widget], [data-cnx-container]) .container-md, :where([data-cnx-widget], [data-cnx-container]) .container-sm, :where([data-cnx-widget], [data-cnx-container]) .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .container-xl, :where([data-cnx-widget], [data-cnx-container]) .container-lg, :where([data-cnx-widget], [data-cnx-container]) .container-md, :where([data-cnx-widget], [data-cnx-container]) .container-sm, :where([data-cnx-widget], [data-cnx-container]) .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .container-xxl, :where([data-cnx-widget], [data-cnx-container]) .container-xl, :where([data-cnx-widget], [data-cnx-container]) .container-lg, :where([data-cnx-widget], [data-cnx-container]) .container-md, :where([data-cnx-widget], [data-cnx-container]) .container-sm, :where([data-cnx-widget], [data-cnx-container]) .container {
    max-width: 1320px;
  }
}
:where([data-cnx-widget], [data-cnx-container]) :root {
  --wlp-bs-breakpoint-xs: 0;
  --wlp-bs-breakpoint-sm: 576px;
  --wlp-bs-breakpoint-md: 768px;
  --wlp-bs-breakpoint-lg: 992px;
  --wlp-bs-breakpoint-xl: 1200px;
  --wlp-bs-breakpoint-xxl: 1400px;
}
:where([data-cnx-widget], [data-cnx-container]) .row {
  --wlp-bs-gutter-x: 1.5rem;
  --wlp-bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--wlp-bs-gutter-y));
  margin-right: calc(-0.5 * var(--wlp-bs-gutter-x));
  margin-left: calc(-0.5 * var(--wlp-bs-gutter-x));
}
:where([data-cnx-widget], [data-cnx-container]) .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--wlp-bs-gutter-x) * 0.5);
  padding-left: calc(var(--wlp-bs-gutter-x) * 0.5);
  margin-top: var(--wlp-bs-gutter-y);
}
:where([data-cnx-widget], [data-cnx-container]) .col {
  flex: 1 0 0%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
:where([data-cnx-widget], [data-cnx-container]) .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-auto {
  flex: 0 0 auto;
  width: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-1 {
  margin-left: 8.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-2 {
  margin-left: 16.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-3 {
  margin-left: 25%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-4 {
  margin-left: 33.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-5 {
  margin-left: 41.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-6 {
  margin-left: 50%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-7 {
  margin-left: 58.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-8 {
  margin-left: 66.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-9 {
  margin-left: 75%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-10 {
  margin-left: 83.33333333%;
}
:where([data-cnx-widget], [data-cnx-container]) .offset-11 {
  margin-left: 91.66666667%;
}
:where([data-cnx-widget], [data-cnx-container]) .g-0,
:where([data-cnx-widget], [data-cnx-container]) .gx-0 {
  --wlp-bs-gutter-x: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .g-0,
:where([data-cnx-widget], [data-cnx-container]) .gy-0 {
  --wlp-bs-gutter-y: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .g-1,
:where([data-cnx-widget], [data-cnx-container]) .gx-1 {
  --wlp-bs-gutter-x: 0.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-1,
:where([data-cnx-widget], [data-cnx-container]) .gy-1 {
  --wlp-bs-gutter-y: 0.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-2,
:where([data-cnx-widget], [data-cnx-container]) .gx-2 {
  --wlp-bs-gutter-x: 0.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-2,
:where([data-cnx-widget], [data-cnx-container]) .gy-2 {
  --wlp-bs-gutter-y: 0.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-3,
:where([data-cnx-widget], [data-cnx-container]) .gx-3 {
  --wlp-bs-gutter-x: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-3,
:where([data-cnx-widget], [data-cnx-container]) .gy-3 {
  --wlp-bs-gutter-y: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-4,
:where([data-cnx-widget], [data-cnx-container]) .gx-4 {
  --wlp-bs-gutter-x: 1.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-4,
:where([data-cnx-widget], [data-cnx-container]) .gy-4 {
  --wlp-bs-gutter-y: 1.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-5,
:where([data-cnx-widget], [data-cnx-container]) .gx-5 {
  --wlp-bs-gutter-x: 3rem;
}
:where([data-cnx-widget], [data-cnx-container]) .g-5,
:where([data-cnx-widget], [data-cnx-container]) .gy-5 {
  --wlp-bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .col-sm {
    flex: 1 0 0%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-0 {
    margin-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-3 {
    margin-left: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-6 {
    margin-left: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-9 {
    margin-left: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-0,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-0 {
    --wlp-bs-gutter-x: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-0,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-0 {
    --wlp-bs-gutter-y: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-1,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-1 {
    --wlp-bs-gutter-x: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-1,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-1 {
    --wlp-bs-gutter-y: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-2,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-2 {
    --wlp-bs-gutter-x: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-2,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-2 {
    --wlp-bs-gutter-y: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-3,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-3 {
    --wlp-bs-gutter-x: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-3,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-3 {
    --wlp-bs-gutter-y: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-4,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-4 {
    --wlp-bs-gutter-x: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-4,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-4 {
    --wlp-bs-gutter-y: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-5,
  :where([data-cnx-widget], [data-cnx-container]) .gx-sm-5 {
    --wlp-bs-gutter-x: 3rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-sm-5,
  :where([data-cnx-widget], [data-cnx-container]) .gy-sm-5 {
    --wlp-bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .col-md {
    flex: 1 0 0%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-0 {
    margin-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-1 {
    margin-left: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-2 {
    margin-left: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-3 {
    margin-left: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-4 {
    margin-left: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-5 {
    margin-left: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-6 {
    margin-left: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-7 {
    margin-left: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-8 {
    margin-left: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-9 {
    margin-left: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-10 {
    margin-left: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-md-11 {
    margin-left: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-0,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-0 {
    --wlp-bs-gutter-x: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-0,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-0 {
    --wlp-bs-gutter-y: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-1,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-1 {
    --wlp-bs-gutter-x: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-1,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-1 {
    --wlp-bs-gutter-y: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-2,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-2 {
    --wlp-bs-gutter-x: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-2,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-2 {
    --wlp-bs-gutter-y: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-3,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-3 {
    --wlp-bs-gutter-x: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-3,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-3 {
    --wlp-bs-gutter-y: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-4,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-4 {
    --wlp-bs-gutter-x: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-4,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-4 {
    --wlp-bs-gutter-y: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-5,
  :where([data-cnx-widget], [data-cnx-container]) .gx-md-5 {
    --wlp-bs-gutter-x: 3rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-md-5,
  :where([data-cnx-widget], [data-cnx-container]) .gy-md-5 {
    --wlp-bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .col-lg {
    flex: 1 0 0%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-0 {
    margin-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-3 {
    margin-left: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-6 {
    margin-left: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-9 {
    margin-left: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-0,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-0 {
    --wlp-bs-gutter-x: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-0,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-0 {
    --wlp-bs-gutter-y: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-1,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-1 {
    --wlp-bs-gutter-x: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-1,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-1 {
    --wlp-bs-gutter-y: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-2,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-2 {
    --wlp-bs-gutter-x: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-2,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-2 {
    --wlp-bs-gutter-y: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-3,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-3 {
    --wlp-bs-gutter-x: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-3,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-3 {
    --wlp-bs-gutter-y: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-4,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-4 {
    --wlp-bs-gutter-x: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-4,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-4 {
    --wlp-bs-gutter-y: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-5,
  :where([data-cnx-widget], [data-cnx-container]) .gx-lg-5 {
    --wlp-bs-gutter-x: 3rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-lg-5,
  :where([data-cnx-widget], [data-cnx-container]) .gy-lg-5 {
    --wlp-bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .col-xl {
    flex: 1 0 0%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-0 {
    margin-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-3 {
    margin-left: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-6 {
    margin-left: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-9 {
    margin-left: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-0,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-0 {
    --wlp-bs-gutter-x: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-0,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-0 {
    --wlp-bs-gutter-y: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-1,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-1 {
    --wlp-bs-gutter-x: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-1,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-1 {
    --wlp-bs-gutter-y: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-2,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-2 {
    --wlp-bs-gutter-x: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-2,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-2 {
    --wlp-bs-gutter-y: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-3,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-3 {
    --wlp-bs-gutter-x: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-3,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-3 {
    --wlp-bs-gutter-y: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-4,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-4 {
    --wlp-bs-gutter-x: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-4,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-4 {
    --wlp-bs-gutter-y: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-5,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xl-5 {
    --wlp-bs-gutter-x: 3rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xl-5,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xl-5 {
    --wlp-bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl {
    flex: 1 0 0%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-0 {
    margin-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-3 {
    margin-left: 25%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-6 {
    margin-left: 50%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-9 {
    margin-left: 75%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-0,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-0 {
    --wlp-bs-gutter-x: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-0,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-0 {
    --wlp-bs-gutter-y: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-1,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-1 {
    --wlp-bs-gutter-x: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-1,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-1 {
    --wlp-bs-gutter-y: 0.25rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-2,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-2 {
    --wlp-bs-gutter-x: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-2,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-2 {
    --wlp-bs-gutter-y: 0.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-3,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-3 {
    --wlp-bs-gutter-x: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-3,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-3 {
    --wlp-bs-gutter-y: 1rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-4,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-4 {
    --wlp-bs-gutter-x: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-4,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-4 {
    --wlp-bs-gutter-y: 1.5rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-5,
  :where([data-cnx-widget], [data-cnx-container]) .gx-xxl-5 {
    --wlp-bs-gutter-x: 3rem;
  }
  :where([data-cnx-widget], [data-cnx-container]) .g-xxl-5,
  :where([data-cnx-widget], [data-cnx-container]) .gy-xxl-5 {
    --wlp-bs-gutter-y: 3rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .table {
  --wlp-bs-table-color-type: initial;
  --wlp-bs-table-bg-type: initial;
  --wlp-bs-table-color-state: initial;
  --wlp-bs-table-bg-state: initial;
  --wlp-bs-table-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-table-bg: var(--wlp-bs-body-bg);
  --wlp-bs-table-border-color: var(--wlp-bs-border-color);
  --wlp-bs-table-accent-bg: transparent;
  --wlp-bs-table-striped-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-table-striped-bg: rgba(var(--wlp-bs-emphasis-color-rgb), 0.05);
  --wlp-bs-table-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-table-active-bg: rgba(var(--wlp-bs-emphasis-color-rgb), 0.1);
  --wlp-bs-table-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-table-hover-bg: rgba(var(--wlp-bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--wlp-bs-table-color-state, var(--wlp-bs-table-color-type, var(--wlp-bs-table-color)));
  background-color: var(--wlp-bs-table-bg);
  border-bottom-width: var(--wlp-bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--wlp-bs-table-bg-state, var(--wlp-bs-table-bg-type, var(--wlp-bs-table-accent-bg)));
}
:where([data-cnx-widget], [data-cnx-container]) .table > tbody {
  vertical-align: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .table > thead {
  vertical-align: bottom;
}
:where([data-cnx-widget], [data-cnx-container]) .table-group-divider {
  border-top: calc(var(--wlp-bs-border-width) * 2) solid currentcolor;
}
:where([data-cnx-widget], [data-cnx-container]) .caption-top {
  caption-side: top;
}
:where([data-cnx-widget], [data-cnx-container]) .table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) .table-bordered > :not(caption) > * {
  border-width: var(--wlp-bs-border-width) 0;
}
:where([data-cnx-widget], [data-cnx-container]) .table-bordered > :not(caption) > * > * {
  border-width: 0 var(--wlp-bs-border-width);
}
:where([data-cnx-widget], [data-cnx-container]) .table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .table-borderless > :not(:first-child) {
  border-top-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .table-striped > tbody > tr:nth-of-type(odd) > * {
  --wlp-bs-table-color-type: var(--wlp-bs-table-striped-color);
  --wlp-bs-table-bg-type: var(--wlp-bs-table-striped-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --wlp-bs-table-color-type: var(--wlp-bs-table-striped-color);
  --wlp-bs-table-bg-type: var(--wlp-bs-table-striped-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .table-active {
  --wlp-bs-table-color-state: var(--wlp-bs-table-active-color);
  --wlp-bs-table-bg-state: var(--wlp-bs-table-active-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .table-hover > tbody > tr:hover > * {
  --wlp-bs-table-color-state: var(--wlp-bs-table-hover-color);
  --wlp-bs-table-bg-state: var(--wlp-bs-table-hover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .table-primary {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #fdd6e1;
  --wlp-bs-table-border-color: #caabb4;
  --wlp-bs-table-striped-bg: #f0cbd6;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #e4c1cb;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #eac6d0;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-secondary {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #e2e3e5;
  --wlp-bs-table-border-color: #b5b6b7;
  --wlp-bs-table-striped-bg: #d7d8da;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #cbccce;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #d1d2d4;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-success {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #d1e7dd;
  --wlp-bs-table-border-color: #a7b9b1;
  --wlp-bs-table-striped-bg: #c7dbd2;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #bcd0c7;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #c1d6cc;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-info {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #cff4fc;
  --wlp-bs-table-border-color: #a6c3ca;
  --wlp-bs-table-striped-bg: #c5e8ef;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #badce3;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #bfe2e9;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-warning {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #fff3cd;
  --wlp-bs-table-border-color: #ccc2a4;
  --wlp-bs-table-striped-bg: #f2e7c3;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #e6dbb9;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #ece1be;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-danger {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #f8d7da;
  --wlp-bs-table-border-color: #c6acae;
  --wlp-bs-table-striped-bg: #eccccf;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #dfc2c4;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #e5c7ca;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-light {
  --wlp-bs-table-color: #000;
  --wlp-bs-table-bg: #f8f9fa;
  --wlp-bs-table-border-color: #c6c7c8;
  --wlp-bs-table-striped-bg: #ecedee;
  --wlp-bs-table-striped-color: #000;
  --wlp-bs-table-active-bg: #dfe0e1;
  --wlp-bs-table-active-color: #000;
  --wlp-bs-table-hover-bg: #e5e6e7;
  --wlp-bs-table-hover-color: #000;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-dark {
  --wlp-bs-table-color: #fff;
  --wlp-bs-table-bg: #212529;
  --wlp-bs-table-border-color: #4d5154;
  --wlp-bs-table-striped-bg: #2c3034;
  --wlp-bs-table-striped-color: #fff;
  --wlp-bs-table-active-bg: #373b3e;
  --wlp-bs-table-active-color: #fff;
  --wlp-bs-table-hover-bg: #323539;
  --wlp-bs-table-hover-color: #fff;
  color: var(--wlp-bs-table-color);
  border-color: var(--wlp-bs-table-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-label {
  margin-bottom: 0.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .col-form-label {
  padding-top: calc(0.375rem + var(--wlp-bs-border-width));
  padding-bottom: calc(0.375rem + var(--wlp-bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
:where([data-cnx-widget], [data-cnx-container]) .col-form-label-lg {
  padding-top: calc(0.5rem + var(--wlp-bs-border-width));
  padding-bottom: calc(0.5rem + var(--wlp-bs-border-width));
  font-size: 1.25rem;
}
:where([data-cnx-widget], [data-cnx-container]) .col-form-label-sm {
  padding-top: calc(0.25rem + var(--wlp-bs-border-width));
  padding-bottom: calc(0.25rem + var(--wlp-bs-border-width));
  font-size: 0.875rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--wlp-bs-secondary-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--wlp-bs-body-color);
  appearance: none;
  background-color: var(--wlp-bs-body-bg);
  background-clip: padding-box;
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-border-color);
  border-radius: var(--wlp-bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-control {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-control[type=file] {
  overflow: hidden;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control:focus {
  color: var(--wlp-bs-body-color);
  background-color: var(--wlp-bs-body-bg);
  border-color: #fa99b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control::placeholder {
  color: var(--wlp-bs-secondary-color);
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control:disabled {
  background-color: var(--wlp-bs-secondary-bg);
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--wlp-bs-body-color);
  background-color: var(--wlp-bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--wlp-bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-control::file-selector-button {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--wlp-bs-secondary-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--wlp-bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--wlp-bs-border-width) 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-plaintext:focus {
  outline: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-plaintext.form-control-sm, :where([data-cnx-widget], [data-cnx-container]) .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--wlp-bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--wlp-bs-border-radius-sm);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--wlp-bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--wlp-bs-border-radius-lg);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--wlp-bs-border-width) * 2));
}
:where([data-cnx-widget], [data-cnx-container]) textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--wlp-bs-border-width) * 2));
}
:where([data-cnx-widget], [data-cnx-container]) textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--wlp-bs-border-width) * 2));
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--wlp-bs-border-width) * 2));
  padding: 0.375rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--wlp-bs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--wlp-bs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--wlp-bs-border-width) * 2));
}
:where([data-cnx-widget], [data-cnx-container]) .form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--wlp-bs-border-width) * 2));
}
:where([data-cnx-widget], [data-cnx-container]) .form-select {
  --wlp-bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--wlp-bs-body-color);
  appearance: none;
  background-color: var(--wlp-bs-body-bg);
  background-image: var(--wlp-bs-form-select-bg-img), var(--wlp-bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-border-color);
  border-radius: var(--wlp-bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-select {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-select:focus {
  border-color: #fa99b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .form-select[multiple], :where([data-cnx-widget], [data-cnx-container]) .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
:where([data-cnx-widget], [data-cnx-container]) .form-select:disabled {
  background-color: var(--wlp-bs-secondary-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--wlp-bs-body-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--wlp-bs-border-radius-sm);
}
:where([data-cnx-widget], [data-cnx-container]) .form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--wlp-bs-border-radius-lg);
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .form-select {
  --wlp-bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input {
  --wlp-bs-form-check-bg: var(--wlp-bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--wlp-bs-form-check-bg);
  background-image: var(--wlp-bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-border-color);
  print-color-adjust: exact;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input[type=radio] {
  border-radius: 50%;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:active {
  filter: brightness(90%);
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:focus {
  border-color: #fa99b4;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:checked {
  background-color: #f43369;
  border-color: #f43369;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:checked[type=checkbox] {
  --wlp-bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:checked[type=radio] {
  --wlp-bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input[type=checkbox]:indeterminate {
  background-color: #f43369;
  border-color: #f43369;
  --wlp-bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-input[disabled] ~ .form-check-label, :where([data-cnx-widget], [data-cnx-container]) .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch {
  padding-left: 2.5em;
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch .form-check-input {
  --wlp-bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--wlp-bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-switch .form-check-input {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch .form-check-input:focus {
  --wlp-bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fa99b4'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch .form-check-input:checked {
  background-position: right center;
  --wlp-bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-check[disabled] + .btn, :where([data-cnx-widget], [data-cnx-container]) .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --wlp-bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:focus {
  outline: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-moz-focus-outer {
  border: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #f43369;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-webkit-slider-thumb:active {
  background-color: #fcc2d2;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--wlp-bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #f43369;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-range::-moz-range-thumb {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-moz-range-thumb:active {
  background-color: #fcc2d2;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--wlp-bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:disabled {
  pointer-events: none;
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:disabled::-webkit-slider-thumb {
  background-color: var(--wlp-bs-secondary-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-range:disabled::-moz-range-thumb {
  background-color: var(--wlp-bs-secondary-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating {
  position: relative;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--wlp-bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--wlp-bs-border-width) * 2));
  line-height: 1.25;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--wlp-bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .form-floating > label {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control::placeholder,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:focus, :where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:not(:placeholder-shown),
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext:focus,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:-webkit-autofill,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:focus ~ label,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:not(:placeholder-shown) ~ label,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext ~ label,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-select ~ label {
  color: rgba(var(--wlp-bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:focus ~ label::after,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext ~ label::after,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--wlp-bs-body-bg);
  border-radius: var(--wlp-bs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--wlp-bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control-plaintext ~ label {
  border-width: var(--wlp-bs-border-width) 0;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > :disabled ~ label,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
:where([data-cnx-widget], [data-cnx-container]) .form-floating > :disabled ~ label::after,
:where([data-cnx-widget], [data-cnx-container]) .form-floating > .form-control:disabled ~ label::after {
  background-color: var(--wlp-bs-secondary-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control:focus,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select:focus,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:focus-within {
  z-index: 5;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group .btn {
  position: relative;
  z-index: 2;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group .btn:focus {
  z-index: 5;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--wlp-bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--wlp-bs-tertiary-bg);
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-border-color);
  border-radius: var(--wlp-bs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .input-group-lg > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group-lg > .form-select,
:where([data-cnx-widget], [data-cnx-container]) .input-group-lg > .input-group-text,
:where([data-cnx-widget], [data-cnx-container]) .input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--wlp-bs-border-radius-lg);
}
:where([data-cnx-widget], [data-cnx-container]) .input-group-sm > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group-sm > .form-select,
:where([data-cnx-widget], [data-cnx-container]) .input-group-sm > .input-group-text,
:where([data-cnx-widget], [data-cnx-container]) .input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--wlp-bs-border-radius-sm);
}
:where([data-cnx-widget], [data-cnx-container]) .input-group-lg > .form-select,
:where([data-cnx-widget], [data-cnx-container]) .input-group-sm > .form-select {
  padding-right: 3rem;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
:where([data-cnx-widget], [data-cnx-container]) .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
:where([data-cnx-widget], [data-cnx-container]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
:where([data-cnx-widget], [data-cnx-container]) .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
:where([data-cnx-widget], [data-cnx-container]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--wlp-bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:first-child) > .form-control,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--wlp-bs-form-valid-color);
}
:where([data-cnx-widget], [data-cnx-container]) .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--wlp-bs-success);
  border-radius: var(--wlp-bs-border-radius);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]):valid ~ .valid-feedback,
.was-validated :where([data-cnx-widget], [data-cnx-container]):valid ~ .valid-tooltip, :where([data-cnx-widget], [data-cnx-container]).is-valid ~ .valid-feedback,
:where([data-cnx-widget], [data-cnx-container]).is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control:valid, :where([data-cnx-widget], [data-cnx-container]) .form-control.is-valid {
  border-color: var(--wlp-bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control:valid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-control.is-valid:focus {
  border-color: var(--wlp-bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-success-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) textarea.form-control:valid, :where([data-cnx-widget], [data-cnx-container]) textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:valid, :where([data-cnx-widget], [data-cnx-container]) .form-select.is-valid {
  border-color: var(--wlp-bs-form-valid-border-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:valid:not([multiple]):not([size]), .was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:valid:not([multiple])[size="1"], :where([data-cnx-widget], [data-cnx-container]) .form-select.is-valid:not([multiple]):not([size]), :where([data-cnx-widget], [data-cnx-container]) .form-select.is-valid:not([multiple])[size="1"] {
  --wlp-bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:valid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-select.is-valid:focus {
  border-color: var(--wlp-bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-success-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control-color:valid, :where([data-cnx-widget], [data-cnx-container]) .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:valid, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-valid {
  border-color: var(--wlp-bs-form-valid-border-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:valid:checked, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-valid:checked {
  background-color: var(--wlp-bs-form-valid-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:valid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-success-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:valid ~ .form-check-label, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-valid ~ .form-check-label {
  color: var(--wlp-bs-form-valid-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control:not(:focus):valid, :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control:not(:focus).is-valid,
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select:not(:focus):valid,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select:not(:focus).is-valid,
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:focus-within):valid,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}
:where([data-cnx-widget], [data-cnx-container]) .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--wlp-bs-form-invalid-color);
}
:where([data-cnx-widget], [data-cnx-container]) .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--wlp-bs-danger);
  border-radius: var(--wlp-bs-border-radius);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]):invalid ~ .invalid-feedback,
.was-validated :where([data-cnx-widget], [data-cnx-container]):invalid ~ .invalid-tooltip, :where([data-cnx-widget], [data-cnx-container]).is-invalid ~ .invalid-feedback,
:where([data-cnx-widget], [data-cnx-container]).is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control:invalid, :where([data-cnx-widget], [data-cnx-container]) .form-control.is-invalid {
  border-color: var(--wlp-bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control:invalid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-control.is-invalid:focus {
  border-color: var(--wlp-bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-danger-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) textarea.form-control:invalid, :where([data-cnx-widget], [data-cnx-container]) textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:invalid, :where([data-cnx-widget], [data-cnx-container]) .form-select.is-invalid {
  border-color: var(--wlp-bs-form-invalid-border-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:invalid:not([multiple]):not([size]), .was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:invalid:not([multiple])[size="1"], :where([data-cnx-widget], [data-cnx-container]) .form-select.is-invalid:not([multiple]):not([size]), :where([data-cnx-widget], [data-cnx-container]) .form-select.is-invalid:not([multiple])[size="1"] {
  --wlp-bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-select:invalid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-select.is-invalid:focus {
  border-color: var(--wlp-bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-danger-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-control-color:invalid, :where([data-cnx-widget], [data-cnx-container]) .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:invalid, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-invalid {
  border-color: var(--wlp-bs-form-invalid-border-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:invalid:checked, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-invalid:checked {
  background-color: var(--wlp-bs-form-invalid-color);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:invalid:focus, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-danger-rgb), 0.25);
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .form-check-input:invalid ~ .form-check-label, :where([data-cnx-widget], [data-cnx-container]) .form-check-input.is-invalid ~ .form-check-label {
  color: var(--wlp-bs-form-invalid-color);
}
:where([data-cnx-widget], [data-cnx-container]) .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control:not(:focus):invalid, :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-control:not(:focus).is-invalid,
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select:not(:focus):invalid,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-select:not(:focus).is-invalid,
.was-validated :where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:focus-within):invalid,
:where([data-cnx-widget], [data-cnx-container]) .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
:where([data-cnx-widget], [data-cnx-container]) .btn {
  --wlp-bs-btn-padding-x: 0.75rem;
  --wlp-bs-btn-padding-y: 0.375rem;
  --wlp-bs-btn-font-family: ;
  --wlp-bs-btn-font-size: 1rem;
  --wlp-bs-btn-font-weight: 400;
  --wlp-bs-btn-line-height: 1.5;
  --wlp-bs-btn-color: var(--wlp-bs-body-color);
  --wlp-bs-btn-bg: transparent;
  --wlp-bs-btn-border-width: var(--wlp-bs-border-width);
  --wlp-bs-btn-border-color: transparent;
  --wlp-bs-btn-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-btn-hover-border-color: transparent;
  --wlp-bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --wlp-bs-btn-disabled-opacity: 0.65;
  --wlp-bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--wlp-bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--wlp-bs-btn-padding-y) var(--wlp-bs-btn-padding-x);
  font-family: var(--wlp-bs-btn-font-family);
  font-size: var(--wlp-bs-btn-font-size);
  font-weight: var(--wlp-bs-btn-font-weight);
  line-height: var(--wlp-bs-btn-line-height);
  color: var(--wlp-bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--wlp-bs-btn-border-width) solid var(--wlp-bs-btn-border-color);
  border-radius: var(--wlp-bs-btn-border-radius);
  background-color: var(--wlp-bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .btn {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .btn:hover {
  color: var(--wlp-bs-btn-hover-color);
  background-color: var(--wlp-bs-btn-hover-bg);
  border-color: var(--wlp-bs-btn-hover-border-color);
}
.btn-check + :where([data-cnx-widget], [data-cnx-container]) .btn:hover {
  color: var(--wlp-bs-btn-color);
  background-color: var(--wlp-bs-btn-bg);
  border-color: var(--wlp-bs-btn-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .btn:focus-visible {
  color: var(--wlp-bs-btn-hover-color);
  background-color: var(--wlp-bs-btn-hover-bg);
  border-color: var(--wlp-bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--wlp-bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + :where([data-cnx-widget], [data-cnx-container]) .btn {
  border-color: var(--wlp-bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--wlp-bs-btn-focus-box-shadow);
}
.btn-check:checked + :where([data-cnx-widget], [data-cnx-container]) .btn, :not(.btn-check) + :where([data-cnx-widget], [data-cnx-container]) .btn:active, :where([data-cnx-widget], [data-cnx-container]) .btn:first-child:active, :where([data-cnx-widget], [data-cnx-container]) .btn.active, :where([data-cnx-widget], [data-cnx-container]) .btn.show {
  color: var(--wlp-bs-btn-active-color);
  background-color: var(--wlp-bs-btn-active-bg);
  border-color: var(--wlp-bs-btn-active-border-color);
}
.btn-check:checked + :where([data-cnx-widget], [data-cnx-container]) .btn:focus-visible, :not(.btn-check) + :where([data-cnx-widget], [data-cnx-container]) .btn:active:focus-visible, :where([data-cnx-widget], [data-cnx-container]) .btn:first-child:active:focus-visible, :where([data-cnx-widget], [data-cnx-container]) .btn.active:focus-visible, :where([data-cnx-widget], [data-cnx-container]) .btn.show:focus-visible {
  box-shadow: var(--wlp-bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + :where([data-cnx-widget], [data-cnx-container]) .btn {
  box-shadow: var(--wlp-bs-btn-focus-box-shadow);
}
:where([data-cnx-widget], [data-cnx-container]) .btn:disabled, :where([data-cnx-widget], [data-cnx-container]) .btn.disabled, fieldset:disabled :where([data-cnx-widget], [data-cnx-container]) .btn {
  color: var(--wlp-bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--wlp-bs-btn-disabled-bg);
  border-color: var(--wlp-bs-btn-disabled-border-color);
  opacity: var(--wlp-bs-btn-disabled-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-primary {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #f43369;
  --wlp-bs-btn-border-color: #f43369;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #cf2b59;
  --wlp-bs-btn-hover-border-color: #c32954;
  --wlp-bs-btn-focus-shadow-rgb: 246, 82, 128;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #c32954;
  --wlp-bs-btn-active-border-color: #b7264f;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #f43369;
  --wlp-bs-btn-disabled-border-color: #f43369;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-secondary {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #6c757d;
  --wlp-bs-btn-border-color: #6c757d;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #5c636a;
  --wlp-bs-btn-hover-border-color: #565e64;
  --wlp-bs-btn-focus-shadow-rgb: 130, 138, 145;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #565e64;
  --wlp-bs-btn-active-border-color: #51585e;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #6c757d;
  --wlp-bs-btn-disabled-border-color: #6c757d;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-success {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #198754;
  --wlp-bs-btn-border-color: #198754;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #157347;
  --wlp-bs-btn-hover-border-color: #146c43;
  --wlp-bs-btn-focus-shadow-rgb: 60, 153, 110;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #146c43;
  --wlp-bs-btn-active-border-color: #13653f;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #198754;
  --wlp-bs-btn-disabled-border-color: #198754;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-info {
  --wlp-bs-btn-color: #000;
  --wlp-bs-btn-bg: #0dcaf0;
  --wlp-bs-btn-border-color: #0dcaf0;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #31d2f2;
  --wlp-bs-btn-hover-border-color: #25cff2;
  --wlp-bs-btn-focus-shadow-rgb: 11, 172, 204;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #3dd5f3;
  --wlp-bs-btn-active-border-color: #25cff2;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #000;
  --wlp-bs-btn-disabled-bg: #0dcaf0;
  --wlp-bs-btn-disabled-border-color: #0dcaf0;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-warning {
  --wlp-bs-btn-color: #000;
  --wlp-bs-btn-bg: #ffc107;
  --wlp-bs-btn-border-color: #ffc107;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #ffca2c;
  --wlp-bs-btn-hover-border-color: #ffc720;
  --wlp-bs-btn-focus-shadow-rgb: 217, 164, 6;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #ffcd39;
  --wlp-bs-btn-active-border-color: #ffc720;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #000;
  --wlp-bs-btn-disabled-bg: #ffc107;
  --wlp-bs-btn-disabled-border-color: #ffc107;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-danger {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #dc3545;
  --wlp-bs-btn-border-color: #dc3545;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #bb2d3b;
  --wlp-bs-btn-hover-border-color: #b02a37;
  --wlp-bs-btn-focus-shadow-rgb: 225, 83, 97;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #b02a37;
  --wlp-bs-btn-active-border-color: #a52834;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #dc3545;
  --wlp-bs-btn-disabled-border-color: #dc3545;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-light {
  --wlp-bs-btn-color: #000;
  --wlp-bs-btn-bg: #f8f9fa;
  --wlp-bs-btn-border-color: #f8f9fa;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #d3d4d5;
  --wlp-bs-btn-hover-border-color: #c6c7c8;
  --wlp-bs-btn-focus-shadow-rgb: 211, 212, 213;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #c6c7c8;
  --wlp-bs-btn-active-border-color: #babbbc;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #000;
  --wlp-bs-btn-disabled-bg: #f8f9fa;
  --wlp-bs-btn-disabled-border-color: #f8f9fa;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-dark {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #212529;
  --wlp-bs-btn-border-color: #212529;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #424649;
  --wlp-bs-btn-hover-border-color: #373b3e;
  --wlp-bs-btn-focus-shadow-rgb: 66, 70, 73;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #4d5154;
  --wlp-bs-btn-active-border-color: #373b3e;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #212529;
  --wlp-bs-btn-disabled-border-color: #212529;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-primary {
  --wlp-bs-btn-color: #f43369;
  --wlp-bs-btn-border-color: #f43369;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #f43369;
  --wlp-bs-btn-hover-border-color: #f43369;
  --wlp-bs-btn-focus-shadow-rgb: 244, 51, 105;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #f43369;
  --wlp-bs-btn-active-border-color: #f43369;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #f43369;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #f43369;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-secondary {
  --wlp-bs-btn-color: #6c757d;
  --wlp-bs-btn-border-color: #6c757d;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #6c757d;
  --wlp-bs-btn-hover-border-color: #6c757d;
  --wlp-bs-btn-focus-shadow-rgb: 108, 117, 125;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #6c757d;
  --wlp-bs-btn-active-border-color: #6c757d;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #6c757d;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #6c757d;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-success {
  --wlp-bs-btn-color: #198754;
  --wlp-bs-btn-border-color: #198754;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #198754;
  --wlp-bs-btn-hover-border-color: #198754;
  --wlp-bs-btn-focus-shadow-rgb: 25, 135, 84;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #198754;
  --wlp-bs-btn-active-border-color: #198754;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #198754;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #198754;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-info {
  --wlp-bs-btn-color: #0dcaf0;
  --wlp-bs-btn-border-color: #0dcaf0;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #0dcaf0;
  --wlp-bs-btn-hover-border-color: #0dcaf0;
  --wlp-bs-btn-focus-shadow-rgb: 13, 202, 240;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #0dcaf0;
  --wlp-bs-btn-active-border-color: #0dcaf0;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #0dcaf0;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #0dcaf0;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-warning {
  --wlp-bs-btn-color: #ffc107;
  --wlp-bs-btn-border-color: #ffc107;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #ffc107;
  --wlp-bs-btn-hover-border-color: #ffc107;
  --wlp-bs-btn-focus-shadow-rgb: 255, 193, 7;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #ffc107;
  --wlp-bs-btn-active-border-color: #ffc107;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #ffc107;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #ffc107;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-danger {
  --wlp-bs-btn-color: #dc3545;
  --wlp-bs-btn-border-color: #dc3545;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #dc3545;
  --wlp-bs-btn-hover-border-color: #dc3545;
  --wlp-bs-btn-focus-shadow-rgb: 220, 53, 69;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #dc3545;
  --wlp-bs-btn-active-border-color: #dc3545;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #dc3545;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #dc3545;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-light {
  --wlp-bs-btn-color: #f8f9fa;
  --wlp-bs-btn-border-color: #f8f9fa;
  --wlp-bs-btn-hover-color: #000;
  --wlp-bs-btn-hover-bg: #f8f9fa;
  --wlp-bs-btn-hover-border-color: #f8f9fa;
  --wlp-bs-btn-focus-shadow-rgb: 248, 249, 250;
  --wlp-bs-btn-active-color: #000;
  --wlp-bs-btn-active-bg: #f8f9fa;
  --wlp-bs-btn-active-border-color: #f8f9fa;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #f8f9fa;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #f8f9fa;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-outline-dark {
  --wlp-bs-btn-color: #212529;
  --wlp-bs-btn-border-color: #212529;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #212529;
  --wlp-bs-btn-hover-border-color: #212529;
  --wlp-bs-btn-focus-shadow-rgb: 33, 37, 41;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #212529;
  --wlp-bs-btn-active-border-color: #212529;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #212529;
  --wlp-bs-btn-disabled-bg: transparent;
  --wlp-bs-btn-disabled-border-color: #212529;
  --wlp-bs-gradient: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-link {
  --wlp-bs-btn-font-weight: 400;
  --wlp-bs-btn-color: var(--wlp-bs-link-color);
  --wlp-bs-btn-bg: transparent;
  --wlp-bs-btn-border-color: transparent;
  --wlp-bs-btn-hover-color: var(--wlp-bs-link-hover-color);
  --wlp-bs-btn-hover-border-color: transparent;
  --wlp-bs-btn-active-color: var(--wlp-bs-link-hover-color);
  --wlp-bs-btn-active-border-color: transparent;
  --wlp-bs-btn-disabled-color: #6c757d;
  --wlp-bs-btn-disabled-border-color: transparent;
  --wlp-bs-btn-box-shadow: 0 0 0 #000;
  --wlp-bs-btn-focus-shadow-rgb: 246, 82, 128;
  text-decoration: underline;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-link:focus-visible {
  color: var(--wlp-bs-btn-color);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-link:hover {
  color: var(--wlp-bs-btn-hover-color);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-lg, :where([data-cnx-widget], [data-cnx-container]) .btn-group-lg > .btn {
  --wlp-bs-btn-padding-y: 0.5rem;
  --wlp-bs-btn-padding-x: 1rem;
  --wlp-bs-btn-font-size: 1.25rem;
  --wlp-bs-btn-border-radius: var(--wlp-bs-border-radius-lg);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-sm, :where([data-cnx-widget], [data-cnx-container]) .btn-group-sm > .btn {
  --wlp-bs-btn-padding-y: 0.25rem;
  --wlp-bs-btn-padding-x: 0.5rem;
  --wlp-bs-btn-font-size: 0.875rem;
  --wlp-bs-btn-border-radius: var(--wlp-bs-border-radius-sm);
}
:where([data-cnx-widget], [data-cnx-container]) .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .fade {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .fade:not(.show) {
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .collapse:not(.show) {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .collapsing {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .collapsing.collapse-horizontal {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .dropup,
:where([data-cnx-widget], [data-cnx-container]) .dropend,
:where([data-cnx-widget], [data-cnx-container]) .dropdown,
:where([data-cnx-widget], [data-cnx-container]) .dropstart,
:where([data-cnx-widget], [data-cnx-container]) .dropup-center,
:where([data-cnx-widget], [data-cnx-container]) .dropdown-center {
  position: relative;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle {
  white-space: nowrap;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle:empty::after {
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu {
  --wlp-bs-dropdown-zindex: 1000;
  --wlp-bs-dropdown-min-width: 10rem;
  --wlp-bs-dropdown-padding-x: 0;
  --wlp-bs-dropdown-padding-y: 0.5rem;
  --wlp-bs-dropdown-spacer: 0.125rem;
  --wlp-bs-dropdown-font-size: 1rem;
  --wlp-bs-dropdown-color: var(--wlp-bs-body-color);
  --wlp-bs-dropdown-bg: var(--wlp-bs-body-bg);
  --wlp-bs-dropdown-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-dropdown-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-dropdown-border-width: var(--wlp-bs-border-width);
  --wlp-bs-dropdown-inner-border-radius: calc(var(--wlp-bs-border-radius) - var(--wlp-bs-border-width));
  --wlp-bs-dropdown-divider-bg: var(--wlp-bs-border-color-translucent);
  --wlp-bs-dropdown-divider-margin-y: 0.5rem;
  --wlp-bs-dropdown-box-shadow: var(--wlp-bs-box-shadow);
  --wlp-bs-dropdown-link-color: var(--wlp-bs-body-color);
  --wlp-bs-dropdown-link-hover-color: var(--wlp-bs-body-color);
  --wlp-bs-dropdown-link-hover-bg: var(--wlp-bs-tertiary-bg);
  --wlp-bs-dropdown-link-active-color: #fff;
  --wlp-bs-dropdown-link-active-bg: #f43369;
  --wlp-bs-dropdown-link-disabled-color: var(--wlp-bs-tertiary-color);
  --wlp-bs-dropdown-item-padding-x: 1rem;
  --wlp-bs-dropdown-item-padding-y: 0.25rem;
  --wlp-bs-dropdown-header-color: #6c757d;
  --wlp-bs-dropdown-header-padding-x: 1rem;
  --wlp-bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--wlp-bs-dropdown-zindex);
  display: none;
  min-width: var(--wlp-bs-dropdown-min-width);
  padding: var(--wlp-bs-dropdown-padding-y) var(--wlp-bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--wlp-bs-dropdown-font-size);
  color: var(--wlp-bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--wlp-bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--wlp-bs-dropdown-border-width) solid var(--wlp-bs-dropdown-border-color);
  border-radius: var(--wlp-bs-dropdown-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--wlp-bs-dropdown-spacer);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-start {
  --bs-position: start;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-end {
  --bs-position: end;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-sm-start {
    --bs-position: start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-sm-end {
    --bs-position: end;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-md-start {
    --bs-position: start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-md-end {
    --bs-position: end;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-lg-start {
    --bs-position: start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-lg-end {
    --bs-position: end;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xl-start {
    --bs-position: start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xl-end {
    --bs-position: end;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  :where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--wlp-bs-dropdown-spacer);
}
:where([data-cnx-widget], [data-cnx-container]) .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--wlp-bs-dropdown-spacer);
}
:where([data-cnx-widget], [data-cnx-container]) .dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
:where([data-cnx-widget], [data-cnx-container]) .dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropend .dropdown-toggle::after {
  vertical-align: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--wlp-bs-dropdown-spacer);
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-toggle::after {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-divider {
  height: 0;
  margin: var(--wlp-bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--wlp-bs-dropdown-divider-bg);
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-item {
  display: block;
  width: 100%;
  padding: var(--wlp-bs-dropdown-item-padding-y) var(--wlp-bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--wlp-bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--wlp-bs-dropdown-item-border-radius, 0);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-item:hover, :where([data-cnx-widget], [data-cnx-container]) .dropdown-item:focus {
  color: var(--wlp-bs-dropdown-link-hover-color);
  background-color: var(--wlp-bs-dropdown-link-hover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-item.active, :where([data-cnx-widget], [data-cnx-container]) .dropdown-item:active {
  color: var(--wlp-bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--wlp-bs-dropdown-link-active-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-item.disabled, :where([data-cnx-widget], [data-cnx-container]) .dropdown-item:disabled {
  color: var(--wlp-bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu.show {
  display: block;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-header {
  display: block;
  padding: var(--wlp-bs-dropdown-header-padding-y) var(--wlp-bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--wlp-bs-dropdown-header-color);
  white-space: nowrap;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-item-text {
  display: block;
  padding: var(--wlp-bs-dropdown-item-padding-y) var(--wlp-bs-dropdown-item-padding-x);
  color: var(--wlp-bs-dropdown-link-color);
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-menu-dark {
  --wlp-bs-dropdown-color: #dee2e6;
  --wlp-bs-dropdown-bg: #343a40;
  --wlp-bs-dropdown-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-dropdown-box-shadow: ;
  --wlp-bs-dropdown-link-color: #dee2e6;
  --wlp-bs-dropdown-link-hover-color: #fff;
  --wlp-bs-dropdown-divider-bg: var(--wlp-bs-border-color-translucent);
  --wlp-bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --wlp-bs-dropdown-link-active-color: #fff;
  --wlp-bs-dropdown-link-active-bg: #f43369;
  --wlp-bs-dropdown-link-disabled-color: #adb5bd;
  --wlp-bs-dropdown-header-color: #adb5bd;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn-check:checked + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn-check:focus + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn:hover,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn:focus,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn:active,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn.active,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-check:checked + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-check:focus + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn:hover,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn:focus,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn:active,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn.active {
  z-index: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-toolbar .input-group {
  width: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group {
  border-radius: var(--wlp-bs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group > :not(.btn-check:first-child) + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--wlp-bs-border-width) * -1);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn.dropdown-toggle-split:first-child,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn:nth-child(n+3),
:where([data-cnx-widget], [data-cnx-container]) .btn-group > :not(.btn-check) + .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
:where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle-split::after, .dropup :where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle-split::after, .dropend :where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart :where([data-cnx-widget], [data-cnx-container]) .dropdown-toggle-split::before {
  margin-right: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-sm + .dropdown-toggle-split, :where([data-cnx-widget], [data-cnx-container]) .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-lg + .dropdown-toggle-split, :where([data-cnx-widget], [data-cnx-container]) .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-group {
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn:not(:first-child),
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--wlp-bs-border-width) * -1);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn ~ .btn,
:where([data-cnx-widget], [data-cnx-container]) .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .nav {
  --wlp-bs-nav-link-padding-x: 1rem;
  --wlp-bs-nav-link-padding-y: 0.5rem;
  --wlp-bs-nav-link-font-weight: ;
  --wlp-bs-nav-link-color: var(--wlp-bs-link-color);
  --wlp-bs-nav-link-hover-color: var(--wlp-bs-link-hover-color);
  --wlp-bs-nav-link-disabled-color: var(--wlp-bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-link {
  display: block;
  padding: var(--wlp-bs-nav-link-padding-y) var(--wlp-bs-nav-link-padding-x);
  font-size: var(--wlp-bs-nav-link-font-size);
  font-weight: var(--wlp-bs-nav-link-font-weight);
  color: var(--wlp-bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .nav-link {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .nav-link:hover, :where([data-cnx-widget], [data-cnx-container]) .nav-link:focus {
  color: var(--wlp-bs-nav-link-hover-color);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-link.disabled, :where([data-cnx-widget], [data-cnx-container]) .nav-link:disabled {
  color: var(--wlp-bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs {
  --wlp-bs-nav-tabs-border-width: var(--wlp-bs-border-width);
  --wlp-bs-nav-tabs-border-color: var(--wlp-bs-border-color);
  --wlp-bs-nav-tabs-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-nav-tabs-link-hover-border-color: var(--wlp-bs-secondary-bg) var(--wlp-bs-secondary-bg) var(--wlp-bs-border-color);
  --wlp-bs-nav-tabs-link-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-nav-tabs-link-active-bg: var(--wlp-bs-body-bg);
  --wlp-bs-nav-tabs-link-active-border-color: var(--wlp-bs-border-color) var(--wlp-bs-border-color) var(--wlp-bs-body-bg);
  border-bottom: var(--wlp-bs-nav-tabs-border-width) solid var(--wlp-bs-nav-tabs-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--wlp-bs-nav-tabs-border-width));
  border: var(--wlp-bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--wlp-bs-nav-tabs-border-radius);
  border-top-right-radius: var(--wlp-bs-nav-tabs-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs .nav-link:hover, :where([data-cnx-widget], [data-cnx-container]) .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--wlp-bs-nav-tabs-link-hover-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs .nav-link.active,
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs .nav-item.show .nav-link {
  color: var(--wlp-bs-nav-tabs-link-active-color);
  background-color: var(--wlp-bs-nav-tabs-link-active-bg);
  border-color: var(--wlp-bs-nav-tabs-link-active-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--wlp-bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-pills {
  --wlp-bs-nav-pills-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-nav-pills-link-active-color: #fff;
  --wlp-bs-nav-pills-link-active-bg: #f43369;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-pills .nav-link {
  border-radius: var(--wlp-bs-nav-pills-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-pills .nav-link.active,
:where([data-cnx-widget], [data-cnx-container]) .nav-pills .show > .nav-link {
  color: var(--wlp-bs-nav-pills-link-active-color);
  background-color: var(--wlp-bs-nav-pills-link-active-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-underline {
  --wlp-bs-nav-underline-gap: 1rem;
  --wlp-bs-nav-underline-border-width: 0.125rem;
  --wlp-bs-nav-underline-link-active-color: var(--wlp-bs-emphasis-color);
  gap: var(--wlp-bs-nav-underline-gap);
}
:where([data-cnx-widget], [data-cnx-container]) .nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--wlp-bs-nav-underline-border-width) solid transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-underline .nav-link:hover, :where([data-cnx-widget], [data-cnx-container]) .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-underline .nav-link.active,
:where([data-cnx-widget], [data-cnx-container]) .nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--wlp-bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-fill > .nav-link,
:where([data-cnx-widget], [data-cnx-container]) .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-justified > .nav-link,
:where([data-cnx-widget], [data-cnx-container]) .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
:where([data-cnx-widget], [data-cnx-container]) .nav-fill .nav-item .nav-link,
:where([data-cnx-widget], [data-cnx-container]) .nav-justified .nav-item .nav-link {
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .tab-content > .tab-pane {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .tab-content > .active {
  display: block;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar {
  --wlp-bs-navbar-padding-x: 0;
  --wlp-bs-navbar-padding-y: 0.5rem;
  --wlp-bs-navbar-color: rgba(var(--wlp-bs-emphasis-color-rgb), 0.65);
  --wlp-bs-navbar-hover-color: rgba(var(--wlp-bs-emphasis-color-rgb), 0.8);
  --wlp-bs-navbar-disabled-color: rgba(var(--wlp-bs-emphasis-color-rgb), 0.3);
  --wlp-bs-navbar-active-color: rgba(var(--wlp-bs-emphasis-color-rgb), 1);
  --wlp-bs-navbar-brand-padding-y: 0.3125rem;
  --wlp-bs-navbar-brand-margin-end: 1rem;
  --wlp-bs-navbar-brand-font-size: 1.25rem;
  --wlp-bs-navbar-brand-color: rgba(var(--wlp-bs-emphasis-color-rgb), 1);
  --wlp-bs-navbar-brand-hover-color: rgba(var(--wlp-bs-emphasis-color-rgb), 1);
  --wlp-bs-navbar-nav-link-padding-x: 0.5rem;
  --wlp-bs-navbar-toggler-padding-y: 0.25rem;
  --wlp-bs-navbar-toggler-padding-x: 0.75rem;
  --wlp-bs-navbar-toggler-font-size: 1.25rem;
  --wlp-bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --wlp-bs-navbar-toggler-border-color: rgba(var(--wlp-bs-emphasis-color-rgb), 0.15);
  --wlp-bs-navbar-toggler-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-navbar-toggler-focus-width: 0.25rem;
  --wlp-bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--wlp-bs-navbar-padding-y) var(--wlp-bs-navbar-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-fluid,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-sm,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-md,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-lg,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-xl,
:where([data-cnx-widget], [data-cnx-container]) .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-brand {
  padding-top: var(--wlp-bs-navbar-brand-padding-y);
  padding-bottom: var(--wlp-bs-navbar-brand-padding-y);
  margin-right: var(--wlp-bs-navbar-brand-margin-end);
  font-size: var(--wlp-bs-navbar-brand-font-size);
  color: var(--wlp-bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-brand:hover, :where([data-cnx-widget], [data-cnx-container]) .navbar-brand:focus {
  color: var(--wlp-bs-navbar-brand-hover-color);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-nav {
  --wlp-bs-nav-link-padding-x: 0;
  --wlp-bs-nav-link-padding-y: 0.5rem;
  --wlp-bs-nav-link-font-weight: ;
  --wlp-bs-nav-link-color: var(--wlp-bs-navbar-color);
  --wlp-bs-nav-link-hover-color: var(--wlp-bs-navbar-hover-color);
  --wlp-bs-nav-link-disabled-color: var(--wlp-bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-nav .nav-link.active, :where([data-cnx-widget], [data-cnx-container]) .navbar-nav .nav-link.show {
  color: var(--wlp-bs-navbar-active-color);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-nav .dropdown-menu {
  position: static;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--wlp-bs-navbar-color);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-text a,
:where([data-cnx-widget], [data-cnx-container]) .navbar-text a:hover,
:where([data-cnx-widget], [data-cnx-container]) .navbar-text a:focus {
  color: var(--wlp-bs-navbar-active-color);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-toggler {
  padding: var(--wlp-bs-navbar-toggler-padding-y) var(--wlp-bs-navbar-toggler-padding-x);
  font-size: var(--wlp-bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--wlp-bs-navbar-color);
  background-color: transparent;
  border: var(--wlp-bs-border-width) solid var(--wlp-bs-navbar-toggler-border-color);
  border-radius: var(--wlp-bs-navbar-toggler-border-radius);
  transition: var(--wlp-bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-toggler {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-toggler:hover {
  text-decoration: none;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--wlp-bs-navbar-toggler-focus-width);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--wlp-bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-nav-scroll {
  max-height: var(--wlp-bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
    padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
    padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .navbar-toggler {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
    padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
    padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
    padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-nav {
  flex-direction: row;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-nav .nav-link {
  padding-right: var(--wlp-bs-navbar-nav-link-padding-x);
  padding-left: var(--wlp-bs-navbar-nav-link-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .navbar-toggler {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
:where([data-cnx-widget], [data-cnx-container]) .navbar-dark,
:where([data-cnx-widget], [data-cnx-container]) .navbar[data-bs-theme=dark] {
  --wlp-bs-navbar-color: rgba(255, 255, 255, 0.55);
  --wlp-bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --wlp-bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --wlp-bs-navbar-active-color: #fff;
  --wlp-bs-navbar-brand-color: #fff;
  --wlp-bs-navbar-brand-hover-color: #fff;
  --wlp-bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --wlp-bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .navbar-toggler-icon {
  --wlp-bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .card {
  --wlp-bs-card-spacer-y: 1rem;
  --wlp-bs-card-spacer-x: 1rem;
  --wlp-bs-card-title-spacer-y: 0.5rem;
  --wlp-bs-card-title-color: ;
  --wlp-bs-card-subtitle-color: ;
  --wlp-bs-card-border-width: var(--wlp-bs-border-width);
  --wlp-bs-card-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-card-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-card-box-shadow: ;
  --wlp-bs-card-inner-border-radius: calc(var(--wlp-bs-border-radius) - (var(--wlp-bs-border-width)));
  --wlp-bs-card-cap-padding-y: 0.5rem;
  --wlp-bs-card-cap-padding-x: 1rem;
  --wlp-bs-card-cap-bg: rgba(var(--wlp-bs-body-color-rgb), 0.03);
  --wlp-bs-card-cap-color: ;
  --wlp-bs-card-height: ;
  --wlp-bs-card-color: ;
  --wlp-bs-card-bg: var(--wlp-bs-body-bg);
  --wlp-bs-card-img-overlay-padding: 1rem;
  --wlp-bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--wlp-bs-card-height);
  color: var(--wlp-bs-body-color);
  word-wrap: break-word;
  background-color: var(--wlp-bs-card-bg);
  background-clip: border-box;
  border: var(--wlp-bs-card-border-width) solid var(--wlp-bs-card-border-color);
  border-radius: var(--wlp-bs-card-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card > hr {
  margin-right: 0;
  margin-left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--wlp-bs-card-inner-border-radius);
  border-top-right-radius: var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--wlp-bs-card-inner-border-radius);
  border-bottom-left-radius: var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card > .card-header + .list-group,
:where([data-cnx-widget], [data-cnx-container]) .card > .list-group + .card-footer {
  border-top: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .card-body {
  flex: 1 1 auto;
  padding: var(--wlp-bs-card-spacer-y) var(--wlp-bs-card-spacer-x);
  color: var(--wlp-bs-card-color);
}
:where([data-cnx-widget], [data-cnx-container]) .card-title {
  margin-bottom: var(--wlp-bs-card-title-spacer-y);
  color: var(--wlp-bs-card-title-color);
}
:where([data-cnx-widget], [data-cnx-container]) .card-subtitle {
  margin-top: calc(-0.5 * var(--wlp-bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--wlp-bs-card-subtitle-color);
}
:where([data-cnx-widget], [data-cnx-container]) .card-text:last-child {
  margin-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .card-link + .card-link {
  margin-left: var(--wlp-bs-card-spacer-x);
}
:where([data-cnx-widget], [data-cnx-container]) .card-header {
  padding: var(--wlp-bs-card-cap-padding-y) var(--wlp-bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--wlp-bs-card-cap-color);
  background-color: var(--wlp-bs-card-cap-bg);
  border-bottom: var(--wlp-bs-card-border-width) solid var(--wlp-bs-card-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .card-header:first-child {
  border-radius: var(--wlp-bs-card-inner-border-radius) var(--wlp-bs-card-inner-border-radius) 0 0;
}
:where([data-cnx-widget], [data-cnx-container]) .card-footer {
  padding: var(--wlp-bs-card-cap-padding-y) var(--wlp-bs-card-cap-padding-x);
  color: var(--wlp-bs-card-cap-color);
  background-color: var(--wlp-bs-card-cap-bg);
  border-top: var(--wlp-bs-card-border-width) solid var(--wlp-bs-card-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .card-footer:last-child {
  border-radius: 0 0 var(--wlp-bs-card-inner-border-radius) var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card-header-tabs {
  margin-right: calc(-0.5 * var(--wlp-bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--wlp-bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--wlp-bs-card-cap-padding-x));
  border-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .card-header-tabs .nav-link.active {
  background-color: var(--wlp-bs-card-bg);
  border-bottom-color: var(--wlp-bs-card-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .card-header-pills {
  margin-right: calc(-0.5 * var(--wlp-bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--wlp-bs-card-cap-padding-x));
}
:where([data-cnx-widget], [data-cnx-container]) .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--wlp-bs-card-img-overlay-padding);
  border-radius: var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card-img,
:where([data-cnx-widget], [data-cnx-container]) .card-img-top,
:where([data-cnx-widget], [data-cnx-container]) .card-img-bottom {
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .card-img,
:where([data-cnx-widget], [data-cnx-container]) .card-img-top {
  border-top-left-radius: var(--wlp-bs-card-inner-border-radius);
  border-top-right-radius: var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card-img,
:where([data-cnx-widget], [data-cnx-container]) .card-img-bottom {
  border-bottom-right-radius: var(--wlp-bs-card-inner-border-radius);
  border-bottom-left-radius: var(--wlp-bs-card-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .card-group > .card {
  margin-bottom: var(--wlp-bs-card-group-margin);
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:last-child) .card-img-top,
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:last-child) .card-img-bottom,
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:first-child) .card-img-top,
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:first-child) .card-img-bottom,
  :where([data-cnx-widget], [data-cnx-container]) .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .accordion {
  --wlp-bs-accordion-color: var(--wlp-bs-body-color);
  --wlp-bs-accordion-bg: var(--wlp-bs-body-bg);
  --wlp-bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --wlp-bs-accordion-border-color: var(--wlp-bs-border-color);
  --wlp-bs-accordion-border-width: var(--wlp-bs-border-width);
  --wlp-bs-accordion-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-accordion-inner-border-radius: calc(var(--wlp-bs-border-radius) - (var(--wlp-bs-border-width)));
  --wlp-bs-accordion-btn-padding-x: 1.25rem;
  --wlp-bs-accordion-btn-padding-y: 1rem;
  --wlp-bs-accordion-btn-color: var(--wlp-bs-body-color);
  --wlp-bs-accordion-btn-bg: var(--wlp-bs-accordion-bg);
  --wlp-bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --wlp-bs-accordion-btn-icon-width: 1.25rem;
  --wlp-bs-accordion-btn-icon-transform: rotate(-180deg);
  --wlp-bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --wlp-bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%2362142a' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --wlp-bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
  --wlp-bs-accordion-body-padding-x: 1.25rem;
  --wlp-bs-accordion-body-padding-y: 1rem;
  --wlp-bs-accordion-active-color: var(--wlp-bs-primary-text-emphasis);
  --wlp-bs-accordion-active-bg: var(--wlp-bs-primary-bg-subtle);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--wlp-bs-accordion-btn-padding-y) var(--wlp-bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--wlp-bs-accordion-btn-color);
  text-align: left;
  background-color: var(--wlp-bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--wlp-bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .accordion-button {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button:not(.collapsed) {
  color: var(--wlp-bs-accordion-active-color);
  background-color: var(--wlp-bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--wlp-bs-accordion-border-width)) 0 var(--wlp-bs-accordion-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button:not(.collapsed)::after {
  background-image: var(--wlp-bs-accordion-btn-active-icon);
  transform: var(--wlp-bs-accordion-btn-icon-transform);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button::after {
  flex-shrink: 0;
  width: var(--wlp-bs-accordion-btn-icon-width);
  height: var(--wlp-bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--wlp-bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--wlp-bs-accordion-btn-icon-width);
  transition: var(--wlp-bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .accordion-button::after {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button:hover {
  z-index: 2;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--wlp-bs-accordion-btn-focus-box-shadow);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-header {
  margin-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item {
  color: var(--wlp-bs-accordion-color);
  background-color: var(--wlp-bs-accordion-bg);
  border: var(--wlp-bs-accordion-border-width) solid var(--wlp-bs-accordion-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:first-of-type {
  border-top-left-radius: var(--wlp-bs-accordion-border-radius);
  border-top-right-radius: var(--wlp-bs-accordion-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--wlp-bs-accordion-inner-border-radius);
  border-top-right-radius: var(--wlp-bs-accordion-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:not(:first-of-type) {
  border-top: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:last-of-type {
  border-bottom-right-radius: var(--wlp-bs-accordion-border-radius);
  border-bottom-left-radius: var(--wlp-bs-accordion-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--wlp-bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--wlp-bs-accordion-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--wlp-bs-accordion-border-radius);
  border-bottom-left-radius: var(--wlp-bs-accordion-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-body {
  padding: var(--wlp-bs-accordion-body-padding-y) var(--wlp-bs-accordion-body-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item > .accordion-header .accordion-button, :where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .accordion-button::after {
  --wlp-bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f885a5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --wlp-bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f885a5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
:where([data-cnx-widget], [data-cnx-container]) .breadcrumb {
  --wlp-bs-breadcrumb-padding-x: 0;
  --wlp-bs-breadcrumb-padding-y: 0;
  --wlp-bs-breadcrumb-margin-bottom: 1rem;
  --wlp-bs-breadcrumb-bg: ;
  --wlp-bs-breadcrumb-border-radius: ;
  --wlp-bs-breadcrumb-divider-color: var(--wlp-bs-secondary-color);
  --wlp-bs-breadcrumb-item-padding-x: 0.5rem;
  --wlp-bs-breadcrumb-item-active-color: var(--wlp-bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--wlp-bs-breadcrumb-padding-y) var(--wlp-bs-breadcrumb-padding-x);
  margin-bottom: var(--wlp-bs-breadcrumb-margin-bottom);
  font-size: var(--wlp-bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--wlp-bs-breadcrumb-bg);
  border-radius: var(--wlp-bs-breadcrumb-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .breadcrumb-item + .breadcrumb-item {
  padding-left: var(--wlp-bs-breadcrumb-item-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--wlp-bs-breadcrumb-item-padding-x);
  color: var(--wlp-bs-breadcrumb-divider-color);
  content: var(--wlp-bs-breadcrumb-divider, "/") /* rtl: var(--wlp-bs-breadcrumb-divider, "/") */;
}
:where([data-cnx-widget], [data-cnx-container]) .breadcrumb-item.active {
  color: var(--wlp-bs-breadcrumb-item-active-color);
}
:where([data-cnx-widget], [data-cnx-container]) .pagination {
  --wlp-bs-pagination-padding-x: 0.75rem;
  --wlp-bs-pagination-padding-y: 0.375rem;
  --wlp-bs-pagination-font-size: 1rem;
  --wlp-bs-pagination-color: var(--wlp-bs-link-color);
  --wlp-bs-pagination-bg: var(--wlp-bs-body-bg);
  --wlp-bs-pagination-border-width: var(--wlp-bs-border-width);
  --wlp-bs-pagination-border-color: var(--wlp-bs-border-color);
  --wlp-bs-pagination-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-pagination-hover-color: var(--wlp-bs-link-hover-color);
  --wlp-bs-pagination-hover-bg: var(--wlp-bs-tertiary-bg);
  --wlp-bs-pagination-hover-border-color: var(--wlp-bs-border-color);
  --wlp-bs-pagination-focus-color: var(--wlp-bs-link-hover-color);
  --wlp-bs-pagination-focus-bg: var(--wlp-bs-secondary-bg);
  --wlp-bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
  --wlp-bs-pagination-active-color: #fff;
  --wlp-bs-pagination-active-bg: #f43369;
  --wlp-bs-pagination-active-border-color: #f43369;
  --wlp-bs-pagination-disabled-color: var(--wlp-bs-secondary-color);
  --wlp-bs-pagination-disabled-bg: var(--wlp-bs-secondary-bg);
  --wlp-bs-pagination-disabled-border-color: var(--wlp-bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}
:where([data-cnx-widget], [data-cnx-container]) .page-link {
  position: relative;
  display: block;
  padding: var(--wlp-bs-pagination-padding-y) var(--wlp-bs-pagination-padding-x);
  font-size: var(--wlp-bs-pagination-font-size);
  color: var(--wlp-bs-pagination-color);
  text-decoration: none;
  background-color: var(--wlp-bs-pagination-bg);
  border: var(--wlp-bs-pagination-border-width) solid var(--wlp-bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .page-link {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .page-link:hover {
  z-index: 2;
  color: var(--wlp-bs-pagination-hover-color);
  background-color: var(--wlp-bs-pagination-hover-bg);
  border-color: var(--wlp-bs-pagination-hover-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .page-link:focus {
  z-index: 3;
  color: var(--wlp-bs-pagination-focus-color);
  background-color: var(--wlp-bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--wlp-bs-pagination-focus-box-shadow);
}
:where([data-cnx-widget], [data-cnx-container]) .page-link.active, .active > :where([data-cnx-widget], [data-cnx-container]) .page-link {
  z-index: 3;
  color: var(--wlp-bs-pagination-active-color);
  background-color: var(--wlp-bs-pagination-active-bg);
  border-color: var(--wlp-bs-pagination-active-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .page-link.disabled, .disabled > :where([data-cnx-widget], [data-cnx-container]) .page-link {
  color: var(--wlp-bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--wlp-bs-pagination-disabled-bg);
  border-color: var(--wlp-bs-pagination-disabled-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .page-item:not(:first-child) .page-link {
  margin-left: calc(var(--wlp-bs-border-width) * -1);
}
:where([data-cnx-widget], [data-cnx-container]) .page-item:first-child .page-link {
  border-top-left-radius: var(--wlp-bs-pagination-border-radius);
  border-bottom-left-radius: var(--wlp-bs-pagination-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .page-item:last-child .page-link {
  border-top-right-radius: var(--wlp-bs-pagination-border-radius);
  border-bottom-right-radius: var(--wlp-bs-pagination-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .pagination-lg {
  --wlp-bs-pagination-padding-x: 1.5rem;
  --wlp-bs-pagination-padding-y: 0.75rem;
  --wlp-bs-pagination-font-size: 1.25rem;
  --wlp-bs-pagination-border-radius: var(--wlp-bs-border-radius-lg);
}
:where([data-cnx-widget], [data-cnx-container]) .pagination-sm {
  --wlp-bs-pagination-padding-x: 0.5rem;
  --wlp-bs-pagination-padding-y: 0.25rem;
  --wlp-bs-pagination-font-size: 0.875rem;
  --wlp-bs-pagination-border-radius: var(--wlp-bs-border-radius-sm);
}
:where([data-cnx-widget], [data-cnx-container]) .badge {
  --wlp-bs-badge-padding-x: 0.65em;
  --wlp-bs-badge-padding-y: 0.35em;
  --wlp-bs-badge-font-size: 0.75em;
  --wlp-bs-badge-font-weight: 700;
  --wlp-bs-badge-color: #fff;
  --wlp-bs-badge-border-radius: var(--wlp-bs-border-radius);
  display: inline-block;
  padding: var(--wlp-bs-badge-padding-y) var(--wlp-bs-badge-padding-x);
  font-size: var(--wlp-bs-badge-font-size);
  font-weight: var(--wlp-bs-badge-font-weight);
  line-height: 1;
  color: var(--wlp-bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--wlp-bs-badge-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .badge:empty {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .btn .badge {
  position: relative;
  top: -1px;
}
:where([data-cnx-widget], [data-cnx-container]) .alert {
  --wlp-bs-alert-bg: transparent;
  --wlp-bs-alert-padding-x: 1rem;
  --wlp-bs-alert-padding-y: 1rem;
  --wlp-bs-alert-margin-bottom: 1rem;
  --wlp-bs-alert-color: inherit;
  --wlp-bs-alert-border-color: transparent;
  --wlp-bs-alert-border: var(--wlp-bs-border-width) solid var(--wlp-bs-alert-border-color);
  --wlp-bs-alert-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-alert-link-color: inherit;
  position: relative;
  padding: var(--wlp-bs-alert-padding-y) var(--wlp-bs-alert-padding-x);
  margin-bottom: var(--wlp-bs-alert-margin-bottom);
  color: var(--wlp-bs-alert-color);
  background-color: var(--wlp-bs-alert-bg);
  border: var(--wlp-bs-alert-border);
  border-radius: var(--wlp-bs-alert-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-heading {
  color: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .alert-link {
  font-weight: 700;
  color: var(--wlp-bs-alert-link-color);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-dismissible {
  padding-right: 3rem;
}
:where([data-cnx-widget], [data-cnx-container]) .alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
:where([data-cnx-widget], [data-cnx-container]) .alert-primary {
  --wlp-bs-alert-color: var(--wlp-bs-primary-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-primary-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-primary-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-primary-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-secondary {
  --wlp-bs-alert-color: var(--wlp-bs-secondary-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-secondary-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-secondary-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-secondary-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-success {
  --wlp-bs-alert-color: var(--wlp-bs-success-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-success-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-success-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-success-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-info {
  --wlp-bs-alert-color: var(--wlp-bs-info-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-info-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-info-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-info-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-warning {
  --wlp-bs-alert-color: var(--wlp-bs-warning-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-warning-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-warning-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-warning-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-danger {
  --wlp-bs-alert-color: var(--wlp-bs-danger-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-danger-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-danger-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-danger-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-light {
  --wlp-bs-alert-color: var(--wlp-bs-light-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-light-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-light-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-light-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .alert-dark {
  --wlp-bs-alert-color: var(--wlp-bs-dark-text-emphasis);
  --wlp-bs-alert-bg: var(--wlp-bs-dark-bg-subtle);
  --wlp-bs-alert-border-color: var(--wlp-bs-dark-border-subtle);
  --wlp-bs-alert-link-color: var(--wlp-bs-dark-text-emphasis);
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .progress,
:where([data-cnx-widget], [data-cnx-container]) .progress-stacked {
  --wlp-bs-progress-height: 1rem;
  --wlp-bs-progress-font-size: 0.75rem;
  --wlp-bs-progress-bg: var(--wlp-bs-secondary-bg);
  --wlp-bs-progress-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-progress-box-shadow: var(--wlp-bs-box-shadow-inset);
  --wlp-bs-progress-bar-color: #fff;
  --wlp-bs-progress-bar-bg: #f43369;
  --wlp-bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--wlp-bs-progress-height);
  overflow: hidden;
  font-size: var(--wlp-bs-progress-font-size);
  background-color: var(--wlp-bs-progress-bg);
  border-radius: var(--wlp-bs-progress-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--wlp-bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--wlp-bs-progress-bar-bg);
  transition: var(--wlp-bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .progress-bar {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--wlp-bs-progress-height) var(--wlp-bs-progress-height);
}
:where([data-cnx-widget], [data-cnx-container]) .progress-stacked > .progress {
  overflow: visible;
}
:where([data-cnx-widget], [data-cnx-container]) .progress-stacked > .progress > .progress-bar {
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .progress-bar-animated {
    animation: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .list-group {
  --wlp-bs-list-group-color: var(--wlp-bs-body-color);
  --wlp-bs-list-group-bg: var(--wlp-bs-body-bg);
  --wlp-bs-list-group-border-color: var(--wlp-bs-border-color);
  --wlp-bs-list-group-border-width: var(--wlp-bs-border-width);
  --wlp-bs-list-group-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-list-group-item-padding-x: 1rem;
  --wlp-bs-list-group-item-padding-y: 0.5rem;
  --wlp-bs-list-group-action-color: var(--wlp-bs-secondary-color);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-tertiary-bg);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-body-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-secondary-bg);
  --wlp-bs-list-group-disabled-color: var(--wlp-bs-secondary-color);
  --wlp-bs-list-group-disabled-bg: var(--wlp-bs-body-bg);
  --wlp-bs-list-group-active-color: #fff;
  --wlp-bs-list-group-active-bg: #f43369;
  --wlp-bs-list-group-active-border-color: #f43369;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--wlp-bs-list-group-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-action {
  width: 100%;
  color: var(--wlp-bs-list-group-action-color);
  text-align: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-action:hover, :where([data-cnx-widget], [data-cnx-container]) .list-group-item-action:focus {
  z-index: 1;
  color: var(--wlp-bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--wlp-bs-list-group-action-hover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-action:active {
  color: var(--wlp-bs-list-group-action-active-color);
  background-color: var(--wlp-bs-list-group-action-active-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item {
  position: relative;
  display: block;
  padding: var(--wlp-bs-list-group-item-padding-y) var(--wlp-bs-list-group-item-padding-x);
  color: var(--wlp-bs-list-group-color);
  text-decoration: none;
  background-color: var(--wlp-bs-list-group-bg);
  border: var(--wlp-bs-list-group-border-width) solid var(--wlp-bs-list-group-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item.disabled, :where([data-cnx-widget], [data-cnx-container]) .list-group-item:disabled {
  color: var(--wlp-bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--wlp-bs-list-group-disabled-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item.active {
  z-index: 2;
  color: var(--wlp-bs-list-group-active-color);
  background-color: var(--wlp-bs-list-group-active-bg);
  border-color: var(--wlp-bs-list-group-active-border-color);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item + .list-group-item {
  border-top-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--wlp-bs-list-group-border-width));
  border-top-width: var(--wlp-bs-list-group-border-width);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal {
  flex-direction: row;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
  border-top-right-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--wlp-bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--wlp-bs-list-group-border-width);
  border-left-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
  border-left-width: var(--wlp-bs-list-group-border-width);
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--wlp-bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--wlp-bs-list-group-border-width);
    border-left-width: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
    border-left-width: var(--wlp-bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--wlp-bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--wlp-bs-list-group-border-width);
    border-left-width: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
    border-left-width: var(--wlp-bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--wlp-bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--wlp-bs-list-group-border-width);
    border-left-width: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
    border-left-width: var(--wlp-bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--wlp-bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--wlp-bs-list-group-border-width);
    border-left-width: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
    border-left-width: var(--wlp-bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl {
    flex-direction: row;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--wlp-bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--wlp-bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--wlp-bs-list-group-border-width);
    border-left-width: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--wlp-bs-list-group-border-width));
    border-left-width: var(--wlp-bs-list-group-border-width);
  }
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-flush {
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-flush > .list-group-item {
  border-width: 0 0 var(--wlp-bs-list-group-border-width);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-primary {
  --wlp-bs-list-group-color: var(--wlp-bs-primary-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-primary-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-primary-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-primary-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-primary-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-primary-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-primary-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-primary-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-secondary {
  --wlp-bs-list-group-color: var(--wlp-bs-secondary-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-secondary-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-secondary-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-secondary-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-secondary-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-secondary-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-secondary-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-secondary-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-success {
  --wlp-bs-list-group-color: var(--wlp-bs-success-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-success-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-success-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-success-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-success-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-success-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-success-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-success-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-info {
  --wlp-bs-list-group-color: var(--wlp-bs-info-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-info-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-info-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-info-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-info-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-info-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-info-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-info-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-warning {
  --wlp-bs-list-group-color: var(--wlp-bs-warning-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-warning-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-warning-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-warning-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-warning-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-warning-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-warning-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-warning-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-danger {
  --wlp-bs-list-group-color: var(--wlp-bs-danger-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-danger-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-danger-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-danger-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-danger-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-danger-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-danger-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-danger-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-light {
  --wlp-bs-list-group-color: var(--wlp-bs-light-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-light-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-light-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-light-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-light-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-light-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-light-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-light-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .list-group-item-dark {
  --wlp-bs-list-group-color: var(--wlp-bs-dark-text-emphasis);
  --wlp-bs-list-group-bg: var(--wlp-bs-dark-bg-subtle);
  --wlp-bs-list-group-border-color: var(--wlp-bs-dark-border-subtle);
  --wlp-bs-list-group-action-hover-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-hover-bg: var(--wlp-bs-dark-border-subtle);
  --wlp-bs-list-group-action-active-color: var(--wlp-bs-emphasis-color);
  --wlp-bs-list-group-action-active-bg: var(--wlp-bs-dark-border-subtle);
  --wlp-bs-list-group-active-color: var(--wlp-bs-dark-bg-subtle);
  --wlp-bs-list-group-active-bg: var(--wlp-bs-dark-text-emphasis);
  --wlp-bs-list-group-active-border-color: var(--wlp-bs-dark-text-emphasis);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-close {
  --wlp-bs-btn-close-color: #000;
  --wlp-bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --wlp-bs-btn-close-opacity: 0.5;
  --wlp-bs-btn-close-hover-opacity: 0.75;
  --wlp-bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(244, 51, 105, 0.25);
  --wlp-bs-btn-close-focus-opacity: 1;
  --wlp-bs-btn-close-disabled-opacity: 0.25;
  --wlp-bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--wlp-bs-btn-close-color);
  background: transparent var(--wlp-bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--wlp-bs-btn-close-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-close:hover {
  color: var(--wlp-bs-btn-close-color);
  text-decoration: none;
  opacity: var(--wlp-bs-btn-close-hover-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-close:focus {
  outline: 0;
  box-shadow: var(--wlp-bs-btn-close-focus-shadow);
  opacity: var(--wlp-bs-btn-close-focus-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-close:disabled, :where([data-cnx-widget], [data-cnx-container]) .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--wlp-bs-btn-close-disabled-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .btn-close-white {
  filter: var(--wlp-bs-btn-close-white-filter);
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .btn-close {
  filter: var(--wlp-bs-btn-close-white-filter);
}
:where([data-cnx-widget], [data-cnx-container]) .toast {
  --wlp-bs-toast-zindex: 1090;
  --wlp-bs-toast-padding-x: 0.75rem;
  --wlp-bs-toast-padding-y: 0.5rem;
  --wlp-bs-toast-spacing: 1.5rem;
  --wlp-bs-toast-max-width: 350px;
  --wlp-bs-toast-font-size: 0.875rem;
  --wlp-bs-toast-color: ;
  --wlp-bs-toast-bg: rgba(var(--wlp-bs-body-bg-rgb), 0.85);
  --wlp-bs-toast-border-width: var(--wlp-bs-border-width);
  --wlp-bs-toast-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-toast-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-toast-box-shadow: var(--wlp-bs-box-shadow);
  --wlp-bs-toast-header-color: var(--wlp-bs-secondary-color);
  --wlp-bs-toast-header-bg: rgba(var(--wlp-bs-body-bg-rgb), 0.85);
  --wlp-bs-toast-header-border-color: var(--wlp-bs-border-color-translucent);
  width: var(--wlp-bs-toast-max-width);
  max-width: 100%;
  font-size: var(--wlp-bs-toast-font-size);
  color: var(--wlp-bs-toast-color);
  pointer-events: auto;
  background-color: var(--wlp-bs-toast-bg);
  background-clip: padding-box;
  border: var(--wlp-bs-toast-border-width) solid var(--wlp-bs-toast-border-color);
  box-shadow: var(--wlp-bs-toast-box-shadow);
  border-radius: var(--wlp-bs-toast-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .toast.showing {
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .toast:not(.show) {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .toast-container {
  --wlp-bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--wlp-bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
:where([data-cnx-widget], [data-cnx-container]) .toast-container > :not(:last-child) {
  margin-bottom: var(--wlp-bs-toast-spacing);
}
:where([data-cnx-widget], [data-cnx-container]) .toast-header {
  display: flex;
  align-items: center;
  padding: var(--wlp-bs-toast-padding-y) var(--wlp-bs-toast-padding-x);
  color: var(--wlp-bs-toast-header-color);
  background-color: var(--wlp-bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--wlp-bs-toast-border-width) solid var(--wlp-bs-toast-header-border-color);
  border-top-left-radius: calc(var(--wlp-bs-toast-border-radius) - var(--wlp-bs-toast-border-width));
  border-top-right-radius: calc(var(--wlp-bs-toast-border-radius) - var(--wlp-bs-toast-border-width));
}
:where([data-cnx-widget], [data-cnx-container]) .toast-header .btn-close {
  margin-right: calc(-0.5 * var(--wlp-bs-toast-padding-x));
  margin-left: var(--wlp-bs-toast-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .toast-body {
  padding: var(--wlp-bs-toast-padding-x);
  word-wrap: break-word;
}
:where([data-cnx-widget], [data-cnx-container]) .modal {
  --wlp-bs-modal-zindex: 1055;
  --wlp-bs-modal-width: 500px;
  --wlp-bs-modal-padding: 1rem;
  --wlp-bs-modal-margin: 0.5rem;
  --wlp-bs-modal-color: ;
  --wlp-bs-modal-bg: var(--wlp-bs-body-bg);
  --wlp-bs-modal-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-modal-border-width: var(--wlp-bs-border-width);
  --wlp-bs-modal-border-radius: var(--wlp-bs-border-radius-lg);
  --wlp-bs-modal-box-shadow: var(--wlp-bs-box-shadow-sm);
  --wlp-bs-modal-inner-border-radius: calc(var(--wlp-bs-border-radius-lg) - (var(--wlp-bs-border-width)));
  --wlp-bs-modal-header-padding-x: 1rem;
  --wlp-bs-modal-header-padding-y: 1rem;
  --wlp-bs-modal-header-padding: 1rem 1rem;
  --wlp-bs-modal-header-border-color: var(--wlp-bs-border-color);
  --wlp-bs-modal-header-border-width: var(--wlp-bs-border-width);
  --wlp-bs-modal-title-line-height: 1.5;
  --wlp-bs-modal-footer-gap: 0.5rem;
  --wlp-bs-modal-footer-bg: ;
  --wlp-bs-modal-footer-border-color: var(--wlp-bs-border-color);
  --wlp-bs-modal-footer-border-width: var(--wlp-bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--wlp-bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
  position: relative;
  width: auto;
  margin: var(--wlp-bs-modal-margin);
  pointer-events: none;
}
.modal.fade :where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade :where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
    transition: none;
  }
}
.modal.show :where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
  transform: none;
}
.modal.modal-static :where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
  transform: scale(1.02);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-dialog-scrollable {
  height: calc(100% - var(--wlp-bs-modal-margin) * 2);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--wlp-bs-modal-margin) * 2);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--wlp-bs-modal-color);
  pointer-events: auto;
  background-color: var(--wlp-bs-modal-bg);
  background-clip: padding-box;
  border: var(--wlp-bs-modal-border-width) solid var(--wlp-bs-modal-border-color);
  border-radius: var(--wlp-bs-modal-border-radius);
  outline: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-backdrop {
  --wlp-bs-backdrop-zindex: 1050;
  --wlp-bs-backdrop-bg: #000;
  --wlp-bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--wlp-bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--wlp-bs-backdrop-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-backdrop.fade {
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-backdrop.show {
  opacity: var(--wlp-bs-backdrop-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--wlp-bs-modal-header-padding);
  border-bottom: var(--wlp-bs-modal-header-border-width) solid var(--wlp-bs-modal-header-border-color);
  border-top-left-radius: var(--wlp-bs-modal-inner-border-radius);
  border-top-right-radius: var(--wlp-bs-modal-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-header .btn-close {
  padding: calc(var(--wlp-bs-modal-header-padding-y) * 0.5) calc(var(--wlp-bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--wlp-bs-modal-header-padding-y)) calc(-0.5 * var(--wlp-bs-modal-header-padding-x)) calc(-0.5 * var(--wlp-bs-modal-header-padding-y)) auto;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-title {
  margin-bottom: 0;
  line-height: var(--wlp-bs-modal-title-line-height);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--wlp-bs-modal-padding);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--wlp-bs-modal-padding) - var(--wlp-bs-modal-footer-gap) * 0.5);
  background-color: var(--wlp-bs-modal-footer-bg);
  border-top: var(--wlp-bs-modal-footer-border-width) solid var(--wlp-bs-modal-footer-border-color);
  border-bottom-right-radius: var(--wlp-bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--wlp-bs-modal-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .modal-footer > * {
  margin: calc(var(--wlp-bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal {
    --wlp-bs-modal-margin: 1.75rem;
    --wlp-bs-modal-box-shadow: var(--wlp-bs-box-shadow);
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-dialog {
    max-width: var(--wlp-bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-sm {
    --wlp-bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-lg,
  :where([data-cnx-widget], [data-cnx-container]) .modal-xl {
    --wlp-bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-xl {
    --wlp-bs-modal-width: 1140px;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen .modal-header,
:where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen .modal-footer {
  border-radius: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-sm-down .modal-header,
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-md-down .modal-header,
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-lg-down .modal-header,
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xl-down .modal-header,
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xxl-down .modal-header,
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  :where([data-cnx-widget], [data-cnx-container]) .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .tooltip {
  --wlp-bs-tooltip-zindex: 1080;
  --wlp-bs-tooltip-max-width: 200px;
  --wlp-bs-tooltip-padding-x: 0.5rem;
  --wlp-bs-tooltip-padding-y: 0.25rem;
  --wlp-bs-tooltip-margin: ;
  --wlp-bs-tooltip-font-size: 0.875rem;
  --wlp-bs-tooltip-color: var(--wlp-bs-body-bg);
  --wlp-bs-tooltip-bg: var(--wlp-bs-emphasis-color);
  --wlp-bs-tooltip-border-radius: var(--wlp-bs-border-radius);
  --wlp-bs-tooltip-opacity: 0.9;
  --wlp-bs-tooltip-arrow-width: 0.8rem;
  --wlp-bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--wlp-bs-tooltip-zindex);
  display: block;
  margin: var(--wlp-bs-tooltip-margin);
  font-family: var(--wlp-bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--wlp-bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .tooltip.show {
  opacity: var(--wlp-bs-tooltip-opacity);
}
:where([data-cnx-widget], [data-cnx-container]) .tooltip .tooltip-arrow {
  display: block;
  width: var(--wlp-bs-tooltip-arrow-width);
  height: var(--wlp-bs-tooltip-arrow-height);
}
:where([data-cnx-widget], [data-cnx-container]) .tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-top .tooltip-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--wlp-bs-tooltip-arrow-height));
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-top .tooltip-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--wlp-bs-tooltip-arrow-height) calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--wlp-bs-tooltip-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-end .tooltip-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--wlp-bs-tooltip-arrow-height));
  width: var(--wlp-bs-tooltip-arrow-height);
  height: var(--wlp-bs-tooltip-arrow-width);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-end .tooltip-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) var(--wlp-bs-tooltip-arrow-height) calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--wlp-bs-tooltip-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-bottom .tooltip-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--wlp-bs-tooltip-arrow-height));
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-bottom .tooltip-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) var(--wlp-bs-tooltip-arrow-height);
  border-bottom-color: var(--wlp-bs-tooltip-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-start .tooltip-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--wlp-bs-tooltip-arrow-height));
  width: var(--wlp-bs-tooltip-arrow-height);
  height: var(--wlp-bs-tooltip-arrow-width);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-start .tooltip-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) 0 calc(var(--wlp-bs-tooltip-arrow-width) * 0.5) var(--wlp-bs-tooltip-arrow-height);
  border-left-color: var(--wlp-bs-tooltip-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .tooltip-inner {
  max-width: var(--wlp-bs-tooltip-max-width);
  padding: var(--wlp-bs-tooltip-padding-y) var(--wlp-bs-tooltip-padding-x);
  color: var(--wlp-bs-tooltip-color);
  text-align: center;
  background-color: var(--wlp-bs-tooltip-bg);
  border-radius: var(--wlp-bs-tooltip-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .popover {
  --wlp-bs-popover-zindex: 1070;
  --wlp-bs-popover-max-width: 276px;
  --wlp-bs-popover-font-size: 0.875rem;
  --wlp-bs-popover-bg: var(--wlp-bs-body-bg);
  --wlp-bs-popover-border-width: var(--wlp-bs-border-width);
  --wlp-bs-popover-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-popover-border-radius: var(--wlp-bs-border-radius-lg);
  --wlp-bs-popover-inner-border-radius: calc(var(--wlp-bs-border-radius-lg) - var(--wlp-bs-border-width));
  --wlp-bs-popover-box-shadow: var(--wlp-bs-box-shadow);
  --wlp-bs-popover-header-padding-x: 1rem;
  --wlp-bs-popover-header-padding-y: 0.5rem;
  --wlp-bs-popover-header-font-size: 1rem;
  --wlp-bs-popover-header-color: inherit;
  --wlp-bs-popover-header-bg: var(--wlp-bs-secondary-bg);
  --wlp-bs-popover-body-padding-x: 1rem;
  --wlp-bs-popover-body-padding-y: 1rem;
  --wlp-bs-popover-body-color: var(--wlp-bs-body-color);
  --wlp-bs-popover-arrow-width: 1rem;
  --wlp-bs-popover-arrow-height: 0.5rem;
  --wlp-bs-popover-arrow-border: var(--wlp-bs-popover-border-color);
  z-index: var(--wlp-bs-popover-zindex);
  display: block;
  max-width: var(--wlp-bs-popover-max-width);
  font-family: var(--wlp-bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--wlp-bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--wlp-bs-popover-bg);
  background-clip: padding-box;
  border: var(--wlp-bs-popover-border-width) solid var(--wlp-bs-popover-border-color);
  border-radius: var(--wlp-bs-popover-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .popover .popover-arrow {
  display: block;
  width: var(--wlp-bs-popover-arrow-width);
  height: var(--wlp-bs-popover-arrow-height);
}
:where([data-cnx-widget], [data-cnx-container]) .popover .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-top > .popover-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--wlp-bs-popover-arrow-height)) - var(--wlp-bs-popover-border-width));
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-top > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-top > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--wlp-bs-popover-arrow-height) calc(var(--wlp-bs-popover-arrow-width) * 0.5) 0;
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-top > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--wlp-bs-popover-arrow-border);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-top > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--wlp-bs-popover-border-width);
  border-top-color: var(--wlp-bs-popover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-end > .popover-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--wlp-bs-popover-arrow-height)) - var(--wlp-bs-popover-border-width));
  width: var(--wlp-bs-popover-arrow-height);
  height: var(--wlp-bs-popover-arrow-width);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-end > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-end > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--wlp-bs-popover-arrow-width) * 0.5) var(--wlp-bs-popover-arrow-height) calc(var(--wlp-bs-popover-arrow-width) * 0.5) 0;
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-end > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--wlp-bs-popover-arrow-border);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-end > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--wlp-bs-popover-border-width);
  border-right-color: var(--wlp-bs-popover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom > .popover-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--wlp-bs-popover-arrow-height)) - var(--wlp-bs-popover-border-width));
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--wlp-bs-popover-arrow-width) * 0.5) var(--wlp-bs-popover-arrow-height);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--wlp-bs-popover-arrow-border);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--wlp-bs-popover-border-width);
  border-bottom-color: var(--wlp-bs-popover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-bottom .popover-header::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--wlp-bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--wlp-bs-popover-arrow-width));
  content: "";
  border-bottom: var(--wlp-bs-popover-border-width) solid var(--wlp-bs-popover-header-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-start > .popover-arrow, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--wlp-bs-popover-arrow-height)) - var(--wlp-bs-popover-border-width));
  width: var(--wlp-bs-popover-arrow-height);
  height: var(--wlp-bs-popover-arrow-width);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-start > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-start > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--wlp-bs-popover-arrow-width) * 0.5) 0 calc(var(--wlp-bs-popover-arrow-width) * 0.5) var(--wlp-bs-popover-arrow-height);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-start > .popover-arrow::before, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--wlp-bs-popover-arrow-border);
}
:where([data-cnx-widget], [data-cnx-container]) .bs-popover-start > .popover-arrow::after, :where([data-cnx-widget], [data-cnx-container]) .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--wlp-bs-popover-border-width);
  border-left-color: var(--wlp-bs-popover-bg);
}
:where([data-cnx-widget], [data-cnx-container]) .popover-header {
  padding: var(--wlp-bs-popover-header-padding-y) var(--wlp-bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--wlp-bs-popover-header-font-size);
  color: var(--wlp-bs-popover-header-color);
  background-color: var(--wlp-bs-popover-header-bg);
  border-bottom: var(--wlp-bs-popover-border-width) solid var(--wlp-bs-popover-border-color);
  border-top-left-radius: var(--wlp-bs-popover-inner-border-radius);
  border-top-right-radius: var(--wlp-bs-popover-inner-border-radius);
}
:where([data-cnx-widget], [data-cnx-container]) .popover-header:empty {
  display: none;
}
:where([data-cnx-widget], [data-cnx-container]) .popover-body {
  padding: var(--wlp-bs-popover-body-padding-y) var(--wlp-bs-popover-body-padding-x);
  color: var(--wlp-bs-popover-body-color);
}
:where([data-cnx-widget], [data-cnx-container]) .carousel {
  position: relative;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel.pointer-event {
  touch-action: pan-y;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .carousel-item {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-item.active,
:where([data-cnx-widget], [data-cnx-container]) .carousel-item-next,
:where([data-cnx-widget], [data-cnx-container]) .carousel-item-prev {
  display: block;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-item-next:not(.carousel-item-start),
:where([data-cnx-widget], [data-cnx-container]) .active.carousel-item-end {
  transform: translateX(100%);
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-item-prev:not(.carousel-item-end),
:where([data-cnx-widget], [data-cnx-container]) .active.carousel-item-start {
  transform: translateX(-100%);
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .carousel-item.active,
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .carousel-item-next.carousel-item-start,
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .active.carousel-item-start,
:where([data-cnx-widget], [data-cnx-container]) .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .carousel-fade .active.carousel-item-start,
  :where([data-cnx-widget], [data-cnx-container]) .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev,
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev,
  :where([data-cnx-widget], [data-cnx-container]) .carousel-control-next {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev:hover, :where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev:focus,
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next:hover,
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev {
  left: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next {
  right: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev-icon,
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-indicators .active {
  opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-dark .carousel-control-prev-icon,
:where([data-cnx-widget], [data-cnx-container]) .carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
:where([data-cnx-widget], [data-cnx-container]) .carousel-dark .carousel-caption {
  color: #000;
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .carousel .carousel-control-prev-icon,
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .carousel .carousel-control-next-icon, :where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark].carousel .carousel-control-prev-icon,
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], :where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
:where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark] .carousel .carousel-caption, :where([data-cnx-widget], [data-cnx-container]) [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}
:where([data-cnx-widget], [data-cnx-container]) .spinner-grow,
:where([data-cnx-widget], [data-cnx-container]) .spinner-border {
  display: inline-block;
  width: var(--wlp-bs-spinner-width);
  height: var(--wlp-bs-spinner-height);
  vertical-align: var(--wlp-bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--wlp-bs-spinner-animation-speed) linear infinite var(--wlp-bs-spinner-animation-name);
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .spinner-border {
  --wlp-bs-spinner-width: 2rem;
  --wlp-bs-spinner-height: 2rem;
  --wlp-bs-spinner-vertical-align: -0.125em;
  --wlp-bs-spinner-border-width: 0.25em;
  --wlp-bs-spinner-animation-speed: 0.75s;
  --wlp-bs-spinner-animation-name: spinner-border;
  border: var(--wlp-bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}
:where([data-cnx-widget], [data-cnx-container]) .spinner-border-sm {
  --wlp-bs-spinner-width: 1rem;
  --wlp-bs-spinner-height: 1rem;
  --wlp-bs-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .spinner-grow {
  --wlp-bs-spinner-width: 2rem;
  --wlp-bs-spinner-height: 2rem;
  --wlp-bs-spinner-vertical-align: -0.125em;
  --wlp-bs-spinner-animation-speed: 0.75s;
  --wlp-bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .spinner-grow-sm {
  --wlp-bs-spinner-width: 1rem;
  --wlp-bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .spinner-border,
  :where([data-cnx-widget], [data-cnx-container]) .spinner-grow {
    --wlp-bs-spinner-animation-speed: 1.5s;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm {
  --wlp-bs-offcanvas-zindex: 1045;
  --wlp-bs-offcanvas-width: 400px;
  --wlp-bs-offcanvas-height: 30vh;
  --wlp-bs-offcanvas-padding-x: 1rem;
  --wlp-bs-offcanvas-padding-y: 1rem;
  --wlp-bs-offcanvas-color: var(--wlp-bs-body-color);
  --wlp-bs-offcanvas-bg: var(--wlp-bs-body-bg);
  --wlp-bs-offcanvas-border-width: var(--wlp-bs-border-width);
  --wlp-bs-offcanvas-border-color: var(--wlp-bs-border-color-translucent);
  --wlp-bs-offcanvas-box-shadow: var(--wlp-bs-box-shadow-sm);
  --wlp-bs-offcanvas-transition: transform 0.3s ease-in-out;
  --wlp-bs-offcanvas-title-line-height: 1.5;
}
@media (max-width: 575.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--wlp-bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--wlp-bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--wlp-bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--wlp-bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm {
    --wlp-bs-offcanvas-height: auto;
    --wlp-bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--wlp-bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--wlp-bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--wlp-bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--wlp-bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md {
    --wlp-bs-offcanvas-height: auto;
    --wlp-bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 991.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--wlp-bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--wlp-bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--wlp-bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--wlp-bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg {
    --wlp-bs-offcanvas-height: auto;
    --wlp-bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1199.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--wlp-bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--wlp-bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--wlp-bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--wlp-bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl {
    --wlp-bs-offcanvas-height: auto;
    --wlp-bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
@media (max-width: 1399.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--wlp-bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--wlp-bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--wlp-bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--wlp-bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--wlp-bs-offcanvas-width);
    border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--wlp-bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl {
    --wlp-bs-offcanvas-height: auto;
    --wlp-bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--wlp-bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--wlp-bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--wlp-bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--wlp-bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .offcanvas {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--wlp-bs-offcanvas-width);
  border-right: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
  transform: translateX(-100%);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--wlp-bs-offcanvas-width);
  border-left: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
  transform: translateX(100%);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--wlp-bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
  transform: translateY(-100%);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--wlp-bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--wlp-bs-offcanvas-border-width) solid var(--wlp-bs-offcanvas-border-color);
  transform: translateY(100%);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas.show:not(.hiding) {
  transform: none;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas.showing, :where([data-cnx-widget], [data-cnx-container]) .offcanvas.hiding, :where([data-cnx-widget], [data-cnx-container]) .offcanvas.show {
  visibility: visible;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-backdrop.fade {
  opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-backdrop.show {
  opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--wlp-bs-offcanvas-padding-y) var(--wlp-bs-offcanvas-padding-x);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-header .btn-close {
  padding: calc(var(--wlp-bs-offcanvas-padding-y) * 0.5) calc(var(--wlp-bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--wlp-bs-offcanvas-padding-y)) calc(-0.5 * var(--wlp-bs-offcanvas-padding-x)) calc(-0.5 * var(--wlp-bs-offcanvas-padding-y)) auto;
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-title {
  margin-bottom: 0;
  line-height: var(--wlp-bs-offcanvas-title-line-height);
}
:where([data-cnx-widget], [data-cnx-container]) .offcanvas-body {
  flex-grow: 1;
  padding: var(--wlp-bs-offcanvas-padding-y) var(--wlp-bs-offcanvas-padding-x);
  overflow-y: auto;
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder.btn::before {
  display: inline-block;
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder-xs {
  min-height: 0.6em;
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder-sm {
  min-height: 0.8em;
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder-lg {
  min-height: 1.2em;
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}
@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--wlp-bs-primary-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--wlp-bs-secondary-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--wlp-bs-success-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--wlp-bs-info-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--wlp-bs-warning-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--wlp-bs-danger-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--wlp-bs-light-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--wlp-bs-dark-rgb), var(--wlp-bs-bg-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-primary {
  color: RGBA(var(--wlp-bs-primary-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-primary-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-primary:hover, :where([data-cnx-widget], [data-cnx-container]) .link-primary:focus {
  color: RGBA(195, 41, 84, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(195, 41, 84, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-secondary {
  color: RGBA(var(--wlp-bs-secondary-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-secondary-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-secondary:hover, :where([data-cnx-widget], [data-cnx-container]) .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-success {
  color: RGBA(var(--wlp-bs-success-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-success-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-success:hover, :where([data-cnx-widget], [data-cnx-container]) .link-success:focus {
  color: RGBA(20, 108, 67, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-info {
  color: RGBA(var(--wlp-bs-info-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-info-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-info:hover, :where([data-cnx-widget], [data-cnx-container]) .link-info:focus {
  color: RGBA(61, 213, 243, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-warning {
  color: RGBA(var(--wlp-bs-warning-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-warning-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-warning:hover, :where([data-cnx-widget], [data-cnx-container]) .link-warning:focus {
  color: RGBA(255, 205, 57, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-danger {
  color: RGBA(var(--wlp-bs-danger-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-danger-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-danger:hover, :where([data-cnx-widget], [data-cnx-container]) .link-danger:focus {
  color: RGBA(176, 42, 55, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-light {
  color: RGBA(var(--wlp-bs-light-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-light-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-light:hover, :where([data-cnx-widget], [data-cnx-container]) .link-light:focus {
  color: RGBA(249, 250, 251, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-dark {
  color: RGBA(var(--wlp-bs-dark-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-dark-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-dark:hover, :where([data-cnx-widget], [data-cnx-container]) .link-dark:focus {
  color: RGBA(26, 30, 33, var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-body-emphasis {
  color: RGBA(var(--wlp-bs-emphasis-color-rgb), var(--wlp-bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-emphasis-color-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-body-emphasis:hover, :where([data-cnx-widget], [data-cnx-container]) .link-body-emphasis:focus {
  color: RGBA(var(--wlp-bs-emphasis-color-rgb), var(--wlp-bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--wlp-bs-emphasis-color-rgb), var(--wlp-bs-link-underline-opacity, 0.75)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring:focus {
  outline: 0;
  box-shadow: var(--wlp-bs-focus-ring-x, 0) var(--wlp-bs-focus-ring-y, 0) var(--wlp-bs-focus-ring-blur, 0) var(--wlp-bs-focus-ring-width) var(--wlp-bs-focus-ring-color);
}
:where([data-cnx-widget], [data-cnx-container]) .icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--wlp-bs-link-color-rgb), var(--wlp-bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
:where([data-cnx-widget], [data-cnx-container]) .icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  :where([data-cnx-widget], [data-cnx-container]) .icon-link > .bi {
    transition: none;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .icon-link-hover:hover > .bi, :where([data-cnx-widget], [data-cnx-container]) .icon-link-hover:focus-visible > .bi {
  transform: var(--wlp-bs-icon-link-transform, translate3d(0.25em, 0, 0));
}
:where([data-cnx-widget], [data-cnx-container]) .ratio {
  position: relative;
  width: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .ratio::before {
  display: block;
  padding-top: var(--wlp-bs-aspect-ratio);
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .ratio-1x1 {
  --wlp-bs-aspect-ratio: 100%;
}
:where([data-cnx-widget], [data-cnx-container]) .ratio-4x3 {
  --wlp-bs-aspect-ratio: 75%;
}
:where([data-cnx-widget], [data-cnx-container]) .ratio-16x9 {
  --wlp-bs-aspect-ratio: 56.25%;
}
:where([data-cnx-widget], [data-cnx-container]) .ratio-21x9 {
  --wlp-bs-aspect-ratio: 42.8571428571%;
}
:where([data-cnx-widget], [data-cnx-container]) .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
:where([data-cnx-widget], [data-cnx-container]) .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
:where([data-cnx-widget], [data-cnx-container]) .sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
:where([data-cnx-widget], [data-cnx-container]) .sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  :where([data-cnx-widget], [data-cnx-container]) .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  :where([data-cnx-widget], [data-cnx-container]) .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  :where([data-cnx-widget], [data-cnx-container]) .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  :where([data-cnx-widget], [data-cnx-container]) .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  :where([data-cnx-widget], [data-cnx-container]) .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
:where([data-cnx-widget], [data-cnx-container]) .hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
:where([data-cnx-widget], [data-cnx-container]) .vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
:where([data-cnx-widget], [data-cnx-container]) .visually-hidden,
:where([data-cnx-widget], [data-cnx-container]) .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .visually-hidden:not(caption),
:where([data-cnx-widget], [data-cnx-container]) .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}
:where([data-cnx-widget], [data-cnx-container]) .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
:where([data-cnx-widget], [data-cnx-container]) .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:where([data-cnx-widget], [data-cnx-container]) .vr {
  display: inline-block;
  align-self: stretch;
  width: var(--wlp-bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .align-baseline {
  vertical-align: baseline !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-top {
  vertical-align: top !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-middle {
  vertical-align: middle !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-bottom {
  vertical-align: bottom !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-text-bottom {
  vertical-align: text-bottom !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-text-top {
  vertical-align: text-top !important;
}
:where([data-cnx-widget], [data-cnx-container]) .float-start {
  float: left !important;
}
:where([data-cnx-widget], [data-cnx-container]) .float-end {
  float: right !important;
}
:where([data-cnx-widget], [data-cnx-container]) .float-none {
  float: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .object-fit-contain {
  object-fit: contain !important;
}
:where([data-cnx-widget], [data-cnx-container]) .object-fit-cover {
  object-fit: cover !important;
}
:where([data-cnx-widget], [data-cnx-container]) .object-fit-fill {
  object-fit: fill !important;
}
:where([data-cnx-widget], [data-cnx-container]) .object-fit-scale {
  object-fit: scale-down !important;
}
:where([data-cnx-widget], [data-cnx-container]) .object-fit-none {
  object-fit: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .opacity-0 {
  opacity: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .opacity-25 {
  opacity: 0.25 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .opacity-50 {
  opacity: 0.5 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .opacity-75 {
  opacity: 0.75 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .opacity-100 {
  opacity: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-auto {
  overflow: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-hidden {
  overflow: hidden !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-visible {
  overflow: visible !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-scroll {
  overflow: scroll !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-x-auto {
  overflow-x: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-x-hidden {
  overflow-x: hidden !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-x-visible {
  overflow-x: visible !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-x-scroll {
  overflow-x: scroll !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-y-auto {
  overflow-y: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-y-hidden {
  overflow-y: hidden !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-y-visible {
  overflow-y: visible !important;
}
:where([data-cnx-widget], [data-cnx-container]) .overflow-y-scroll {
  overflow-y: scroll !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-inline {
  display: inline !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-inline-block {
  display: inline-block !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-block {
  display: block !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-grid {
  display: grid !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-inline-grid {
  display: inline-grid !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-table {
  display: table !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-table-row {
  display: table-row !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-table-cell {
  display: table-cell !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-flex {
  display: flex !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-inline-flex {
  display: inline-flex !important;
}
:where([data-cnx-widget], [data-cnx-container]) .d-none {
  display: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .shadow {
  box-shadow: var(--wlp-bs-box-shadow) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .shadow-sm {
  box-shadow: var(--wlp-bs-box-shadow-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .shadow-lg {
  box-shadow: var(--wlp-bs-box-shadow-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .shadow-none {
  box-shadow: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-primary {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-primary-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-secondary {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-secondary-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-success {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-success-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-info {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-info-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-warning {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-warning-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-danger {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-danger-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-light {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-light-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .focus-ring-dark {
  --wlp-bs-focus-ring-color: rgba(var(--wlp-bs-dark-rgb), var(--wlp-bs-focus-ring-opacity));
}
:where([data-cnx-widget], [data-cnx-container]) .position-static {
  position: static !important;
}
:where([data-cnx-widget], [data-cnx-container]) .position-relative {
  position: relative !important;
}
:where([data-cnx-widget], [data-cnx-container]) .position-absolute {
  position: absolute !important;
}
:where([data-cnx-widget], [data-cnx-container]) .position-fixed {
  position: fixed !important;
}
:where([data-cnx-widget], [data-cnx-container]) .position-sticky {
  position: sticky !important;
}
:where([data-cnx-widget], [data-cnx-container]) .top-0 {
  top: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .top-50 {
  top: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .top-100 {
  top: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bottom-0 {
  bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bottom-50 {
  bottom: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bottom-100 {
  bottom: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .start-0 {
  left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .start-50 {
  left: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .start-100 {
  left: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .end-0 {
  right: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .end-50 {
  right: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .end-100 {
  right: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .translate-middle {
  transform: translate(-50%, -50%) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .translate-middle-x {
  transform: translateX(-50%) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .translate-middle-y {
  transform: translateY(-50%) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border {
  border: var(--wlp-bs-border-width) var(--wlp-bs-border-style) var(--wlp-bs-border-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-0 {
  border: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-top {
  border-top: var(--wlp-bs-border-width) var(--wlp-bs-border-style) var(--wlp-bs-border-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-top-0 {
  border-top: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-end {
  border-right: var(--wlp-bs-border-width) var(--wlp-bs-border-style) var(--wlp-bs-border-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-end-0 {
  border-right: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-bottom {
  border-bottom: var(--wlp-bs-border-width) var(--wlp-bs-border-style) var(--wlp-bs-border-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-bottom-0 {
  border-bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-start {
  border-left: var(--wlp-bs-border-width) var(--wlp-bs-border-style) var(--wlp-bs-border-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-start-0 {
  border-left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-primary {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-primary-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-secondary {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-secondary-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-success {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-success-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-info {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-info-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-warning {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-warning-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-danger {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-danger-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-light {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-light-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-dark {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-dark-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-black {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-black-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-white {
  --wlp-bs-border-opacity: 1;
  border-color: rgba(var(--wlp-bs-white-rgb), var(--wlp-bs-border-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-primary-subtle {
  border-color: var(--wlp-bs-primary-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-secondary-subtle {
  border-color: var(--wlp-bs-secondary-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-success-subtle {
  border-color: var(--wlp-bs-success-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-info-subtle {
  border-color: var(--wlp-bs-info-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-warning-subtle {
  border-color: var(--wlp-bs-warning-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-danger-subtle {
  border-color: var(--wlp-bs-danger-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-light-subtle {
  border-color: var(--wlp-bs-light-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-dark-subtle {
  border-color: var(--wlp-bs-dark-border-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-1 {
  border-width: 1px !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-2 {
  border-width: 2px !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-3 {
  border-width: 3px !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-4 {
  border-width: 4px !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-5 {
  border-width: 5px !important;
}
:where([data-cnx-widget], [data-cnx-container]) .border-opacity-10 {
  --wlp-bs-border-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .border-opacity-25 {
  --wlp-bs-border-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .border-opacity-50 {
  --wlp-bs-border-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .border-opacity-75 {
  --wlp-bs-border-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .border-opacity-100 {
  --wlp-bs-border-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .w-25 {
  width: 25% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .w-50 {
  width: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .w-75 {
  width: 75% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .w-100 {
  width: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .w-auto {
  width: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mw-100 {
  max-width: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .vw-100 {
  width: 100vw !important;
}
:where([data-cnx-widget], [data-cnx-container]) .min-vw-100 {
  min-width: 100vw !important;
}
:where([data-cnx-widget], [data-cnx-container]) .h-25 {
  height: 25% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .h-50 {
  height: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .h-75 {
  height: 75% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .h-100 {
  height: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .h-auto {
  height: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mh-100 {
  max-height: 100% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .vh-100 {
  height: 100vh !important;
}
:where([data-cnx-widget], [data-cnx-container]) .min-vh-100 {
  min-height: 100vh !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-fill {
  flex: 1 1 auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-row {
  flex-direction: row !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-column {
  flex-direction: column !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-row-reverse {
  flex-direction: row-reverse !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-column-reverse {
  flex-direction: column-reverse !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-grow-0 {
  flex-grow: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-grow-1 {
  flex-grow: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-shrink-0 {
  flex-shrink: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-shrink-1 {
  flex-shrink: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-wrap {
  flex-wrap: wrap !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-nowrap {
  flex-wrap: nowrap !important;
}
:where([data-cnx-widget], [data-cnx-container]) .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-start {
  justify-content: flex-start !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-end {
  justify-content: flex-end !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-center {
  justify-content: center !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-between {
  justify-content: space-between !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-around {
  justify-content: space-around !important;
}
:where([data-cnx-widget], [data-cnx-container]) .justify-content-evenly {
  justify-content: space-evenly !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-items-start {
  align-items: flex-start !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-items-end {
  align-items: flex-end !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-items-center {
  align-items: center !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-items-baseline {
  align-items: baseline !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-items-stretch {
  align-items: stretch !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-start {
  align-content: flex-start !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-end {
  align-content: flex-end !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-center {
  align-content: center !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-between {
  align-content: space-between !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-around {
  align-content: space-around !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-content-stretch {
  align-content: stretch !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-auto {
  align-self: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-start {
  align-self: flex-start !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-end {
  align-self: flex-end !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-center {
  align-self: center !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-baseline {
  align-self: baseline !important;
}
:where([data-cnx-widget], [data-cnx-container]) .align-self-stretch {
  align-self: stretch !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-first {
  order: -1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-0 {
  order: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-1 {
  order: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-2 {
  order: 2 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-3 {
  order: 3 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-4 {
  order: 4 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-5 {
  order: 5 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .order-last {
  order: 6 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-0 {
  margin: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-1 {
  margin: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-2 {
  margin: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-3 {
  margin: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-4 {
  margin: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-5 {
  margin: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .m-auto {
  margin: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-0 {
  margin-top: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-1 {
  margin-top: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-2 {
  margin-top: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-3 {
  margin-top: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-4 {
  margin-top: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-5 {
  margin-top: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mt-auto {
  margin-top: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-0 {
  margin-right: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-1 {
  margin-right: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-2 {
  margin-right: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-3 {
  margin-right: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-4 {
  margin-right: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-5 {
  margin-right: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .me-auto {
  margin-right: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-0 {
  margin-bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-1 {
  margin-bottom: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-2 {
  margin-bottom: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-3 {
  margin-bottom: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-4 {
  margin-bottom: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-5 {
  margin-bottom: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .mb-auto {
  margin-bottom: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-0 {
  margin-left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-1 {
  margin-left: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-2 {
  margin-left: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-3 {
  margin-left: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-4 {
  margin-left: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-5 {
  margin-left: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ms-auto {
  margin-left: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-0 {
  padding: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-1 {
  padding: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-2 {
  padding: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-3 {
  padding: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-4 {
  padding: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .p-5 {
  padding: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-0 {
  padding-top: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-1 {
  padding-top: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-2 {
  padding-top: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-3 {
  padding-top: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-4 {
  padding-top: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pt-5 {
  padding-top: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-0 {
  padding-right: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-1 {
  padding-right: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-2 {
  padding-right: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-3 {
  padding-right: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-4 {
  padding-right: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-5 {
  padding-right: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-0 {
  padding-bottom: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-1 {
  padding-bottom: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-2 {
  padding-bottom: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-3 {
  padding-bottom: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-4 {
  padding-bottom: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pb-5 {
  padding-bottom: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-0 {
  padding-left: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-1 {
  padding-left: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-2 {
  padding-left: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-3 {
  padding-left: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-4 {
  padding-left: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .ps-5 {
  padding-left: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-0 {
  gap: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-1 {
  gap: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-2 {
  gap: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-3 {
  gap: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-4 {
  gap: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .gap-5 {
  gap: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-0 {
  row-gap: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-1 {
  row-gap: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-2 {
  row-gap: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-3 {
  row-gap: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-4 {
  row-gap: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .row-gap-5 {
  row-gap: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-0 {
  column-gap: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-1 {
  column-gap: 0.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-2 {
  column-gap: 0.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-3 {
  column-gap: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-4 {
  column-gap: 1.5rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .column-gap-5 {
  column-gap: 3rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .font-monospace {
  font-family: var(--wlp-bs-font-monospace) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-1 {
  font-size: 2em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-2 {
  font-size: 1.5em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-3 {
  font-size: 1.17em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-4 {
  font-size: 1em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-5 {
  font-size: 1.25rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fs-6 {
  font-size: 1rem !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fst-italic {
  font-style: italic !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fst-normal {
  font-style: normal !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-lighter {
  font-weight: lighter !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-light {
  font-weight: 300 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-normal {
  font-weight: 400 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-medium {
  font-weight: 500 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-semibold {
  font-weight: 600 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-bold {
  font-weight: 700 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .fw-bolder {
  font-weight: bolder !important;
}
:where([data-cnx-widget], [data-cnx-container]) .lh-1 {
  line-height: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .lh-sm {
  line-height: 1.25 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .lh-base {
  line-height: 1.5 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .lh-lg {
  line-height: 2 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-start {
  text-align: left !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-end {
  text-align: right !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-center {
  text-align: center !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-decoration-none {
  text-decoration: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-decoration-underline {
  text-decoration: underline !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-decoration-line-through {
  text-decoration: line-through !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-lowercase {
  text-transform: lowercase !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-uppercase {
  text-transform: uppercase !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-capitalize {
  text-transform: capitalize !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-wrap {
  white-space: normal !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-nowrap {
  white-space: nowrap !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-primary {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-primary-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-secondary {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-secondary-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-success {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-success-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-info {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-info-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-warning {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-warning-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-danger {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-danger-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-light {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-light-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-dark {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-dark-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-black {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-black-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-white {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-white-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-body {
  --wlp-bs-text-opacity: 1;
  color: rgba(var(--wlp-bs-body-color-rgb), var(--wlp-bs-text-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-muted {
  --wlp-bs-text-opacity: 1;
  color: var(--wlp-bs-secondary-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-black-50 {
  --wlp-bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-white-50 {
  --wlp-bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-body-secondary {
  --wlp-bs-text-opacity: 1;
  color: var(--wlp-bs-secondary-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-body-tertiary {
  --wlp-bs-text-opacity: 1;
  color: var(--wlp-bs-tertiary-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-body-emphasis {
  --wlp-bs-text-opacity: 1;
  color: var(--wlp-bs-emphasis-color) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-reset {
  --wlp-bs-text-opacity: 1;
  color: inherit !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-opacity-25 {
  --wlp-bs-text-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .text-opacity-50 {
  --wlp-bs-text-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .text-opacity-75 {
  --wlp-bs-text-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .text-opacity-100 {
  --wlp-bs-text-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .text-primary-emphasis {
  color: var(--wlp-bs-primary-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-secondary-emphasis {
  color: var(--wlp-bs-secondary-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-success-emphasis {
  color: var(--wlp-bs-success-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-info-emphasis {
  color: var(--wlp-bs-info-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-warning-emphasis {
  color: var(--wlp-bs-warning-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-danger-emphasis {
  color: var(--wlp-bs-danger-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-light-emphasis {
  color: var(--wlp-bs-light-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .text-dark-emphasis {
  color: var(--wlp-bs-dark-text-emphasis) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-10 {
  --wlp-bs-link-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-10-hover:hover {
  --wlp-bs-link-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-25 {
  --wlp-bs-link-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-25-hover:hover {
  --wlp-bs-link-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-50 {
  --wlp-bs-link-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-50-hover:hover {
  --wlp-bs-link-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-75 {
  --wlp-bs-link-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-75-hover:hover {
  --wlp-bs-link-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-100 {
  --wlp-bs-link-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-opacity-100-hover:hover {
  --wlp-bs-link-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-1 {
  text-underline-offset: 0.125em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-2 {
  text-underline-offset: 0.25em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-3 {
  text-underline-offset: 0.375em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-primary {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-primary-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-secondary {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-secondary-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-success {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-success-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-info {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-info-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-warning {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-warning-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-danger {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-danger-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-light {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-light-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-dark {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-dark-rgb), var(--wlp-bs-link-underline-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline {
  --wlp-bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--wlp-bs-link-color-rgb), var(--wlp-bs-link-underline-opacity, 1)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-0 {
  --wlp-bs-link-underline-opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-0-hover:hover {
  --wlp-bs-link-underline-opacity: 0;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-10 {
  --wlp-bs-link-underline-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-10-hover:hover {
  --wlp-bs-link-underline-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-25 {
  --wlp-bs-link-underline-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-25-hover:hover {
  --wlp-bs-link-underline-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-50 {
  --wlp-bs-link-underline-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-50-hover:hover {
  --wlp-bs-link-underline-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-75 {
  --wlp-bs-link-underline-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-75-hover:hover {
  --wlp-bs-link-underline-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-100 {
  --wlp-bs-link-underline-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .link-underline-opacity-100-hover:hover {
  --wlp-bs-link-underline-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-primary {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-primary-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-secondary {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-secondary-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-success {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-success-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-info {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-info-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-warning {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-warning-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-danger {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-danger-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-light {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-light-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-dark {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-dark-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-black {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-black-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-white {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-white-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-body {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-body-bg-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-transparent {
  --wlp-bs-bg-opacity: 1;
  background-color: transparent !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-body-secondary {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-secondary-bg-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-body-tertiary {
  --wlp-bs-bg-opacity: 1;
  background-color: rgba(var(--wlp-bs-tertiary-bg-rgb), var(--wlp-bs-bg-opacity)) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-opacity-10 {
  --wlp-bs-bg-opacity: 0.1;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-opacity-25 {
  --wlp-bs-bg-opacity: 0.25;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-opacity-50 {
  --wlp-bs-bg-opacity: 0.5;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-opacity-75 {
  --wlp-bs-bg-opacity: 0.75;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-opacity-100 {
  --wlp-bs-bg-opacity: 1;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-primary-subtle {
  background-color: var(--wlp-bs-primary-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-secondary-subtle {
  background-color: var(--wlp-bs-secondary-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-success-subtle {
  background-color: var(--wlp-bs-success-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-info-subtle {
  background-color: var(--wlp-bs-info-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-warning-subtle {
  background-color: var(--wlp-bs-warning-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-danger-subtle {
  background-color: var(--wlp-bs-danger-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-light-subtle {
  background-color: var(--wlp-bs-light-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-dark-subtle {
  background-color: var(--wlp-bs-dark-bg-subtle) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .bg-gradient {
  background-image: var(--wlp-bs-gradient) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .user-select-all {
  user-select: all !important;
}
:where([data-cnx-widget], [data-cnx-container]) .user-select-auto {
  user-select: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .user-select-none {
  user-select: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-none {
  pointer-events: none !important;
}
:where([data-cnx-widget], [data-cnx-container]) .pe-auto {
  pointer-events: auto !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded {
  border-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-0 {
  border-radius: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-1 {
  border-radius: var(--wlp-bs-border-radius-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-2 {
  border-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-3 {
  border-radius: var(--wlp-bs-border-radius-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-4 {
  border-radius: var(--wlp-bs-border-radius-xl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-5 {
  border-radius: var(--wlp-bs-border-radius-xxl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-circle {
  border-radius: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-pill {
  border-radius: var(--wlp-bs-border-radius-pill) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top {
  border-top-left-radius: var(--wlp-bs-border-radius) !important;
  border-top-right-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-1 {
  border-top-left-radius: var(--wlp-bs-border-radius-sm) !important;
  border-top-right-radius: var(--wlp-bs-border-radius-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-2 {
  border-top-left-radius: var(--wlp-bs-border-radius) !important;
  border-top-right-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-3 {
  border-top-left-radius: var(--wlp-bs-border-radius-lg) !important;
  border-top-right-radius: var(--wlp-bs-border-radius-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-4 {
  border-top-left-radius: var(--wlp-bs-border-radius-xl) !important;
  border-top-right-radius: var(--wlp-bs-border-radius-xl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-5 {
  border-top-left-radius: var(--wlp-bs-border-radius-xxl) !important;
  border-top-right-radius: var(--wlp-bs-border-radius-xxl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-top-pill {
  border-top-left-radius: var(--wlp-bs-border-radius-pill) !important;
  border-top-right-radius: var(--wlp-bs-border-radius-pill) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end {
  border-top-right-radius: var(--wlp-bs-border-radius) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-1 {
  border-top-right-radius: var(--wlp-bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-2 {
  border-top-right-radius: var(--wlp-bs-border-radius) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-3 {
  border-top-right-radius: var(--wlp-bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-4 {
  border-top-right-radius: var(--wlp-bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius-xl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-5 {
  border-top-right-radius: var(--wlp-bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius-xxl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-end-pill {
  border-top-right-radius: var(--wlp-bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--wlp-bs-border-radius-pill) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom {
  border-bottom-right-radius: var(--wlp-bs-border-radius) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-1 {
  border-bottom-right-radius: var(--wlp-bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-2 {
  border-bottom-right-radius: var(--wlp-bs-border-radius) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-3 {
  border-bottom-right-radius: var(--wlp-bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-4 {
  border-bottom-right-radius: var(--wlp-bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius-xl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-5 {
  border-bottom-right-radius: var(--wlp-bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius-xxl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-bottom-pill {
  border-bottom-right-radius: var(--wlp-bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--wlp-bs-border-radius-pill) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start {
  border-bottom-left-radius: var(--wlp-bs-border-radius) !important;
  border-top-left-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-1 {
  border-bottom-left-radius: var(--wlp-bs-border-radius-sm) !important;
  border-top-left-radius: var(--wlp-bs-border-radius-sm) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-2 {
  border-bottom-left-radius: var(--wlp-bs-border-radius) !important;
  border-top-left-radius: var(--wlp-bs-border-radius) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-3 {
  border-bottom-left-radius: var(--wlp-bs-border-radius-lg) !important;
  border-top-left-radius: var(--wlp-bs-border-radius-lg) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-4 {
  border-bottom-left-radius: var(--wlp-bs-border-radius-xl) !important;
  border-top-left-radius: var(--wlp-bs-border-radius-xl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-5 {
  border-bottom-left-radius: var(--wlp-bs-border-radius-xxl) !important;
  border-top-left-radius: var(--wlp-bs-border-radius-xxl) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
:where([data-cnx-widget], [data-cnx-container]) .rounded-start-pill {
  border-bottom-left-radius: var(--wlp-bs-border-radius-pill) !important;
  border-top-left-radius: var(--wlp-bs-border-radius-pill) !important;
}
:where([data-cnx-widget], [data-cnx-container]) .visible {
  visibility: visible !important;
}
:where([data-cnx-widget], [data-cnx-container]) .invisible {
  visibility: hidden !important;
}
:where([data-cnx-widget], [data-cnx-container]) .z-n1 {
  z-index: -1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .z-0 {
  z-index: 0 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .z-1 {
  z-index: 1 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .z-2 {
  z-index: 2 !important;
}
:where([data-cnx-widget], [data-cnx-container]) .z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  :where([data-cnx-widget], [data-cnx-container]) .float-sm-start {
    float: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-sm-end {
    float: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-sm-none {
    float: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-sm-contain {
    object-fit: contain !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-sm-cover {
    object-fit: cover !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-sm-fill {
    object-fit: fill !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-sm-none {
    object-fit: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-sm-none {
    display: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-row {
    flex-direction: row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-column {
    flex-direction: column !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-center {
    justify-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-between {
    justify-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-around {
    justify-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-sm-start {
    align-items: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-sm-end {
    align-items: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-sm-center {
    align-items: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-sm-baseline {
    align-items: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-sm-stretch {
    align-items: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-start {
    align-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-end {
    align-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-center {
    align-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-between {
    align-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-around {
    align-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-sm-stretch {
    align-content: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-auto {
    align-self: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-start {
    align-self: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-end {
    align-self: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-center {
    align-self: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-baseline {
    align-self: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-sm-stretch {
    align-self: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-first {
    order: -1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-0 {
    order: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-1 {
    order: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-2 {
    order: 2 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-3 {
    order: 3 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-4 {
    order: 4 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-5 {
    order: 5 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-sm-last {
    order: 6 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-0 {
    margin: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-1 {
    margin: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-2 {
    margin: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-3 {
    margin: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-4 {
    margin: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-5 {
    margin: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-sm-auto {
    margin: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-0 {
    margin-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-3 {
    margin-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-5 {
    margin-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-sm-auto {
    margin-top: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-0 {
    margin-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-3 {
    margin-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-5 {
    margin-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-sm-auto {
    margin-right: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-sm-auto {
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-0 {
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-3 {
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-5 {
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-sm-auto {
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-0 {
    padding: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-1 {
    padding: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-2 {
    padding: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-3 {
    padding: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-4 {
    padding: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-sm-5 {
    padding: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-0 {
    padding-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-3 {
    padding-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-sm-5 {
    padding-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-0 {
    padding-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-3 {
    padding-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-sm-5 {
    padding-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-0 {
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-3 {
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-sm-5 {
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-0 {
    gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-1 {
    gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-2 {
    gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-3 {
    gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-4 {
    gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-sm-5 {
    gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-sm-start {
    text-align: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-sm-end {
    text-align: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  :where([data-cnx-widget], [data-cnx-container]) .float-md-start {
    float: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-md-end {
    float: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-md-none {
    float: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-md-contain {
    object-fit: contain !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-md-cover {
    object-fit: cover !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-md-fill {
    object-fit: fill !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-md-none {
    object-fit: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-md-none {
    display: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-fill {
    flex: 1 1 auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-row {
    flex-direction: row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-column {
    flex-direction: column !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-start {
    justify-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-end {
    justify-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-center {
    justify-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-between {
    justify-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-around {
    justify-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-md-start {
    align-items: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-md-end {
    align-items: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-md-center {
    align-items: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-md-baseline {
    align-items: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-md-stretch {
    align-items: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-start {
    align-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-end {
    align-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-center {
    align-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-between {
    align-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-around {
    align-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-md-stretch {
    align-content: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-auto {
    align-self: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-start {
    align-self: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-end {
    align-self: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-center {
    align-self: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-baseline {
    align-self: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-md-stretch {
    align-self: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-first {
    order: -1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-0 {
    order: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-1 {
    order: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-2 {
    order: 2 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-3 {
    order: 3 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-4 {
    order: 4 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-5 {
    order: 5 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-md-last {
    order: 6 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-0 {
    margin: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-1 {
    margin: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-2 {
    margin: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-3 {
    margin: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-4 {
    margin: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-5 {
    margin: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-md-auto {
    margin: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-0 {
    margin-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-3 {
    margin-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-5 {
    margin-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-md-auto {
    margin-top: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-0 {
    margin-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-1 {
    margin-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-2 {
    margin-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-3 {
    margin-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-4 {
    margin-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-5 {
    margin-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-md-auto {
    margin-right: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-0 {
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-md-auto {
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-0 {
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-3 {
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-5 {
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-md-auto {
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-0 {
    padding: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-1 {
    padding: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-2 {
    padding: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-3 {
    padding: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-4 {
    padding: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-md-5 {
    padding: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-0 {
    padding-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-3 {
    padding-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-md-5 {
    padding-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-0 {
    padding-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-3 {
    padding-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-md-5 {
    padding-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-0 {
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-0 {
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-3 {
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-md-5 {
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-0 {
    gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-1 {
    gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-2 {
    gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-3 {
    gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-4 {
    gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-md-5 {
    gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-0 {
    row-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-0 {
    column-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-md-start {
    text-align: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-md-end {
    text-align: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  :where([data-cnx-widget], [data-cnx-container]) .float-lg-start {
    float: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-lg-end {
    float: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-lg-none {
    float: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-lg-contain {
    object-fit: contain !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-lg-cover {
    object-fit: cover !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-lg-fill {
    object-fit: fill !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-lg-none {
    object-fit: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-lg-none {
    display: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-row {
    flex-direction: row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-column {
    flex-direction: column !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-center {
    justify-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-between {
    justify-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-around {
    justify-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-lg-start {
    align-items: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-lg-end {
    align-items: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-lg-center {
    align-items: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-lg-baseline {
    align-items: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-lg-stretch {
    align-items: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-start {
    align-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-end {
    align-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-center {
    align-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-between {
    align-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-around {
    align-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-lg-stretch {
    align-content: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-auto {
    align-self: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-start {
    align-self: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-end {
    align-self: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-center {
    align-self: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-baseline {
    align-self: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-lg-stretch {
    align-self: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-first {
    order: -1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-0 {
    order: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-1 {
    order: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-2 {
    order: 2 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-3 {
    order: 3 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-4 {
    order: 4 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-5 {
    order: 5 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-lg-last {
    order: 6 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-0 {
    margin: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-1 {
    margin: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-2 {
    margin: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-3 {
    margin: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-4 {
    margin: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-5 {
    margin: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-lg-auto {
    margin: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-0 {
    margin-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-3 {
    margin-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-5 {
    margin-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-lg-auto {
    margin-top: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-0 {
    margin-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-3 {
    margin-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-5 {
    margin-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-lg-auto {
    margin-right: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-lg-auto {
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-0 {
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-3 {
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-5 {
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-lg-auto {
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-0 {
    padding: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-1 {
    padding: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-2 {
    padding: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-3 {
    padding: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-4 {
    padding: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-lg-5 {
    padding: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-0 {
    padding-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-3 {
    padding-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-lg-5 {
    padding-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-0 {
    padding-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-3 {
    padding-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-lg-5 {
    padding-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-0 {
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-3 {
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-lg-5 {
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-0 {
    gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-1 {
    gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-2 {
    gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-3 {
    gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-4 {
    gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-lg-5 {
    gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-lg-start {
    text-align: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-lg-end {
    text-align: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  :where([data-cnx-widget], [data-cnx-container]) .float-xl-start {
    float: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-xl-end {
    float: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-xl-none {
    float: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xl-contain {
    object-fit: contain !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xl-cover {
    object-fit: cover !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xl-fill {
    object-fit: fill !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xl-none {
    object-fit: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xl-none {
    display: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-row {
    flex-direction: row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-column {
    flex-direction: column !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-center {
    justify-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-between {
    justify-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-around {
    justify-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xl-start {
    align-items: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xl-end {
    align-items: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xl-center {
    align-items: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xl-baseline {
    align-items: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xl-stretch {
    align-items: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-start {
    align-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-end {
    align-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-center {
    align-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-between {
    align-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-around {
    align-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xl-stretch {
    align-content: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-auto {
    align-self: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-start {
    align-self: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-end {
    align-self: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-center {
    align-self: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-baseline {
    align-self: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xl-stretch {
    align-self: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-first {
    order: -1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-0 {
    order: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-1 {
    order: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-2 {
    order: 2 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-3 {
    order: 3 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-4 {
    order: 4 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-5 {
    order: 5 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xl-last {
    order: 6 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-0 {
    margin: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-1 {
    margin: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-2 {
    margin: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-3 {
    margin: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-4 {
    margin: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-5 {
    margin: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xl-auto {
    margin: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-0 {
    margin-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-3 {
    margin-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-5 {
    margin-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xl-auto {
    margin-top: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-0 {
    margin-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-3 {
    margin-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-5 {
    margin-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xl-auto {
    margin-right: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xl-auto {
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-0 {
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-3 {
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-5 {
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xl-auto {
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-0 {
    padding: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-1 {
    padding: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-2 {
    padding: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-3 {
    padding: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-4 {
    padding: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xl-5 {
    padding: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-0 {
    padding-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-3 {
    padding-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xl-5 {
    padding-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-0 {
    padding-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-3 {
    padding-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xl-5 {
    padding-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-0 {
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-3 {
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xl-5 {
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-0 {
    gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-1 {
    gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-2 {
    gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-3 {
    gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-4 {
    gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xl-5 {
    gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xl-start {
    text-align: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xl-end {
    text-align: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  :where([data-cnx-widget], [data-cnx-container]) .float-xxl-start {
    float: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-xxl-end {
    float: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .float-xxl-none {
    float: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .object-fit-xxl-none {
    object-fit: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-xxl-none {
    display: none !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-row {
    flex-direction: row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-column {
    flex-direction: column !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-center {
    justify-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xxl-start {
    align-items: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xxl-end {
    align-items: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xxl-center {
    align-items: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-start {
    align-content: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-end {
    align-content: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-center {
    align-content: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-between {
    align-content: space-between !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-around {
    align-content: space-around !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-auto {
    align-self: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-start {
    align-self: flex-start !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-end {
    align-self: flex-end !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-center {
    align-self: center !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-first {
    order: -1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-0 {
    order: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-1 {
    order: 1 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-2 {
    order: 2 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-3 {
    order: 3 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-4 {
    order: 4 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-5 {
    order: 5 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .order-xxl-last {
    order: 6 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-0 {
    margin: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-1 {
    margin: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-2 {
    margin: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-3 {
    margin: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-4 {
    margin: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-5 {
    margin: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .m-xxl-auto {
    margin: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-0 {
    margin-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mt-xxl-auto {
    margin-top: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-0 {
    margin-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-3 {
    margin-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-5 {
    margin-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .me-xxl-auto {
    margin-right: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-0 {
    margin-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ms-xxl-auto {
    margin-left: auto !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-0 {
    padding: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-1 {
    padding: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-2 {
    padding: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-3 {
    padding: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-4 {
    padding: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .p-xxl-5 {
    padding: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-0 {
    padding-top: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-0 {
    padding-right: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-0 {
    padding-left: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-0 {
    gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-3 {
    gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .gap-xxl-5 {
    gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xxl-start {
    text-align: left !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xxl-end {
    text-align: right !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .text-xxl-center {
    text-align: center !important;
  }
}
@media print {
  :where([data-cnx-widget], [data-cnx-container]) .d-print-inline {
    display: inline !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-inline-block {
    display: inline-block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-block {
    display: block !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-grid {
    display: grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-inline-grid {
    display: inline-grid !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-table {
    display: table !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-table-row {
    display: table-row !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-table-cell {
    display: table-cell !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-flex {
    display: flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-inline-flex {
    display: inline-flex !important;
  }
  :where([data-cnx-widget], [data-cnx-container]) .d-print-none {
    display: none !important;
  }
}

html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

@keyframes highlight {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.maintenance {
  background: #eff1ee;
}
.maintenance .animate-root,
.maintenance .view-root {
  background: #eff1ee;
}
.maintenance h1, .maintenance :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .maintenance .h1 {
  font-size: 30px;
}
.maintenance article {
  font-size: 15px;
}
.maintenance .view-container {
  margin: 25px auto 0;
  padding: 0;
  max-width: 760px;
  text-align: center;
}
.maintenance .view-container::before {
  box-shadow: none;
}
.maintenance .content {
  background-color: #fff;
}
.maintenance .content img {
  max-width: 300px;
  max-height: 50px;
}
.maintenance .content-alternative {
  background-color: white;
}

.search-form .date-input input,
.search-form .date-input select,
.search-form .time-input input,
.search-form .time-input select, input[type=color], input[type=date], input[type=datetime], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], input:not([type]), textarea,
select,
.fake-input {
  outline-color: rgba(205, 43, 88, 0.974);
  border: 1px solid #bdbdbd;
  border-radius: var(--wlp-bs-border-radius);
  background-color: #fff;
  padding: 0 0 0 0.5em;
  height: 42px;
  line-height: 42px;
  font-size: 1em;
  appearance: none;
}

select:invalid {
  background: #fff;
  color: #9e9e9e;
}
select:invalid option {
  color: rgba(0, 0, 0, 0.87);
}

.search-form .location-wrapper,
.search-form .timeframe-wrapper,
.search-form .grouped-inputs-wrapper, .search-form .dropoff-location-panel {
  position: relative;
}

.validation-message {
  position: relative;
  z-index: 98;
  margin: 8px 0 12px;
  border-radius: 4px;
  background: #dd1c1a;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 1em;
  font-weight: normal;
}
.validation-tooltip .validation-message {
  position: absolute;
  bottom: 100%;
}

.validation-message::before {
  position: absolute;
  bottom: -12px;
  left: 47%;
  z-index: 99;
  border: solid;
  border-width: 12px 12px 0;
  border-color: #dd1c1a transparent;
  content: "";
}

.validation-border {
  border: 1px solid #dd1c1a !important;
}

.validation-highlight {
  color: #dd1c1a !important;
}

.thrifty-logo, .dollar-logo, .sixt-logo, .national-logo, .hertz-logo, .europcar-logo, .enterprise-logo, .budget-logo, .avis-logo, .alamo-logo {
  background-position: center center;
  background-repeat: no-repeat;
}

.alamo-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/alamo.svg);
  background-size: contain;
}
.no-svg .alamo-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/alamo.png);
}

.avis-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/avis.svg);
  background-size: contain;
}
.no-svg .avis-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/avis.png);
}

.budget-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/budget.svg);
  background-size: contain;
}
.no-svg .budget-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/budget.png);
}

.enterprise-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/enterprise.svg);
  background-size: contain;
}
.no-svg .enterprise-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/enterprise.png);
}

.europcar-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/europcar.svg);
  background-size: contain;
}
.no-svg .europcar-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/europcar.png);
}

.hertz-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/hertz.svg);
  background-size: contain;
}
.no-svg .hertz-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/hertz.png);
}

.national-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/national.svg);
  background-size: contain;
}
.no-svg .national-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/national.png);
}

.sixt-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/sixt.svg);
  background-size: contain;
}
.no-svg .sixt-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/sixt.png);
}

.dollar-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/dollar.svg);
  background-size: contain;
}
.no-svg .dollar-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/dollar.png);
}

.thrifty-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/thrifty.svg);
  background-size: contain;
}
.no-svg .thrifty-logo {
  background-image: url(https://static.carhire-solutions.com/images/wlp/suppliers/thrifty.png);
}

@font-face {
  font-family: default-icons;
  font-weight: normal;
  font-style: normal;
  src: url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.eot?93113442");
  src: url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.eot?93113442#iefix") format("embedded-opentype"), url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.woff2?93113442") format("woff2"), url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.woff?93113442") format("woff"), url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.ttf?93113442") format("truetype"), url("https://static.carhire-solutions.com/fonts/wlp-icons/default-icons.svg?93113442#default-icons") format("svg");
}
[data-icon]::before {
  text-transform: none;
  line-height: 1em;
  font-family: default-icons;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-style: normal;
  speak: none;
  /* additional CSS declaration generated by fontello but removed
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  */
  line-height: 1;
  content: attr(data-icon);
}

[data-cnx-container] [class^=icon-]::before,
[data-cnx-container] [class*=" icon-"]::before {
  text-transform: none;
  line-height: 1em;
  font-family: default-icons;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-style: normal;
  speak: none;
  /* additional CSS declaration generated by fontello but removed
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  */
  position: relative;
  top: 1px;
}
[data-cnx-container] .icon-credit-card::before,
[data-cnx-container] .icon-car::before,
[data-cnx-container] .icon-truck::before,
[data-cnx-container] .icon-lock::before,
[data-cnx-container] .icon-arrows-cw::before,
[data-cnx-container] .icon-user-circle::before,
[data-cnx-container] .icon-lightning::before {
  top: 0;
}

/* stylelint-disable */
[data-cnx-container] {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
[data-cnx-container] .icon-credit-card::before {
  content: "\e800";
}
[data-cnx-container] .icon-aircon::before {
  content: "\e801";
}
[data-cnx-container] .icon-aircon-solid::before {
  content: "\e802";
}
[data-cnx-container] .icon-airplane::before {
  content: "\e803";
}
[data-cnx-container] .icon-airplane-solid::before {
  content: "\e804";
}
[data-cnx-container] .icon-bags::before {
  content: "\e805";
}
[data-cnx-container] .icon-bags-solid::before {
  content: "\e806";
}
[data-cnx-container] .icon-bulletpoint::before {
  content: "\e807";
}
[data-cnx-container] .icon-bulletpoint-small::before {
  content: "\e808";
}
[data-cnx-container] .icon-calendar::before {
  content: "\e809";
}
[data-cnx-container] .icon-check::before {
  content: "\e80a";
}
[data-cnx-container] .icon-check-solid::before {
  content: "\e80b";
}
[data-cnx-container] .icon-chevron-down::before {
  content: "\e80c";
}
[data-cnx-container] .icon-chevron-left::before {
  content: "\e80d";
}
[data-cnx-container] .icon-chevron-right::before {
  content: "\e80e";
}
[data-cnx-container] .icon-chevron-up::before {
  content: "\e80f";
}
[data-cnx-container] .icon-clock::before {
  content: "\e810";
}
[data-cnx-container] .icon-clock-solid::before {
  content: "\e811";
}
[data-cnx-container] .icon-cross::before {
  content: "\e812";
}
[data-cnx-container] .icon-doors::before {
  content: "\e813";
}
[data-cnx-container] .icon-doors-solid::before {
  content: "\e814";
}
[data-cnx-container] .icon-downtown-solid::before {
  content: "\e815";
}
[data-cnx-container] .icon-transmission-solid::before {
  content: "\e816";
}
[data-cnx-container] .icon-transmission::before {
  content: "\e817";
}
[data-cnx-container] .icon-star-solid::before {
  content: "\e818";
}
[data-cnx-container] .icon-star::before {
  content: "\e819";
}
[data-cnx-container] .icon-railway-solid::before {
  content: "\e81a";
}
[data-cnx-container] .icon-railway::before {
  content: "\e81b";
}
[data-cnx-container] .icon-phone::before {
  content: "\e81c";
}
[data-cnx-container] .icon-passengers-solid::before {
  content: "\e81d";
}
[data-cnx-container] .icon-passengers::before {
  content: "\e81e";
}
[data-cnx-container] .icon-minus-boxed::before {
  content: "\e81f";
}
[data-cnx-container] .icon-minus::before {
  content: "\e820";
}
[data-cnx-container] .icon-location-solid::before {
  content: "\e821";
}
[data-cnx-container] .icon-location::before {
  content: "\e822";
}
[data-cnx-container] .icon-info-solid::before {
  content: "\e823";
}
[data-cnx-container] .icon-info::before {
  content: "\e824";
}
[data-cnx-container] .icon-fuel-solid::before {
  content: "\e825";
}
[data-cnx-container] .icon-fuel::before {
  content: "\e826";
}
[data-cnx-container] .icon-email-solid::before {
  content: "\e827";
}
[data-cnx-container] .icon-email::before {
  content: "\e828";
}
[data-cnx-container] .icon-downtown::before {
  content: "\e829";
}
[data-cnx-container] .icon-menu::before {
  content: "\e82a";
}
[data-cnx-container] .icon-square::before {
  content: "\e82b";
}
[data-cnx-container] .icon-check-square::before {
  content: "\e82c";
}
[data-cnx-container] .icon-lock::before {
  content: "\e82d";
}
[data-cnx-container] .icon-warning::before {
  content: "\e82e";
}
[data-cnx-container] .icon-thumbs-up::before {
  content: "\e82f";
}
[data-cnx-container] .icon-check-circle::before {
  content: "\e830";
}
[data-cnx-container] .icon-logout::before {
  content: "\e831";
}
[data-cnx-container] .icon-status-confirmed::before {
  content: "\e832";
}
[data-cnx-container] .icon-status-cancelled::before {
  content: "\e833";
}
[data-cnx-container] .icon-status-unknown::before {
  content: "\e834";
}
[data-cnx-container] .icon-status-pending::before {
  content: "\e835";
}
[data-cnx-container] .icon-up-dir::before {
  content: "\e836";
}
[data-cnx-container] .icon-arrows-cw::before {
  content: "\e837";
}
[data-cnx-container] .icon-ccw::before {
  content: "\e838";
}
[data-cnx-container] .icon-lightning::before {
  content: "\e839";
}
[data-cnx-container] .icon-dimensions-3d::before {
  content: "\e83a";
}
[data-cnx-container] .icon-weight::before {
  content: "\e83b";
}
[data-cnx-container] .icon-truck::before {
  content: "\e83c";
}
[data-cnx-container] .icon-driving-license::before {
  content: "\e841";
}
[data-cnx-container] .icon-car::before {
  content: "\f1b9";
}
[data-cnx-container] .icon-bus::before {
  content: "\f207";
}
[data-cnx-container] .icon-user-circle::before {
  content: "\f2be";
}

/* stylelint-enable */
/*
 * Default size
*/
.cnx-flag {
  background-image: url("https://static.carhire-solutions.com/images/wlp/flags/flags-16.png");
  width: 16px;
  height: 11px;
}
.cnx-flag-me {
  background-position: 0 0;
  height: 12px;
}
.cnx-flag-ch {
  background-position: -208px -187px;
  width: 11px;
}
.cnx-flag-ky {
  background-position: -16px 0;
}
.cnx-flag-af {
  background-position: 0 -12px;
}
.cnx-flag-ag {
  background-position: -16px -12px;
}
.cnx-flag-ai {
  background-position: -32px 0;
}
.cnx-flag-al {
  background-position: -32px -11px;
}
.cnx-flag-am {
  background-position: 0 -23px;
}
.cnx-flag-an {
  background-position: -16px -23px;
}
.cnx-flag-ao {
  background-position: -32px -23px;
}
.cnx-flag-aq {
  background-position: 0 -34px;
}
.cnx-flag-ar {
  background-position: -16px -34px;
}
.cnx-flag-as {
  background-position: -32px -34px;
}
.cnx-flag-at {
  background-position: -48px 0;
}
.cnx-flag-au {
  background-position: -48px -11px;
}
.cnx-flag-aw {
  background-position: -48px -22px;
}
.cnx-flag-ax {
  background-position: -48px -33px;
}
.cnx-flag-az {
  background-position: 0 -45px;
}
.cnx-flag-ba {
  background-position: -16px -45px;
}
.cnx-flag-bb {
  background-position: -32px -45px;
}
.cnx-flag-bd {
  background-position: -48px -45px;
}
.cnx-flag-be {
  background-position: -64px 0;
}
.cnx-flag-bf {
  background-position: -64px -11px;
}
.cnx-flag-bg {
  background-position: -64px -22px;
}
.cnx-flag-bh {
  background-position: -64px -33px;
}
.cnx-flag-bi {
  background-position: -64px -44px;
}
.cnx-flag-bj {
  background-position: 0 -56px;
}
.cnx-flag-bl {
  background-position: -16px -56px;
}
.cnx-flag-bm {
  background-position: -32px -56px;
}
.cnx-flag-bn {
  background-position: -48px -56px;
}
.cnx-flag-bo {
  background-position: -64px -56px;
}
.cnx-flag-bq {
  background-position: 0 -67px;
}
.cnx-flag-br {
  background-position: -16px -67px;
}
.cnx-flag-bs {
  background-position: -32px -67px;
}
.cnx-flag-bt {
  background-position: -48px -67px;
}
.cnx-flag-bv {
  background-position: -64px -67px;
}
.cnx-flag-bw {
  background-position: -80px 0;
}
.cnx-flag-by {
  background-position: -80px -11px;
}
.cnx-flag-bz {
  background-position: -80px -22px;
}
.cnx-flag-ca {
  background-position: -80px -33px;
}
.cnx-flag-cc {
  background-position: -80px -44px;
}
.cnx-flag-cd {
  background-position: -80px -55px;
}
.cnx-flag-cf {
  background-position: -80px -66px;
}
.cnx-flag-cg {
  background-position: 0 -78px;
}
.cnx-flag-zm {
  background-position: -16px -78px;
}
.cnx-flag-ci {
  background-position: -32px -78px;
}
.cnx-flag-ck {
  background-position: -48px -78px;
}
.cnx-flag-cl {
  background-position: -64px -78px;
}
.cnx-flag-cm {
  background-position: -80px -78px;
}
.cnx-flag-cn {
  background-position: -96px 0;
}
.cnx-flag-co {
  background-position: -96px -11px;
}
.cnx-flag-cr {
  background-position: -96px -22px;
}
.cnx-flag-cs {
  background-position: -96px -33px;
}
.cnx-flag-cu {
  background-position: -96px -44px;
}
.cnx-flag-cv {
  background-position: -96px -55px;
}
.cnx-flag-cw {
  background-position: -96px -66px;
}
.cnx-flag-cx {
  background-position: -96px -77px;
}
.cnx-flag-cy {
  background-position: 0 -89px;
}
.cnx-flag-cz {
  background-position: -16px -89px;
}
.cnx-flag-de {
  background-position: -32px -89px;
}
.cnx-flag-dj {
  background-position: -48px -89px;
}
.cnx-flag-dk {
  background-position: -64px -89px;
}
.cnx-flag-dm {
  background-position: -80px -89px;
}
.cnx-flag-do {
  background-position: -96px -89px;
}
.cnx-flag-dz {
  background-position: 0 -100px;
}
.cnx-flag-ec {
  background-position: -16px -100px;
}
.cnx-flag-ee {
  background-position: -32px -100px;
}
.cnx-flag-eg {
  background-position: -48px -100px;
}
.cnx-flag-eh {
  background-position: -64px -100px;
}
.cnx-flag-er {
  background-position: -80px -100px;
}
.cnx-flag-es {
  background-position: -96px -100px;
}
.cnx-flag-et {
  background-position: -112px 0;
}
.cnx-flag-fi {
  background-position: -112px -11px;
}
.cnx-flag-fj {
  background-position: -112px -22px;
}
.cnx-flag-fk {
  background-position: -112px -33px;
}
.cnx-flag-fm {
  background-position: -112px -44px;
}
.cnx-flag-fo {
  background-position: -112px -55px;
}
.cnx-flag-fr {
  background-position: -112px -66px;
}
.cnx-flag-ga {
  background-position: -112px -77px;
}
.cnx-flag-gb {
  background-position: -112px -88px;
}
.cnx-flag-gd {
  background-position: -112px -99px;
}
.cnx-flag-ge {
  background-position: 0 -111px;
}
.cnx-flag-gf {
  background-position: -16px -111px;
}
.cnx-flag-gg {
  background-position: -32px -111px;
}
.cnx-flag-gh {
  background-position: -48px -111px;
}
.cnx-flag-gi {
  background-position: -64px -111px;
}
.cnx-flag-gl {
  background-position: -80px -111px;
}
.cnx-flag-gm {
  background-position: -96px -111px;
}
.cnx-flag-gn {
  background-position: -112px -111px;
}
.cnx-flag-gp {
  background-position: -128px 0;
}
.cnx-flag-gq {
  background-position: -128px -11px;
}
.cnx-flag-gr {
  background-position: -128px -22px;
}
.cnx-flag-gs {
  background-position: -128px -33px;
}
.cnx-flag-gt {
  background-position: -128px -44px;
}
.cnx-flag-gu {
  background-position: -128px -55px;
}
.cnx-flag-gw {
  background-position: -128px -66px;
}
.cnx-flag-gy {
  background-position: -128px -77px;
}
.cnx-flag-hk {
  background-position: -128px -88px;
}
.cnx-flag-hm {
  background-position: -128px -99px;
}
.cnx-flag-hn {
  background-position: -128px -110px;
}
.cnx-flag-hr {
  background-position: 0 -122px;
}
.cnx-flag-ht {
  background-position: -16px -122px;
}
.cnx-flag-hu {
  background-position: -32px -122px;
}
.cnx-flag-id {
  background-position: -48px -122px;
}
.cnx-flag-ie {
  background-position: -64px -122px;
}
.cnx-flag-il {
  background-position: -80px -122px;
}
.cnx-flag-im {
  background-position: -96px -122px;
}
.cnx-flag-in {
  background-position: -112px -122px;
}
.cnx-flag-io {
  background-position: -128px -122px;
}
.cnx-flag-iq {
  background-position: 0 -133px;
}
.cnx-flag-ir {
  background-position: -16px -133px;
}
.cnx-flag-is {
  background-position: -32px -133px;
}
.cnx-flag-it {
  background-position: -48px -133px;
}
.cnx-flag-je {
  background-position: -64px -133px;
}
.cnx-flag-jm {
  background-position: -80px -133px;
}
.cnx-flag-jo {
  background-position: -96px -133px;
}
.cnx-flag-jp {
  background-position: -112px -133px;
}
.cnx-flag-ke {
  background-position: -128px -133px;
}
.cnx-flag-kg {
  background-position: -144px 0;
}
.cnx-flag-kh {
  background-position: -144px -11px;
}
.cnx-flag-ki {
  background-position: -144px -22px;
}
.cnx-flag-km {
  background-position: -144px -33px;
}
.cnx-flag-kn {
  background-position: -144px -44px;
}
.cnx-flag-kp {
  background-position: -144px -55px;
}
.cnx-flag-kr {
  background-position: -144px -66px;
}
.cnx-flag-kv {
  background-position: -144px -77px;
}
.cnx-flag-kw {
  background-position: -144px -88px;
}
.cnx-flag-ae {
  background-position: -144px -99px;
}
.cnx-flag-kz {
  background-position: -144px -110px;
}
.cnx-flag-la {
  background-position: -144px -121px;
}
.cnx-flag-lb {
  background-position: -144px -132px;
}
.cnx-flag-lc {
  background-position: 0 -144px;
}
.cnx-flag-li {
  background-position: -16px -144px;
}
.cnx-flag-lk {
  background-position: -32px -144px;
}
.cnx-flag-lr {
  background-position: -48px -144px;
}
.cnx-flag-ls {
  background-position: -64px -144px;
}
.cnx-flag-lt {
  background-position: -80px -144px;
}
.cnx-flag-lu {
  background-position: -96px -144px;
}
.cnx-flag-lv {
  background-position: -112px -144px;
}
.cnx-flag-ly {
  background-position: -128px -144px;
}
.cnx-flag-ma {
  background-position: -144px -144px;
}
.cnx-flag-mc {
  background-position: -160px 0;
}
.cnx-flag-md {
  background-position: -160px -11px;
}
.cnx-flag-ad {
  background-position: -160px -22px;
}
.cnx-flag-mf {
  background-position: -160px -33px;
}
.cnx-flag-mg {
  background-position: -160px -44px;
}
.cnx-flag-mh {
  background-position: -160px -55px;
}
.cnx-flag-mk {
  background-position: -160px -66px;
}
.cnx-flag-ml {
  background-position: -160px -77px;
}
.cnx-flag-mm {
  background-position: -160px -88px;
}
.cnx-flag-mn {
  background-position: -160px -99px;
}
.cnx-flag-mo {
  background-position: -160px -110px;
}
.cnx-flag-mp {
  background-position: -160px -121px;
}
.cnx-flag-mq {
  background-position: -160px -132px;
}
.cnx-flag-mr {
  background-position: -160px -143px;
}
.cnx-flag-ms {
  background-position: 0 -155px;
}
.cnx-flag-mt {
  background-position: -16px -155px;
}
.cnx-flag-mu {
  background-position: -32px -155px;
}
.cnx-flag-mv {
  background-position: -48px -155px;
}
.cnx-flag-mw {
  background-position: -64px -155px;
}
.cnx-flag-mx {
  background-position: -80px -155px;
}
.cnx-flag-my {
  background-position: -96px -155px;
}
.cnx-flag-mz {
  background-position: -112px -155px;
}
.cnx-flag-na {
  background-position: -128px -155px;
}
.cnx-flag-nc {
  background-position: -144px -155px;
}
.cnx-flag-ne {
  background-position: -160px -155px;
}
.cnx-flag-nf {
  background-position: -176px 0;
}
.cnx-flag-ng {
  background-position: -176px -11px;
}
.cnx-flag-ni {
  background-position: -176px -22px;
}
.cnx-flag-nl {
  background-position: -176px -33px;
}
.cnx-flag-no {
  background-position: -176px -44px;
}
.cnx-flag-nr {
  background-position: -176px -55px;
}
.cnx-flag-nu {
  background-position: -176px -66px;
}
.cnx-flag-nz {
  background-position: -176px -77px;
}
.cnx-flag-om {
  background-position: -176px -88px;
}
.cnx-flag-pa {
  background-position: -176px -99px;
}
.cnx-flag-pe {
  background-position: -176px -110px;
}
.cnx-flag-pf {
  background-position: -176px -121px;
}
.cnx-flag-pg {
  background-position: -176px -132px;
}
.cnx-flag-ph {
  background-position: -176px -143px;
}
.cnx-flag-pk {
  background-position: -176px -154px;
}
.cnx-flag-pl {
  background-position: 0 -166px;
}
.cnx-flag-pm {
  background-position: -16px -166px;
}
.cnx-flag-pn {
  background-position: -32px -166px;
}
.cnx-flag-pr {
  background-position: -48px -166px;
}
.cnx-flag-ps {
  background-position: -64px -166px;
}
.cnx-flag-pt {
  background-position: -80px -166px;
}
.cnx-flag-pw {
  background-position: -96px -166px;
}
.cnx-flag-py {
  background-position: -112px -166px;
}
.cnx-flag-qa {
  background-position: -128px -166px;
}
.cnx-flag-re {
  background-position: -144px -166px;
}
.cnx-flag-ro {
  background-position: -160px -166px;
}
.cnx-flag-rs {
  background-position: -176px -166px;
}
.cnx-flag-ru {
  background-position: 0 -177px;
}
.cnx-flag-rw {
  background-position: -16px -177px;
}
.cnx-flag-sa {
  background-position: -32px -177px;
}
.cnx-flag-sb {
  background-position: -48px -177px;
}
.cnx-flag-sc {
  background-position: -64px -177px;
}
.cnx-flag-sd {
  background-position: -80px -177px;
}
.cnx-flag-se {
  background-position: -96px -177px;
}
.cnx-flag-sg {
  background-position: -112px -177px;
}
.cnx-flag-sh {
  background-position: -128px -177px;
}
.cnx-flag-si {
  background-position: -144px -177px;
}
.cnx-flag-sj {
  background-position: -160px -177px;
}
.cnx-flag-sk {
  background-position: -176px -177px;
}
.cnx-flag-sl {
  background-position: -192px 0;
}
.cnx-flag-sm {
  background-position: -192px -11px;
}
.cnx-flag-sn {
  background-position: -192px -22px;
}
.cnx-flag-so {
  background-position: -192px -33px;
}
.cnx-flag-sr {
  background-position: -192px -44px;
}
.cnx-flag-ss {
  background-position: -192px -55px;
}
.cnx-flag-st {
  background-position: -192px -66px;
}
.cnx-flag-su {
  background-position: -192px -77px;
}
.cnx-flag-sv {
  background-position: -192px -88px;
}
.cnx-flag-sx {
  background-position: -192px -99px;
}
.cnx-flag-sy {
  background-position: -192px -110px;
}
.cnx-flag-sz {
  background-position: -192px -121px;
}
.cnx-flag-tc {
  background-position: -192px -132px;
}
.cnx-flag-td {
  background-position: -192px -143px;
}
.cnx-flag-tf {
  background-position: -192px -154px;
}
.cnx-flag-tg {
  background-position: -192px -165px;
}
.cnx-flag-th {
  background-position: -192px -176px;
}
.cnx-flag-tj {
  background-position: 0 -188px;
}
.cnx-flag-tk {
  background-position: -16px -188px;
}
.cnx-flag-tl {
  background-position: -32px -188px;
}
.cnx-flag-tm {
  background-position: -48px -188px;
}
.cnx-flag-tn {
  background-position: -64px -188px;
}
.cnx-flag-to {
  background-position: -80px -188px;
}
.cnx-flag-tr {
  background-position: -96px -188px;
}
.cnx-flag-tt {
  background-position: -112px -188px;
}
.cnx-flag-tv {
  background-position: -128px -188px;
}
.cnx-flag-tw {
  background-position: -144px -188px;
}
.cnx-flag-tz {
  background-position: -160px -188px;
}
.cnx-flag-ua {
  background-position: -176px -188px;
}
.cnx-flag-ug {
  background-position: -192px -188px;
}
.cnx-flag-um {
  background-position: -208px 0;
}
.cnx-flag-us {
  background-position: -208px -11px;
}
.cnx-flag-uy {
  background-position: -208px -22px;
}
.cnx-flag-uz {
  background-position: -208px -33px;
}
.cnx-flag-va {
  background-position: -208px -44px;
}
.cnx-flag-vc {
  background-position: -208px -55px;
}
.cnx-flag-ve {
  background-position: -208px -66px;
}
.cnx-flag-vg {
  background-position: -208px -77px;
}
.cnx-flag-vi {
  background-position: -208px -88px;
}
.cnx-flag-vn {
  background-position: -208px -99px;
}
.cnx-flag-vu {
  background-position: -208px -110px;
}
.cnx-flag-wf {
  background-position: -208px -121px;
}
.cnx-flag-ws {
  background-position: -208px -132px;
}
.cnx-flag-ye {
  background-position: -208px -143px;
}
.cnx-flag-yt {
  background-position: -208px -154px;
}
.cnx-flag-za {
  background-position: -208px -165px;
}
.cnx-flag-zw {
  background-position: -208px -176px;
}

/*
 * Medium size
*/
.cnx-flag-medium {
  background-image: url("https://static.carhire-solutions.com/images/wlp/flags/flags-32.png");
  width: 32px;
  height: 32px;
}
.cnx-flag-medium.cnx-flag-ky {
  background-position: 0 0;
}
.cnx-flag-medium.cnx-flag-ad {
  background-position: -32px 0;
}
.cnx-flag-medium.cnx-flag-af {
  background-position: 0 -32px;
}
.cnx-flag-medium.cnx-flag-ag {
  background-position: -32px -32px;
}
.cnx-flag-medium.cnx-flag-ai {
  background-position: -64px 0;
}
.cnx-flag-medium.cnx-flag-al {
  background-position: -64px -32px;
}
.cnx-flag-medium.cnx-flag-am {
  background-position: 0 -64px;
}
.cnx-flag-medium.cnx-flag-an {
  background-position: -32px -64px;
}
.cnx-flag-medium.cnx-flag-ao {
  background-position: -64px -64px;
}
.cnx-flag-medium.cnx-flag-aq {
  background-position: -96px 0;
}
.cnx-flag-medium.cnx-flag-ar {
  background-position: -96px -32px;
}
.cnx-flag-medium.cnx-flag-as {
  background-position: -96px -64px;
}
.cnx-flag-medium.cnx-flag-at {
  background-position: 0 -96px;
}
.cnx-flag-medium.cnx-flag-au {
  background-position: -32px -96px;
}
.cnx-flag-medium.cnx-flag-aw {
  background-position: -64px -96px;
}
.cnx-flag-medium.cnx-flag-ax {
  background-position: -96px -96px;
}
.cnx-flag-medium.cnx-flag-az {
  background-position: -128px 0;
}
.cnx-flag-medium.cnx-flag-ba {
  background-position: -128px -32px;
}
.cnx-flag-medium.cnx-flag-bb {
  background-position: -128px -64px;
}
.cnx-flag-medium.cnx-flag-bd {
  background-position: -128px -96px;
}
.cnx-flag-medium.cnx-flag-be {
  background-position: 0 -128px;
}
.cnx-flag-medium.cnx-flag-bf {
  background-position: -32px -128px;
}
.cnx-flag-medium.cnx-flag-bg {
  background-position: -64px -128px;
}
.cnx-flag-medium.cnx-flag-bh {
  background-position: -96px -128px;
}
.cnx-flag-medium.cnx-flag-bi {
  background-position: -128px -128px;
}
.cnx-flag-medium.cnx-flag-bj {
  background-position: -160px 0;
}
.cnx-flag-medium.cnx-flag-bl {
  background-position: -160px -32px;
}
.cnx-flag-medium.cnx-flag-bm {
  background-position: -160px -64px;
}
.cnx-flag-medium.cnx-flag-bn {
  background-position: -160px -96px;
}
.cnx-flag-medium.cnx-flag-bo {
  background-position: -160px -128px;
}
.cnx-flag-medium.cnx-flag-br {
  background-position: 0 -160px;
}
.cnx-flag-medium.cnx-flag-bs {
  background-position: -32px -160px;
}
.cnx-flag-medium.cnx-flag-bt {
  background-position: -64px -160px;
}
.cnx-flag-medium.cnx-flag-bw {
  background-position: -96px -160px;
}
.cnx-flag-medium.cnx-flag-by {
  background-position: -128px -160px;
}
.cnx-flag-medium.cnx-flag-bz {
  background-position: -160px -160px;
}
.cnx-flag-medium.cnx-flag-ca {
  background-position: -192px 0;
}
.cnx-flag-medium.cnx-flag-cc {
  background-position: -192px -32px;
}
.cnx-flag-medium.cnx-flag-cd {
  background-position: -192px -64px;
}
.cnx-flag-medium.cnx-flag-cf {
  background-position: -192px -96px;
}
.cnx-flag-medium.cnx-flag-cg {
  background-position: -192px -128px;
}
.cnx-flag-medium.cnx-flag-ch {
  background-position: -192px -160px;
}
.cnx-flag-medium.cnx-flag-ci {
  background-position: 0 -192px;
}
.cnx-flag-medium.cnx-flag-ck {
  background-position: -32px -192px;
}
.cnx-flag-medium.cnx-flag-cl {
  background-position: -64px -192px;
}
.cnx-flag-medium.cnx-flag-cm {
  background-position: -96px -192px;
}
.cnx-flag-medium.cnx-flag-cn {
  background-position: -128px -192px;
}
.cnx-flag-medium.cnx-flag-co {
  background-position: -160px -192px;
}
.cnx-flag-medium.cnx-flag-cr {
  background-position: -192px -192px;
}
.cnx-flag-medium.cnx-flag-cu {
  background-position: -224px 0;
}
.cnx-flag-medium.cnx-flag-cv {
  background-position: -224px -32px;
}
.cnx-flag-medium.cnx-flag-cw {
  background-position: -224px -64px;
}
.cnx-flag-medium.cnx-flag-cx {
  background-position: -224px -96px;
}
.cnx-flag-medium.cnx-flag-cy {
  background-position: -224px -128px;
}
.cnx-flag-medium.cnx-flag-cz {
  background-position: -224px -160px;
}
.cnx-flag-medium.cnx-flag-de {
  background-position: -224px -192px;
}
.cnx-flag-medium.cnx-flag-dj {
  background-position: 0 -224px;
}
.cnx-flag-medium.cnx-flag-dk {
  background-position: -32px -224px;
}
.cnx-flag-medium.cnx-flag-dm {
  background-position: -64px -224px;
}
.cnx-flag-medium.cnx-flag-do {
  background-position: -96px -224px;
}
.cnx-flag-medium.cnx-flag-dz {
  background-position: -128px -224px;
}
.cnx-flag-medium.cnx-flag-ec {
  background-position: -160px -224px;
}
.cnx-flag-medium.cnx-flag-ee {
  background-position: -192px -224px;
}
.cnx-flag-medium.cnx-flag-eg {
  background-position: -224px -224px;
}
.cnx-flag-medium.cnx-flag-eh {
  background-position: -256px 0;
}
.cnx-flag-medium.cnx-flag-er {
  background-position: -256px -32px;
}
.cnx-flag-medium.cnx-flag-es {
  background-position: -256px -64px;
}
.cnx-flag-medium.cnx-flag-et {
  background-position: -256px -96px;
}
.cnx-flag-medium.cnx-flag-eu {
  background-position: -256px -128px;
}
.cnx-flag-medium.cnx-flag-fi {
  background-position: -256px -160px;
}
.cnx-flag-medium.cnx-flag-fj {
  background-position: -256px -192px;
}
.cnx-flag-medium.cnx-flag-fk {
  background-position: -256px -224px;
}
.cnx-flag-medium.cnx-flag-fm {
  background-position: 0 -256px;
}
.cnx-flag-medium.cnx-flag-fo {
  background-position: -32px -256px;
}
.cnx-flag-medium.cnx-flag-fr {
  background-position: -64px -256px;
}
.cnx-flag-medium.cnx-flag-ga {
  background-position: -96px -256px;
}
.cnx-flag-medium.cnx-flag-gb {
  background-position: -128px -256px;
}
.cnx-flag-medium.cnx-flag-gd {
  background-position: -160px -256px;
}
.cnx-flag-medium.cnx-flag-ge {
  background-position: -192px -256px;
}
.cnx-flag-medium.cnx-flag-gg {
  background-position: -224px -256px;
}
.cnx-flag-medium.cnx-flag-gh {
  background-position: -256px -256px;
}
.cnx-flag-medium.cnx-flag-gi {
  background-position: -288px 0;
}
.cnx-flag-medium.cnx-flag-gl {
  background-position: -288px -32px;
}
.cnx-flag-medium.cnx-flag-gm {
  background-position: -288px -64px;
}
.cnx-flag-medium.cnx-flag-gn {
  background-position: -288px -96px;
}
.cnx-flag-medium.cnx-flag-gq {
  background-position: -288px -128px;
}
.cnx-flag-medium.cnx-flag-gr {
  background-position: -288px -160px;
}
.cnx-flag-medium.cnx-flag-gs {
  background-position: -288px -192px;
}
.cnx-flag-medium.cnx-flag-gt {
  background-position: -288px -224px;
}
.cnx-flag-medium.cnx-flag-gu {
  background-position: -288px -256px;
}
.cnx-flag-medium.cnx-flag-gw {
  background-position: 0 -288px;
}
.cnx-flag-medium.cnx-flag-gy {
  background-position: -32px -288px;
}
.cnx-flag-medium.cnx-flag-hk {
  background-position: -64px -288px;
}
.cnx-flag-medium.cnx-flag-hn {
  background-position: -96px -288px;
}
.cnx-flag-medium.cnx-flag-hr {
  background-position: -128px -288px;
}
.cnx-flag-medium.cnx-flag-ht {
  background-position: -160px -288px;
}
.cnx-flag-medium.cnx-flag-hu {
  background-position: -192px -288px;
}
.cnx-flag-medium.cnx-flag-ic {
  background-position: -224px -288px;
}
.cnx-flag-medium.cnx-flag-id {
  background-position: -256px -288px;
}
.cnx-flag-medium.cnx-flag-ie {
  background-position: -288px -288px;
}
.cnx-flag-medium.cnx-flag-il {
  background-position: -320px 0;
}
.cnx-flag-medium.cnx-flag-im {
  background-position: -320px -32px;
}
.cnx-flag-medium.cnx-flag-in {
  background-position: -320px -64px;
}
.cnx-flag-medium.cnx-flag-iq {
  background-position: -320px -96px;
}
.cnx-flag-medium.cnx-flag-ir {
  background-position: -320px -128px;
}
.cnx-flag-medium.cnx-flag-is {
  background-position: -320px -160px;
}
.cnx-flag-medium.cnx-flag-it {
  background-position: -320px -192px;
}
.cnx-flag-medium.cnx-flag-je {
  background-position: -320px -224px;
}
.cnx-flag-medium.cnx-flag-jm {
  background-position: -320px -256px;
}
.cnx-flag-medium.cnx-flag-jo {
  background-position: -320px -288px;
}
.cnx-flag-medium.cnx-flag-jp {
  background-position: 0 -320px;
}
.cnx-flag-medium.cnx-flag-ke {
  background-position: -32px -320px;
}
.cnx-flag-medium.cnx-flag-kg {
  background-position: -64px -320px;
}
.cnx-flag-medium.cnx-flag-kh {
  background-position: -96px -320px;
}
.cnx-flag-medium.cnx-flag-ki {
  background-position: -128px -320px;
}
.cnx-flag-medium.cnx-flag-km {
  background-position: -160px -320px;
}
.cnx-flag-medium.cnx-flag-kn {
  background-position: -192px -320px;
}
.cnx-flag-medium.cnx-flag-kp {
  background-position: -224px -320px;
}
.cnx-flag-medium.cnx-flag-kr {
  background-position: -256px -320px;
}
.cnx-flag-medium.cnx-flag-kw {
  background-position: -288px -320px;
}
.cnx-flag-medium.cnx-flag-ae {
  background-position: -320px -320px;
}
.cnx-flag-medium.cnx-flag-kz {
  background-position: -352px 0;
}
.cnx-flag-medium.cnx-flag-la {
  background-position: -352px -32px;
}
.cnx-flag-medium.cnx-flag-lb {
  background-position: -352px -64px;
}
.cnx-flag-medium.cnx-flag-lc {
  background-position: -352px -96px;
}
.cnx-flag-medium.cnx-flag-li {
  background-position: -352px -128px;
}
.cnx-flag-medium.cnx-flag-lk {
  background-position: -352px -160px;
}
.cnx-flag-medium.cnx-flag-lr {
  background-position: -352px -192px;
}
.cnx-flag-medium.cnx-flag-ls {
  background-position: -352px -224px;
}
.cnx-flag-medium.cnx-flag-lt {
  background-position: -352px -256px;
}
.cnx-flag-medium.cnx-flag-lu {
  background-position: -352px -288px;
}
.cnx-flag-medium.cnx-flag-lv {
  background-position: -352px -320px;
}
.cnx-flag-medium.cnx-flag-ly {
  background-position: 0 -352px;
}
.cnx-flag-medium.cnx-flag-ma {
  background-position: -32px -352px;
}
.cnx-flag-medium.cnx-flag-mc {
  background-position: -64px -352px;
}
.cnx-flag-medium.cnx-flag-md {
  background-position: -96px -352px;
}
.cnx-flag-medium.cnx-flag-me {
  background-position: -128px -352px;
}
.cnx-flag-medium.cnx-flag-mf {
  background-position: -160px -352px;
}
.cnx-flag-medium.cnx-flag-mg {
  background-position: -192px -352px;
}
.cnx-flag-medium.cnx-flag-mh {
  background-position: -224px -352px;
}
.cnx-flag-medium.cnx-flag-mk {
  background-position: -256px -352px;
}
.cnx-flag-medium.cnx-flag-ml {
  background-position: -288px -352px;
}
.cnx-flag-medium.cnx-flag-mm {
  background-position: -320px -352px;
}
.cnx-flag-medium.cnx-flag-mn {
  background-position: -352px -352px;
}
.cnx-flag-medium.cnx-flag-mo {
  background-position: -384px 0;
}
.cnx-flag-medium.cnx-flag-mp {
  background-position: -384px -32px;
}
.cnx-flag-medium.cnx-flag-mq {
  background-position: -384px -64px;
}
.cnx-flag-medium.cnx-flag-mr {
  background-position: -384px -96px;
}
.cnx-flag-medium.cnx-flag-ms {
  background-position: -384px -128px;
}
.cnx-flag-medium.cnx-flag-mt {
  background-position: -384px -160px;
}
.cnx-flag-medium.cnx-flag-mu {
  background-position: -384px -192px;
}
.cnx-flag-medium.cnx-flag-mv {
  background-position: -384px -224px;
}
.cnx-flag-medium.cnx-flag-mw {
  background-position: -384px -256px;
}
.cnx-flag-medium.cnx-flag-mx {
  background-position: -384px -288px;
}
.cnx-flag-medium.cnx-flag-my {
  background-position: -384px -320px;
}
.cnx-flag-medium.cnx-flag-mz {
  background-position: -384px -352px;
}
.cnx-flag-medium.cnx-flag-na {
  background-position: 0 -384px;
}
.cnx-flag-medium.cnx-flag-nc {
  background-position: -32px -384px;
}
.cnx-flag-medium.cnx-flag-ne {
  background-position: -64px -384px;
}
.cnx-flag-medium.cnx-flag-nf {
  background-position: -96px -384px;
}
.cnx-flag-medium.cnx-flag-ng {
  background-position: -128px -384px;
}
.cnx-flag-medium.cnx-flag-ni {
  background-position: -160px -384px;
}
.cnx-flag-medium.cnx-flag-nl {
  background-position: -192px -384px;
}
.cnx-flag-medium.cnx-flag-no {
  background-position: -224px -384px;
}
.cnx-flag-medium.cnx-flag-np {
  background-position: -256px -384px;
}
.cnx-flag-medium.cnx-flag-nr {
  background-position: -288px -384px;
}
.cnx-flag-medium.cnx-flag-nu {
  background-position: -320px -384px;
}
.cnx-flag-medium.cnx-flag-nz {
  background-position: -352px -384px;
}
.cnx-flag-medium.cnx-flag-om {
  background-position: -384px -384px;
}
.cnx-flag-medium.cnx-flag-pa {
  background-position: -416px 0;
}
.cnx-flag-medium.cnx-flag-pe {
  background-position: -416px -32px;
}
.cnx-flag-medium.cnx-flag-pf {
  background-position: -416px -64px;
}
.cnx-flag-medium.cnx-flag-pg {
  background-position: -416px -96px;
}
.cnx-flag-medium.cnx-flag-ph {
  background-position: -416px -128px;
}
.cnx-flag-medium.cnx-flag-pk {
  background-position: -416px -160px;
}
.cnx-flag-medium.cnx-flag-pl {
  background-position: -416px -192px;
}
.cnx-flag-medium.cnx-flag-pn {
  background-position: -416px -224px;
}
.cnx-flag-medium.cnx-flag-pr {
  background-position: -416px -256px;
}
.cnx-flag-medium.cnx-flag-ps {
  background-position: -416px -288px;
}
.cnx-flag-medium.cnx-flag-pt {
  background-position: -416px -320px;
}
.cnx-flag-medium.cnx-flag-pw {
  background-position: -416px -352px;
}
.cnx-flag-medium.cnx-flag-py {
  background-position: -416px -384px;
}
.cnx-flag-medium.cnx-flag-qa {
  background-position: 0 -416px;
}
.cnx-flag-medium.cnx-flag-ro {
  background-position: -32px -416px;
}
.cnx-flag-medium.cnx-flag-rs {
  background-position: -64px -416px;
}
.cnx-flag-medium.cnx-flag-ru {
  background-position: -96px -416px;
}
.cnx-flag-medium.cnx-flag-rw {
  background-position: -128px -416px;
}
.cnx-flag-medium.cnx-flag-sa {
  background-position: -160px -416px;
}
.cnx-flag-medium.cnx-flag-sb {
  background-position: -192px -416px;
}
.cnx-flag-medium.cnx-flag-sc {
  background-position: -224px -416px;
}
.cnx-flag-medium.cnx-flag-sd {
  background-position: -256px -416px;
}
.cnx-flag-medium.cnx-flag-se {
  background-position: -288px -416px;
}
.cnx-flag-medium.cnx-flag-sg {
  background-position: -320px -416px;
}
.cnx-flag-medium.cnx-flag-sh {
  background-position: -352px -416px;
}
.cnx-flag-medium.cnx-flag-si {
  background-position: -384px -416px;
}
.cnx-flag-medium.cnx-flag-sk {
  background-position: -416px -416px;
}
.cnx-flag-medium.cnx-flag-sl {
  background-position: -448px 0;
}
.cnx-flag-medium.cnx-flag-sm {
  background-position: -448px -32px;
}
.cnx-flag-medium.cnx-flag-sn {
  background-position: -448px -64px;
}
.cnx-flag-medium.cnx-flag-so {
  background-position: -448px -96px;
}
.cnx-flag-medium.cnx-flag-sr {
  background-position: -448px -128px;
}
.cnx-flag-medium.cnx-flag-ss {
  background-position: -448px -160px;
}
.cnx-flag-medium.cnx-flag-st {
  background-position: -448px -192px;
}
.cnx-flag-medium.cnx-flag-sv {
  background-position: -448px -224px;
}
.cnx-flag-medium.cnx-flag-sy {
  background-position: -448px -256px;
}
.cnx-flag-medium.cnx-flag-sz {
  background-position: -448px -288px;
}
.cnx-flag-medium.cnx-flag-tc {
  background-position: -448px -320px;
}
.cnx-flag-medium.cnx-flag-td {
  background-position: -448px -352px;
}
.cnx-flag-medium.cnx-flag-tf {
  background-position: -448px -384px;
}
.cnx-flag-medium.cnx-flag-tg {
  background-position: -448px -416px;
}
.cnx-flag-medium.cnx-flag-th {
  background-position: 0 -448px;
}
.cnx-flag-medium.cnx-flag-tj {
  background-position: -32px -448px;
}
.cnx-flag-medium.cnx-flag-tk {
  background-position: -64px -448px;
}
.cnx-flag-medium.cnx-flag-tl {
  background-position: -96px -448px;
}
.cnx-flag-medium.cnx-flag-tm {
  background-position: -128px -448px;
}
.cnx-flag-medium.cnx-flag-tn {
  background-position: -160px -448px;
}
.cnx-flag-medium.cnx-flag-to {
  background-position: -192px -448px;
}
.cnx-flag-medium.cnx-flag-tr {
  background-position: -224px -448px;
}
.cnx-flag-medium.cnx-flag-tt {
  background-position: -256px -448px;
}
.cnx-flag-medium.cnx-flag-tv {
  background-position: -288px -448px;
}
.cnx-flag-medium.cnx-flag-tw {
  background-position: -320px -448px;
}
.cnx-flag-medium.cnx-flag-tz {
  background-position: -352px -448px;
}
.cnx-flag-medium.cnx-flag-ua {
  background-position: -384px -448px;
}
.cnx-flag-medium.cnx-flag-ug {
  background-position: -416px -448px;
}
.cnx-flag-medium.cnx-flag-us {
  background-position: -448px -448px;
}
.cnx-flag-medium.cnx-flag-uy {
  background-position: -480px 0;
}
.cnx-flag-medium.cnx-flag-uz {
  background-position: -480px -32px;
}
.cnx-flag-medium.cnx-flag-va {
  background-position: -480px -64px;
}
.cnx-flag-medium.cnx-flag-vc {
  background-position: -480px -96px;
}
.cnx-flag-medium.cnx-flag-ve {
  background-position: -480px -128px;
}
.cnx-flag-medium.cnx-flag-vg {
  background-position: -480px -160px;
}
.cnx-flag-medium.cnx-flag-vi {
  background-position: -480px -192px;
}
.cnx-flag-medium.cnx-flag-vn {
  background-position: -480px -224px;
}
.cnx-flag-medium.cnx-flag-vu {
  background-position: -480px -256px;
}
.cnx-flag-medium.cnx-flag-wf {
  background-position: -480px -288px;
}
.cnx-flag-medium.cnx-flag-ws {
  background-position: -480px -320px;
}
.cnx-flag-medium.cnx-flag-ye {
  background-position: -480px -352px;
}
.cnx-flag-medium.cnx-flag-yt {
  background-position: -480px -384px;
}
.cnx-flag-medium.cnx-flag-za {
  background-position: -480px -416px;
}
.cnx-flag-medium.cnx-flag-zm {
  background-position: -480px -448px;
}
.cnx-flag-medium.cnx-flag-zw {
  background-position: 0 -480px;
}

html {
  margin: 0;
  height: 100%;
  height: 100vh;
}

body {
  position: relative;
  margin: 0;
  background-color: #fff;
  min-width: 320px;
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 992px) {
  body {
    font: 16px "Lato", sans-serif;
  }
}
@media screen and (min-width: 992px) {
  body {
    font: 12.5px "Lato", sans-serif;
  }
}

.widget-body {
  overflow: hidden;
}

.widget-root {
  overflow: hidden;
}
.widget-root .view-root {
  margin-bottom: 20px;
}

.fixed-bg {
  position: fixed;
  top: 0;
  z-index: -1;
  background-attachment: fixed;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 20%, rgba(50, 50, 50, 0.3) 50%), url("https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 110%;
}
@media screen and (max-width: 767px) {
  .fixed-bg {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 20%, rgba(50, 50, 50, 0.3) 50%), url("https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg");
    background-position: right bottom;
  }
}

.view-root {
  width: 100%;
}

.view-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  background: #fafafa;
  padding: 1px 12px 12px;
}
.view-container::after {
  clear: both;
  content: "";
  display: table;
}

[data-lightbox=lightbox],
[data-toggle=toggle] {
  cursor: pointer;
  user-select: none;
}

.content-panel {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
}

.content-panel-header {
  margin: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #fff;
  padding: 12px;
  color: rgba(150, 31, 64, 0.9415);
  font-size: 18.2px;
  font-weight: bold;
}
.content-panel-header .content-panel-header-text {
  float: none;
  overflow: hidden;
}
.content-panel-header .content-panel-header-icon {
  float: right;
}

.content-panel-subheader {
  display: block;
  margin: 12px 0 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12.5px;
  font-weight: bold;
}

.content-panel-content {
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fff;
  padding: 0 12px 12px;
}

input::placeholder {
  color: #9e9e9e;
}

.is-mobile {
  position: absolute;
  top: -999em;
  left: -999em;
}

@media screen and (max-width: 767px) {
  .is-mobile::before {
    content: "mobile";
  }
}
.alert {
  display: none;
  margin-bottom: 24px;
  border: 1px solid transparent;
  border-radius: var(--wlp-bs-border-radius);
  padding: 15px;
}
.alert .alert-link {
  font-weight: 700;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}

.alert-success {
  border-color: #d6e9c6;
  background-color: #dff0d8;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  border-color: #bce8f1;
  background-color: #d9edf7;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  border-color: #faebcc;
  background-color: #fcf8e3;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  border-color: #ebccd1;
  background-color: #f2dede;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

.autocomplete-box {
  position: relative;
}
.autocomplete-box input {
  width: 100%;
}
.autocomplete-box .cnx-loading {
  position: absolute;
  top: 1em;
  right: 3em;
  margin: 0;
  font-size: 8px;
}

.autocomplete-results {
  display: none;
  position: relative;
  margin: 0 0 10px;
  background-color: transparent;
  padding: 0;
  width: 100%;
  max-height: 300px;
  max-height: 30%;
  overflow: hidden;
  list-style: none;
}
.autocomplete-results.visible {
  display: block;
}
.autocomplete-results .autocomplete-item {
  clear: both;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 3px 0;
  line-height: 30px;
}
.autocomplete-results .autocomplete-item:last-child {
  border-bottom: 0;
}
.autocomplete-results .autocomplete-item .location-icons {
  display: block;
  float: left;
}
.autocomplete-results .autocomplete-item .location-icon {
  padding: 0 0.5em;
}
.autocomplete-results .autocomplete-item .location-flag {
  display: inline-block;
}
.autocomplete-results .autocomplete-item .location-label-wrapper {
  display: block;
  float: none;
  overflow: hidden;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  line-height: 18px;
}
@media screen and (min-width: 992px) {
  .autocomplete-results .autocomplete-item .location-label-wrapper {
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 16px;
  }
}
.autocomplete-results .autocomplete-item .location-label {
  margin-right: 10px;
}
.autocomplete-results .autocomplete-item .location-country {
  white-space: nowrap;
  color: #999;
}
.autocomplete-results .autocomplete-item-highlighted {
  background: #eee;
}
@media screen and (min-width: 768px) {
  .autocomplete-results {
    margin-bottom: 0;
  }
}

.autocomplete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #bdbdbd;
  border-radius: var(--wlp-bs-border-radius);
  background-color: #fff;
  cursor: pointer;
  padding: 0 8px;
  width: 100%;
  height: 100%;
  line-height: 42px;
}
.autocomplete-overlay .autocomplete-overlay-label {
  float: left;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.autocomplete-overlay .autocomplete-overlay-label i {
  padding-right: 8px;
  color: #bdbdbd;
}
.autocomplete-overlay .clear-icon {
  float: right;
  color: rgba(0, 0, 0, 0.87);
}

.breadcrumbs-panel {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #fafafa;
  padding-top: 3px;
  padding-bottom: 12px;
}
.breadcrumbs-panel::after {
  clear: both;
  content: "";
  display: table;
}

.breadcrumbs-normal {
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
}
.breadcrumbs-normal .breadcrumb {
  display: table-cell;
  width: 20%;
  height: 38px;
}
.breadcrumbs-normal .breadcrumb .caption {
  position: relative;
  z-index: 20;
  overflow: hidden;
  font-size: 0.9rem;
}
@media screen and (max-width: 767px) {
  .breadcrumbs-normal .breadcrumb .caption {
    display: none;
  }
}
.breadcrumbs-normal .breadcrumb .step {
  display: inline;
  position: relative;
  z-index: 20;
  padding: 0 6px;
  font-size: 0.8rem;
  font-weight: bold;
}
.breadcrumbs-normal .breadcrumb:last-of-type {
  padding-right: 26.870047px;
}
.breadcrumbs-normal .sequent {
  display: block;
  position: relative;
  float: left;
  background-color: #e0e0e0;
  padding-left: 26.870047px;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  line-height: 38px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
}
.breadcrumbs-normal .sequent::after {
  display: block;
  position: absolute;
  top: 0;
  right: -19px;
  transform: rotate(45deg);
  z-index: 2;
  border-top: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  border-bottom-left-radius: 38px;
  background: #e0e0e0;
  width: 38px;
  height: 38px;
  content: " ";
}
.complete .breadcrumbs-normal .sequent::after {
  background: #f43369;
}
.active .breadcrumbs-normal .sequent::after {
  background: #01afc8;
}
.breadcrumbs-normal .active .sequent {
  background: #01afc8;
  color: rgb(255, 255, 255);
}
.breadcrumbs-normal .active .sequent::after {
  background: #01afc8;
}
.breadcrumbs-normal .complete .sequent {
  background: #f43369;
  color: rgb(255, 255, 255);
}
.breadcrumbs-normal .complete .sequent::after {
  background: #f43369;
}
.breadcrumbs-normal .complete:hover {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .breadcrumbs-normal {
    width: 100%;
  }
  .breadcrumbs-normal .active {
    width: 80%;
  }
  .breadcrumbs-normal .active .caption {
    display: inline;
    font-size: 0.8em;
  }
}

.search-breadcrumbs-wrapper {
  display: none;
}
.search-breadcrumbs-wrapper.loaded {
  display: block;
}

.confirmation-dialog {
  margin: 0;
  background-color: #f5f5f5;
  padding: 20px;
  text-align: left;
}
.confirmation-dialog .modal-confirm-button-wrapper {
  margin-top: 20px;
  text-align: center;
}
.confirmation-dialog .modal-confirm-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: inline-block;
  background-color: #f43369;
  padding: 10px 20px;
  line-height: normal;
}
.confirmation-dialog .modal-confirm-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.confirmation-dialog .modal-confirm-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.confirmation-dialog .modal-confirm-button:hover {
  color: rgb(255, 255, 255);
}
.confirmation-dialog .modal-link-button {
  display: inline-block;
  text-decoration: none;
  color: #f43369;
}
.confirmation-dialog .modal-link-button:hover {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .confirmation-dialog {
    background-color: #fff;
  }
}
@media screen and (max-width: 992px) {
  .confirmation-dialog {
    width: 100%;
  }
}

.lightbox-refresh-search .lightbox-inner {
  width: 80%;
  max-width: 800px;
}
@media screen and (max-width: 767px) {
  .lightbox-refresh-search .lightbox-inner {
    width: 100%;
  }
}

.lightbox-price-jump .lightbox-inner {
  background: #f5f5f5;
}
@media screen and (max-width: 767px) {
  .lightbox-price-jump .lightbox-content {
    padding-top: 0;
  }
}
.lightbox-price-jump .price-jump-car-info {
  background: #fff;
  padding: 12px 0;
}
.lightbox-price-jump .price-jump-car-info .price-jump-row {
  clear: both;
  padding-top: 12px;
}
.lightbox-price-jump .price-jump-car-info .car-header-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.lightbox-price-jump .price-jump-car-info .vendor-branding {
  display: inline-block;
  float: none;
  margin: 0 0 0 12px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .lightbox-price-jump .price-jump-car-info .vendor-branding {
    display: block;
    margin-top: 6px;
    max-width: 100%;
  }
}
.lightbox-price-jump .price-jump-car-info .vendor-image img {
  vertical-align: middle;
}
@media screen and (min-width: 768px) {
  .lightbox-price-jump .price-jump-car-info .vendor-image img {
    height: 20px;
  }
}
.lightbox-price-jump .price-jump-car-info .car-image {
  display: inline-block;
  vertical-align: middle;
}
.lightbox-price-jump .price-jump-car-info .car-image img {
  width: 150px;
}
.lightbox-price-jump .price-jump-car-info .more-details-wrapper {
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
}
.lightbox-price-jump .confirmation-dialog {
  border-top: 1px solid #e0e0e0;
  background: inherit;
  text-align: center;
}
.lightbox-price-jump .confirmation-dialog h2, .lightbox-price-jump .confirmation-dialog :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) .lightbox-price-jump .confirmation-dialog .h2 {
  margin-top: 0;
}
.lightbox-price-jump .confirmation-dialog .price-jump-prices {
  margin: 24px 0;
  line-height: 200%;
}
.lightbox-price-jump .confirmation-dialog .new-price {
  font-size: 160%;
}
.lightbox-price-jump .lightbox-close-button,
.lightbox-price-jump .lightbox-title-bar {
  display: none;
}

.country-of-residence-selector-wrapper {
  display: inline-block;
  width: 100%;
}
.country-of-residence-selector-wrapper .country-of-residence-selector {
  display: none;
}
.country-of-residence-selector-wrapper label {
  display: block;
}
.country-of-residence-selector-wrapper select {
  margin-top: 6px;
  width: 100%;
  color: #000;
  font-size: 0.8rem;
}
.country-of-residence-selector-wrapper.active .different-residence {
  display: none;
}
.country-of-residence-selector-wrapper.active .country-of-residence-selector {
  display: block;
}
@media screen and (max-width: 767px) {
  .country-of-residence-selector-wrapper {
    width: 100%;
  }
}

.datepicker {
  background: #fff;
  width: 400px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .datepicker {
    width: 100%;
  }
}

.datepicker-selected-dates {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: none;
  background: #f43369;
  color: rgb(255, 255, 255);
}
.datepicker-selected-dates::after {
  clear: both;
  content: "";
  display: table;
}

.datepicker-currently-selecting {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #f43369;
  padding: 8px;
  color: rgb(255, 255, 255);
}
.datepicker-currently-selecting::after {
  clear: both;
  content: "";
  display: table;
}

.datepicker-selected {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%;
  border-bottom: 4px solid #f43369;
  cursor: pointer;
  padding: 12px 0 8px;
  text-align: center;
}
.datepicker-selected:last-child {
  margin-right: 0;
}
.datepicker-selected:hover {
  border-bottom: 4px solid #e0e0e0;
}
.datepicker-selected.selected {
  border-bottom: 4px solid #e0e0e0;
  font-weight: bold;
}

.datepicker-inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px 60px;
  width: 100%;
  overflow-y: scroll;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
.datepicker-inner::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner:nth-child(7n) {
  margin-right: 0;
}
.datepicker-inner:nth-child(7n+1) {
  clear: left;
}
.datepicker-inner .datepicker-days {
  display: block;
}
.datepicker-inner .datepicker-days::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-days span {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 12.1430157118%;
  padding: 8px;
}
.datepicker-inner .datepicker-days span:last-child {
  margin-right: 0;
}
.datepicker-inner .datepicker-month {
  display: block;
  margin-top: 8px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background: #f43369;
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.datepicker-inner .datepicker-month::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-month span {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  padding: 12px 0;
}
.datepicker-inner .datepicker-month span:last-child {
  margin-right: 0;
}
.datepicker-inner .datepicker-dates {
  display: block;
}
.datepicker-inner .datepicker-dates::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-dates span {
  float: left;
  margin: 4px 0;
  padding: 8px;
  width: 14.2857143%;
}
.datepicker-inner.dropoff .datepicker-dropoff-date, .datepicker-inner.pickup .datepicker-pickup-date {
  border: 2px solid #f43369;
  border-radius: 24px;
  background: #f43369;
  color: rgb(255, 255, 255);
}
.datepicker-inner .datepicker-non-selectable-date {
  cursor: default;
  color: #e0e0e0;
}
.datepicker-inner .datepicker-selectable-date {
  cursor: pointer;
}
.datepicker-inner .datepicker-selectable-date:hover {
  border-radius: 24px;
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (min-width: 768px) {
  .datepicker-inner {
    max-height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .datepicker-inner {
    max-height: 100%;
  }
}

.api-error {
  margin: 20px 0;
  border-radius: 4px;
  background: #dd1c1a;
  padding: 12px;
  text-align: center;
  color: #fff;
}

.payment-error {
  display: none;
  align-items: flex-start;
  flex-direction: row;
  margin: 15px 0;
  border-radius: 4px;
  background: #cf1e1b;
  padding: 15px;
  text-align: left;
  color: #fff;
}
.payment-error.payment-failed {
  display: flex;
}
.payment-error.payment-failed .failed {
  display: block;
}
.payment-error.payment-cancelled {
  display: flex;
}
.payment-error.payment-cancelled .cancelled {
  display: block;
}
.payment-error .failed,
.payment-error .cancelled {
  display: none;
}
.payment-error .icon-warning {
  display: inline-block;
  margin-right: 15px;
  font-size: 220%;
}
.payment-error h3, .payment-error :where([data-cnx-widget], [data-cnx-container]) .h3, :where([data-cnx-widget], [data-cnx-container]) .payment-error .h3 {
  margin: 0 0 10px;
}
.payment-error p {
  margin: 0;
}

.filters {
  margin: 0;
  padding: 0;
  list-style: none;
}
.filters .filter {
  display: block;
}
.filters .filter::after {
  clear: both;
  content: "";
  display: table;
}

.filter-header-collapsible,
.filter-header {
  margin: 6px 0;
  padding: 12px 0;
  color: rgba(205, 43, 88, 0.974);
}

.filter-header-collapsible i {
  float: right;
}

.filter-group-section {
  display: block;
  margin: auto;
  border-bottom: 1px solid #e0e0e0;
  width: 90%;
}
.filter-group-section::after {
  clear: both;
  content: "";
  display: table;
}
.filter-group-section.active .filter-checklist {
  display: block;
}
.filter-group-section.active .filter-header-collapsible i {
  transform: rotate(180deg);
}

.filter-checklist {
  display: block;
  display: none;
  margin: 0;
  margin-bottom: 12px;
  padding-left: 0;
  list-style: none;
}
.filter-checklist::after {
  clear: both;
  content: "";
  display: table;
}
.filter-checklist .filter-checklist-item {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%;
  position: relative;
  z-index: 1;
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: 6px;
  border: solid 1px #e0e0e0;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  padding: 8px 0;
  width: 100%;
  height: 100%;
  text-align: center;
  user-select: none;
}
.filter-checklist .filter-checklist-item:last-child {
  margin-right: 0;
}
.filter-checklist .filter-checklist-item:nth-child(2n) {
  margin-right: 0;
}
.filter-checklist .filter-checklist-item:nth-child(2n+1) {
  clear: left;
}
.filter-checklist .filter-checklist-item::before {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  content: "";
}
.filter-checklist .filter-checklist-item:hover::before {
  transition: 0s;
  opacity: 1;
}
.filter-checklist .filter-checklist-item::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.filter-checklist .filter-checklist-item:active::after {
  transition: 0s;
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .filter-checklist .filter-checklist-item {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .filter-checklist .filter-checklist-item:last-child {
    margin-right: 0;
  }
  .filter-checklist .filter-checklist-item:nth-child(1n) {
    margin-right: 0;
  }
  .filter-checklist .filter-checklist-item:nth-child(1n+1) {
    clear: left;
  }
}
.filter-checklist .active-filter {
  border-color: #f43369;
  color: #f43369;
  font-weight: bold;
}

.filter-price-range-label {
  margin: 0.5em 0 0.25em;
  font-size: 16px;
}

.filter-price-range {
  width: 100%;
}

.filter-price-section {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%;
}
.filter-price-section:last-child {
  margin-right: 0;
}

.filter-checkbox {
  margin-right: 4px;
}

.modify-search-panel {
  position: relative;
  z-index: 1;
  transition: width 0.3s;
  margin-bottom: 12px;
  width: 100%;
}
.modify-search-panel::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 0 12px #e3ab31;
  width: 100%;
  height: 100%;
  animation-name: highlight;
  animation-duration: 4s;
  content: "";
}
@media screen and (min-width: 992px) {
  .modify-search-panel {
    display: none;
  }
  .map-search-page .modify-search-panel {
    display: block;
  }
  .map-search-page .modify-search-panel .filter-toggle {
    display: none;
  }
}

.filter-sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: -1px 1px 2px 0 #e0e0e0;
  width: 100%;
}
.filter-sticky .accordion-group .filter-toggle,
.filter-sticky .accordion-group .search-summary-toggle {
  border-radius: 0;
}

.apply-filters-button {
  position: relative;
  z-index: 1;
  display: block;
  margin: 12px auto 0;
  border: 0 none;
  border-radius: 4px;
  background-color: #f43369;
  cursor: pointer;
  width: 90%;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: #fff;
  font-weight: 700;
}
.apply-filters-button::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.apply-filters-button:active::after {
  transition: 0s;
  opacity: 1;
}

.clear-filters-button {
  position: relative;
  z-index: 1;
  display: block;
  margin: 12px auto 0;
  border: 0 none;
  border-radius: 4px;
  background-color: #e0e0e0;
  cursor: pointer;
  width: 90%;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: constrast-color(#e0e0e0);
  font-weight: 700;
}
.clear-filters-button::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.clear-filters-button:active::after {
  transition: 0s;
  opacity: 1;
}

.no-results-header {
  margin: 16px auto 0;
  border: 1px solid #01afc8;
  border-radius: 50px;
  background-color: #fff;
  padding-top: 4px;
  width: 48px;
  height: 48px;
  text-align: center;
  color: #01afc8;
  font-size: 200%;
  font-weight: bolder;
}

.no-results-text {
  text-align: center;
}

.prevent-scroll {
  overflow: hidden;
}

.car-category-filter-5 {
  display: block;
  margin-top: 0;
  border: 0;
  padding-left: 0;
  width: 100%;
  list-style: none;
}
.car-category-filter-5::after {
  clear: both;
  content: "";
  display: table;
}
.car-category-filter-5 .car-category-filter-item {
  width: 19.4%;
}

.has-active-filters .car-category-filter-5 .car-category-filter-item {
  background: #eee;
}
.has-active-filters .car-category-filter-5 .active-filter {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f43369;
}

.car-category-filter-6 {
  display: block;
  margin-top: 0;
  border: 0;
  padding-left: 0;
  width: 100%;
  list-style: none;
}
.car-category-filter-6::after {
  clear: both;
  content: "";
  display: table;
}
.car-category-filter-6 .car-category-filter-item {
  width: 16.0416666667%;
}

.has-active-filters .car-category-filter-6 .car-category-filter-item {
  background: #eee;
}
.has-active-filters .car-category-filter-6 .active-filter {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f43369;
}

.car-category-filter-7 {
  display: block;
  margin-top: 0;
  border: 0;
  padding-left: 0;
  width: 100%;
  list-style: none;
}
.car-category-filter-7::after {
  clear: both;
  content: "";
  display: table;
}
.car-category-filter-7 .car-category-filter-item {
  width: 13.6428571429%;
}

.has-active-filters .car-category-filter-7 .car-category-filter-item {
  background: #eee;
}
.has-active-filters .car-category-filter-7 .active-filter {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f43369;
}

.car-category-filter-8 {
  display: block;
  margin-top: 0;
  border: 0;
  padding-left: 0;
  width: 100%;
  list-style: none;
}
.car-category-filter-8::after {
  clear: both;
  content: "";
  display: table;
}
.car-category-filter-8 .car-category-filter-item {
  width: 11.84375%;
}

.has-active-filters .car-category-filter-8 .car-category-filter-item {
  background: #eee;
}
.has-active-filters .car-category-filter-8 .active-filter {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #f43369;
}

.car-category-filter-item {
  position: relative;
  z-index: 1;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  float: left;
  margin-left: 0.75%;
  background: #fff;
  cursor: pointer;
  padding: 12px;
  user-select: none;
}
.car-category-filter-item::before {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  content: "";
}
.car-category-filter-item:hover::before {
  transition: 0s;
  opacity: 0.7;
}
.car-category-filter-item:hover {
  background-color: #fff;
}
.car-category-filter-item::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.car-category-filter-item:active::after {
  transition: 0s;
  opacity: 1;
}
.car-category-filter-item:nth-child(1) {
  margin-left: 0;
}
.car-category-filter-item .car-category-filter-item-title {
  display: block;
  width: 100%;
  height: 36px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 18px;
  color: rgba(205, 43, 88, 0.974);
  font-size: 14px;
}
.car-category-filter-item .car-category-filter-item-image {
  padding: 6px;
  width: 100%;
}
.car-category-filter-item .car-category-filter-item-subtext {
  display: inline-block;
  width: 100%;
  height: 34px;
  text-align: center;
  line-height: 17px;
}

@media screen and (min-width: 768px) {
  .car-category-filter-4,
  .car-category-filter-3,
  .car-category-filter-2,
  .car-category-filter-1 {
    display: none;
  }
  .filter-group-section-carcategories-5,
  .filter-group-section-carcategories-6,
  .filter-group-section-carcategories-7,
  .filter-group-section-carcategories-8 {
    display: none;
  }
  .map-search-page .filter-group-section-carcategories-5,
  .map-search-page .filter-group-section-carcategories-6,
  .map-search-page .filter-group-section-carcategories-7,
  .map-search-page .filter-group-section-carcategories-8 {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .car-category-filter-8,
  .car-category-filter-7,
  .car-category-filter-6,
  .car-category-filter-5,
  .car-category-filter-4,
  .car-category-filter-3,
  .car-category-filter-2,
  .car-category-filter-1 {
    display: none;
  }
}
.insurance-content-panel {
  margin-bottom: 12px;
}

.content-panel .insurance-popout-content {
  display: none;
}

.insurance-header {
  float: none;
  overflow: hidden;
}

.insurance-price {
  float: right;
}

.insurance-subheader {
  padding: 0 12px 12px;
  line-height: 1.4;
}

.insurance-more-details-link {
  display: block;
  margin-top: 6px;
  color: #f43369;
  font-size: 12.5px;
}

.insurance-positive {
  border-top: 1px solid #e0e0e0;
}

.insurance-positive-check {
  float: right;
  padding: 6px 12px;
  color: #10ad12;
  font-size: 15px;
}

.insurance-positive-header {
  float: none;
  margin: 0;
  padding: 6px 12px;
  overflow: hidden;
  color: #10ad12;
  font-size: 15px;
  font-weight: bold;
}
.insurance-positive-header i {
  display: block;
  float: left;
}
.insurance-positive-header .insurance-positive-header-text {
  display: block;
  float: none;
  overflow: hidden;
}
.insurance-positive.active .insurance-positive-header i {
  transform: rotate(90deg);
}

.insurance-positive-description {
  display: none;
  margin: 0;
  padding: 0 12px 6px 27px;
}
.insurance-positive.active .insurance-positive-description {
  display: block;
}

.insurance-actions {
  display: block;
  border-top: 1px solid #e0e0e0;
  background: #fff;
  padding: 12px;
  user-select: none;
}
.insurance-actions::after {
  clear: both;
  content: "";
  display: table;
}

.insurance-popout-content {
  text-align: left;
}
.insurance-popout-content .insurance-popout-text {
  padding: 12px;
}
.insurance-popout-content h1, .insurance-popout-content :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .insurance-popout-content .h1 {
  margin-top: 0;
}
.insurance-popout-content h1, .insurance-popout-content :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .insurance-popout-content .h1,
.insurance-popout-content h2,
.insurance-popout-content :where([data-cnx-widget], [data-cnx-container]) .h2,
:where([data-cnx-widget], [data-cnx-container]) .insurance-popout-content .h2,
.insurance-popout-content h3,
.insurance-popout-content :where([data-cnx-widget], [data-cnx-container]) .h3,
:where([data-cnx-widget], [data-cnx-container]) .insurance-popout-content .h3,
.insurance-popout-content h4,
.insurance-popout-content :where([data-cnx-widget], [data-cnx-container]) .h4,
:where([data-cnx-widget], [data-cnx-container]) .insurance-popout-content .h4 {
  font-size: 16px;
}
.insurance-popout-content p {
  margin-top: 0;
  line-height: 1.6;
}
.insurance-popout-content ul {
  margin: 1em 0;
  padding: 0;
  list-style: none;
}
.insurance-popout-content li {
  position: relative;
  margin-top: 6px;
  margin-left: 20px;
}
.insurance-popout-content li::before {
  text-transform: none;
  line-height: 1em;
  font-family: default-icons;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-style: normal;
  speak: none;
  /* additional CSS declaration generated by fontello but removed
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  */
  position: relative;
  top: 1px;
  position: absolute;
  left: -25px;
  margin: 0 6px;
  color: #10ad12;
  content: "\e80b";
}
@media screen and (min-width: 768px) {
  .insurance-popout-content {
    width: 500px;
    max-width: 100%;
    height: 500px;
    max-height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .insurance-popout-content {
    display: inline-block;
  }
}

.insurance-popout-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 24px 12px;
  text-align: center;
}
.insurance-popout-header .insurance-popout-header-tagline {
  margin: 0;
  color: #f43369;
  font-size: 18px;
}
.insurance-popout-header .insurance-popout-header-subheader {
  margin: 6px 0 0;
  font-size: 16px;
}

.insurance-reassurance {
  display: table;
  margin-bottom: 12px;
  padding: 0 12px;
  width: 100%;
}

.insurance-reassurance-price {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  color: #f43369;
  font-size: 15px;
  font-weight: bold;
}

.insurance-disclaimer {
  display: table-cell;
  margin-top: 12px;
  overflow: hidden;
  vertical-align: middle;
  font-size: 0.8em;
}
.insurance-disclaimer a {
  color: #f43369;
}

.insurance-confirm-wrapper {
  margin-bottom: 24px;
  padding: 0 12px;
}
.insurance-confirm-wrapper::after {
  clear: both;
  content: "";
  display: table;
}

.no-insurance-book-button,
.with-insurance-book-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  float: right;
  margin-left: 12px;
  border: 1px solid #f43369;
  padding: 0 12px;
  line-height: 1;
}
.no-insurance-book-button:hover,
.with-insurance-book-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.no-insurance-book-button:active,
.with-insurance-book-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.no-insurance-book-button:hover,
.with-insurance-book-button:hover {
  color: rgb(255, 255, 255);
}
@media screen and (max-width: 767px) {
  .no-insurance-book-button,
  .with-insurance-book-button {
    margin-bottom: 6px;
    margin-left: 0;
    width: 100%;
  }
}

.no-insurance-book-button {
  border-color: #e0e0e0;
  background: rgb(255, 255, 255);
  color: #f43369;
}
.no-insurance-book-button:hover {
  background: #fafafa;
  color: #f43369;
}

.insurance-book-title {
  line-height: 1.6;
  font-size: 1.6em;
}

.insurance-book-description {
  padding-bottom: 4px;
  line-height: 1.6;
  font-size: 1.2em;
}

.insurance-button-wrapper {
  display: none;
}

.insurance-loaded .confirm-wrapper {
  display: none;
}

.insurance-loaded:not(.limited-extras) .insurance-button-wrapper {
  display: block;
}

.menu-options {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 18px;
  height: 100%;
  color: #fff;
}
.menu-options::after {
  clear: both;
  content: "";
  display: table;
}

.change-settings, .language, .language-caption {
  margin-left: 10px;
  padding: 8px;
}

.language-caption {
  margin-top: 10px;
  color: #fff;
}

.language label {
  color: #fff;
}
.language .select-language {
  margin: 0.5em 0;
  width: 100%;
}
.language .select-language select {
  display: block;
  width: 100%;
}

.change-settings {
  color: #fff;
}

.submit-new-settings {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  text-align: center;
  text-decoration: none;
}
.submit-new-settings:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.submit-new-settings:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.submit-new-settings:hover {
  color: rgb(255, 255, 255);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
}
.lightbox.hidden {
  display: none;
}

.lightbox-container {
  position: relative;
}

@media screen and (max-width: 767px) {
  .lightbox-vertical-center {
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .lightbox-vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }
}

.lightbox-inner {
  background: #fff;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .lightbox-inner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .lightbox-inner {
    display: inline-block;
    border-radius: 4px;
  }
}

.lightbox-fixed-height {
  margin: auto;
}
.lightbox-fixed-height .lightbox-content {
  width: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 768px) {
  .lightbox-fixed-height .lightbox-content {
    max-height: 500px;
  }
}

.lightbox-fixed-width {
  display: block;
  margin: auto;
  max-width: 500px;
}
.lightbox-fixed-width .lightbox-content {
  width: 100%;
}

.lightbox-content {
  max-height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  .lightbox-content {
    padding: 42px 0;
  }
}

.lightbox-loading {
  margin: 0 auto;
  width: 100px;
  height: 100px;
}
.lightbox-loading .cnx-loading {
  display: block;
}

.lightbox-close-button {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  z-index: 999;
  border-bottom: 4px solid #fff;
  background: #fff;
  cursor: pointer;
  padding: 9px 0 5px;
  text-align: center;
  color: #f43369;
}
.lightbox-close-button:last-child {
  margin-right: 0;
}
.lightbox-close-button:hover {
  border-bottom: 4px solid #f43369;
  color: #f43369;
}
@media screen and (min-width: 768px) {
  .lightbox-close-button {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .lightbox-close-button {
    position: absolute;
    bottom: 0;
  }
}

.lightbox-title-bar {
  display: block;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  cursor: pointer;
  padding: 9px 12px;
  text-align: left;
}
.lightbox-title-bar::after {
  clear: both;
  content: "";
  display: table;
}
.lightbox-title-bar .lightbox-title {
  float: none;
  font-weight: bold;
}
.lightbox-title-bar .lightbox-desktop-close-button {
  float: right;
}
.lightbox-title-bar:hover .lightbox-desktop-close-button {
  color: #f43369;
}
@media screen and (max-width: 767px) {
  .lightbox-title-bar {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
  }
  .lightbox-title-bar .lightbox-desktop-close-button {
    display: none;
  }
}

.lightbox-alternative {
  text-align: left;
  line-height: 1.5;
  font-size: 0.78125rem;
}
.lightbox-alternative .lightbox-vertical-center {
  height: 100%;
}
.widget-body .lightbox-alternative .lightbox-vertical-center {
  position: relative;
}
@media screen and (min-width: 768px) {
  .lightbox-alternative .lightbox-vertical-center {
    height: 600px;
  }
}
.lightbox-alternative .lightbox-inner {
  display: flex;
  position: static;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 0;
  width: 100%;
  max-width: 800px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .lightbox-alternative .lightbox-inner {
    margin: 1.75rem auto;
  }
}
.lightbox-alternative .lightbox-title-bar {
  position: static;
  background: #f43369;
  text-align: left;
  color: #fff;
  font-size: 110%;
  font-weight: 400;
}
.lightbox-alternative .lightbox-title-bar:hover .lightbox-desktop-close-button {
  color: #fff;
}
.lightbox-alternative .lightbox-title-bar .lightbox-desktop-close-button {
  display: block;
}
.lightbox-alternative .lightbox-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}
.lightbox-alternative .lightbox-content .modal-body {
  padding: 0;
  overflow: hidden;
}
.lightbox-alternative .lightbox-content .modal-footer {
  border-right: 0;
  border-left: 0;
  border-top-width: 0;
  border-radius: 0;
  box-shadow: 0 -2px 5px -1px #d5d5d5;
  background: #d9f7ff;
  padding: 0;
}
.lightbox-alternative .lightbox-close-button {
  display: none;
}

.tabs-in-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-content {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
}

.nav-tabs {
  align-items: flex-start;
  flex-wrap: nowrap;
  box-shadow: inset 0 -5px 8px -8px #c1c1c1;
  background-color: rgb(240, 240, 240);
}
.nav-tabs .nav-link {
  transition: none;
  border: none;
  padding: 1rem 0.4rem;
  min-width: 60px;
  max-width: 110px;
  height: 100%;
  text-align: center;
  color: #6c757d;
  overflow-wrap: break-word;
  hyphens: auto;
}
.nav-tabs .nav-link.active {
  border-right: 1px solid var(--wlp-bs-border-color);
  border-left: 1px solid var(--wlp-bs-border-color);
  border-radius: 0;
  padding-right: calc(0.4rem + 1px);
  padding-left: calc(0.4rem + 1px);
  color: #02bceb;
}
.nav-tabs .nav-link:hover:not(.active) {
  border-radius: 0;
  box-shadow: inset 0 -5px 8px -8px #c1c1c1;
  background-color: #f5f5f5;
}
.nav-tabs .nav-link .tab-icon {
  display: block;
  font-size: 1.5rem;
}
.nav-tabs .nav-link .tab-title {
  display: none;
}
@media (min-width: 576px) {
  .nav-tabs .nav-link .tab-title {
    display: block;
  }
}
@media (min-width: 576px) {
  .nav-tabs .nav-link {
    padding: calc(1rem / 2) calc(0.4rem + 2px);
    min-width: 90px;
  }
}
.nav-tabs .nav-item:first-child .nav-link.active {
  border-left: 0;
  padding-left: calc(0.4rem + 2px);
}

.cnx-loading {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  display: none;
  transform: translateZ(0);
  margin: 1em auto 3em;
  animation-delay: -0.16s;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}
@keyframes load {
  0%, 80%, 100% {
    box-shadow: 0 1em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
}
.cnx-loading.visible {
  display: block;
}
.cnx-loading::before, .cnx-loading::after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  content: "";
}
.cnx-loading::before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.cnx-loading::after {
  left: 1.5em;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.rate-location-details {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  overflow: hidden;
}
.rate-location-details::after {
  clear: both;
  content: "";
  display: table;
}

.pickup-location-header,
.dropoff-location-header,
.locations-header {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  background: #f5f5f5;
  cursor: pointer;
  padding: 12px;
}
.pickup-location-header:last-child,
.dropoff-location-header:last-child,
.locations-header:last-child {
  margin-right: 0;
}

@media screen and (min-width: 992px) {
  .pickup-location-header,
  .dropoff-location-header {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .locations-header {
    display: none;
  }
}

.rate-location-details-section {
  background: #fff;
  padding: 12px;
}
@media screen and (min-width: 992px) {
  .rate-location-details-section {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .rate-location-details-section:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 992px) {
  .rate-location-details-section {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .rate-location-details-section:last-child {
    margin-right: 0;
  }
}

.location-address-toggle {
  cursor: pointer;
  user-select: none;
  float: right;
  color: #f43369;
  font-size: 0.85em;
}
.location-address-toggle .hide-address {
  display: none;
}

.locations-header.open .rate-location-details-toggle-icon,
.rate-location-details.open .rate-location-details-toggle-icon,
.rate-location-details-section.open .rate-location-details-toggle-icon {
  transform: rotate(180deg);
}
.locations-header.open .hide-address,
.rate-location-details.open .hide-address,
.rate-location-details-section.open .hide-address {
  display: block;
}
.locations-header.open .show-address,
.rate-location-details.open .show-address,
.rate-location-details-section.open .show-address {
  display: none;
}
.locations-header.open .address,
.rate-location-details.open .address,
.rate-location-details-section.open .address {
  display: block;
}

.location-details {
  float: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}
.location-details .location-detail-time,
.location-details .location-detail {
  float: left;
  margin-bottom: 12px;
}
.location-details .location-detail-time i,
.location-details .location-detail i {
  float: left;
  margin-right: 4px;
  text-align: center;
  color: #424242;
}
@media screen and (max-width: 992px) {
  .location-details .location-detail-time,
  .location-details .location-detail {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .location-details .location-detail-time:last-child,
  .location-details .location-detail:last-child {
    margin-right: 0;
  }
}
.location-details .location-detail-time {
  clear: left;
  margin-bottom: 0;
}
.location-details .location-address-text {
  display: block;
  overflow: hidden;
  line-height: 18px;
}
.location-details .location-detail-text {
  float: left;
  margin: 0 12px 0 0;
  line-height: 18px;
}

.address {
  display: none;
  margin: 5px 0 0;
  line-height: 1.4;
}
.address.visible {
  display: block;
}
.address .address-line {
  display: block;
  margin: 0 0 2px;
}
.address .address-description {
  margin: 10px 0;
  font-size: 0.85em;
}
.address .get-directions {
  margin-top: 10px;
  text-decoration: none;
  color: #f43369;
}

.rate-location-details-subheader-text {
  float: left;
  margin: 0;
  font-size: 1em;
}

.rate-location-details-toggle-icon {
  float: right;
}

.manage-extras {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  margin: 0;
}
.manage-extras .manage-extras-header {
  margin: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #fff;
  padding: 12px;
  color: rgba(150, 31, 64, 0.9415);
  font-size: 18.2px;
  font-weight: bold;
}
.manage-extras .manage-extras-header i {
  float: right;
}
.manage-extras .manage-extras-header .manage-extras-subheader {
  display: block;
  margin-top: 6px;
  color: rgba(192, 40, 82, 0.9415);
  font-size: 12.5px;
  font-weight: normal;
}

.manage-extras-section .manage-extras-collapsible .available-extras {
  display: none;
}
.manage-extras-section.active .manage-extras-header i {
  transform: rotate(180deg);
}
.manage-extras-section.active .available-extras {
  display: block;
}
@media screen and (max-width: 767px) {
  .manage-extras-section {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .manage-extras-section:last-child {
    margin-right: 0;
  }
}

.available-extras {
  border-top: 1px solid #e0e0e0;
  background: #fff;
  margin: 0;
  padding: 0;
  list-style: none;
}

.available-extra {
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  padding: 10px;
}
.available-extra:last-of-type {
  border-bottom: 0;
}
.available-extra .available-extra-content {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.available-extra .available-extra-header {
  margin: 0 0 8px;
  font-size: 1.1em;
}
.available-extra .available-extra-price {
  font-weight: normal;
}
.available-extra .available-extra-add-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.available-extra .available-extra-add-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  margin-left: 10px;
  padding: 0 20px;
  text-align: center;
  white-space: nowrap;
}
.available-extra .available-extra-add-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.available-extra .available-extra-add-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.available-extra .available-extra-add-button:hover {
  color: rgb(255, 255, 255);
}
.available-extra .available-extra-button-disabled {
  box-shadow: inset 0 0 0 1px #e0e0e0;
  background: #eee;
  cursor: default;
  color: #bdbdbd;
}

.selected-extra {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;
}
.selected-extra .selected-extra-content {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.selected-extra .selected-extra-remove-button {
  margin-right: 8px;
  text-decoration: none;
  color: #db2118;
  font-size: 18px;
}
.selected-extra .selected-extra-text {
  font-weight: bold;
}
.selected-extra .selected-extra-total {
  display: table-cell;
  vertical-align: middle;
}

.map-search-page:not(.widget-body) .show-map-button-wrapper .show-map-button, .map-search .close-map-button, .map-search .reset-map-button, .map-search .redo-search-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  padding: 12px;
  line-height: 1;
  font-weight: normal;
}
.map-search-page:not(.widget-body) .show-map-button-wrapper .show-map-button:hover, .map-search .close-map-button:hover, .map-search .reset-map-button:hover, .map-search .redo-search-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.map-search-page:not(.widget-body) .show-map-button-wrapper .show-map-button:active, .map-search .close-map-button:active, .map-search .reset-map-button:active, .map-search .redo-search-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.map-search-page:not(.widget-body) .show-map-button-wrapper .show-map-button:hover, .map-search .close-map-button:hover, .map-search .reset-map-button:hover, .map-search .redo-search-button:hover {
  color: rgb(255, 255, 255);
}
.map-search-page:not(.widget-body) .show-map-button-wrapper .show-map-button i, .map-search .close-map-button i, .map-search .reset-map-button i, .map-search .redo-search-button i {
  margin-right: 3px;
}

.map-search-open {
  overflow: hidden;
}

.widget-body .map-search #map {
  height: 300px;
}
@media screen and (min-width: 992px) {
  :not(.widget-body) .map-search #map {
    height: 300px;
  }
}
.map-search .map-buttons-wrapper {
  display: none;
}
.map-search .redo-search-button {
  display: none;
  margin: 10px 0 0 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
}
.widget-body .map-search .redo-search-button {
  display: inline-block;
}
@media screen and (min-width: 992px) {
  :not(.widget-body) .map-search .redo-search-button {
    display: inline-block;
  }
}
.map-search .reset-map-button {
  display: none;
  margin: 10px 10px 0 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
}
.widget-body .map-search .reset-map-button {
  display: inline-block;
}
@media screen and (min-width: 992px) {
  :not(.widget-body) .map-search .reset-map-button {
    display: inline-block;
  }
}
.map-search .close-map-button {
  margin-bottom: 24px;
  font-size: 1.2em;
}
.widget-body .map-search .close-map-button {
  display: none;
}
@media screen and (min-width: 992px) {
  :not(.widget-body) .map-search .close-map-button {
    display: none;
  }
}
.widget-body .map-search {
  margin-bottom: 12px;
}
@media screen and (min-width: 992px) {
  :not(.widget-body) .map-search {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 992px) {
  .map-search-open .map-search {
    display: block;
    position: fixed;
    z-index: 99;
    inset: 50px 0 0;
  }
  .map-search-open .map-search #map {
    height: 100%;
  }
}

.show-map-button-wrapper {
  display: none;
}
.map-search-page:not(.widget-body) .show-map-button-wrapper {
  display: block;
  margin-bottom: 12px;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .map-search-page:not(.widget-body) .show-map-button-wrapper {
    display: none;
  }
}

.location-map-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.location-map-wrapper #locationMap {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
.location-map-wrapper .map-wrapper {
  flex: 1 1 auto;
}
.location-map-wrapper .modal-body {
  flex: 0 0 auto;
  max-height: 350px;
  overflow: auto;
}

.lightbox.location-map {
  z-index: 1000;
  background: none;
}

.confirmation-cancel-wrapper .cancellation-confirmation-popout {
  display: none;
}

.cancellation-details-cancel-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  margin: 12px 0 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.cancellation-details-cancel-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.cancellation-details-cancel-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.cancellation-details-cancel-button:hover {
  color: rgb(255, 255, 255);
}

.cancellation-confirmation-popout {
  padding: 12px;
}
.cancellation-confirmation-popout .cancellation-details-cancel-button {
  background-color: #f00;
  color: rgb(255, 255, 255);
}
.cancellation-confirmation-popout .cancellation-successful,
.cancellation-confirmation-popout .cancellation-failed {
  display: none;
}
.cancellation-confirmation-popout .cancellation-processing,
.cancellation-confirmation-popout .confirm-cancellation {
  display: none;
}
.cancellation-confirmation-popout .cancellation-processing.cancelling,
.cancellation-confirmation-popout .confirm-cancellation.cancelling {
  display: block;
}

.lightbox .lightbox-close-button.cancelling {
  display: none;
}

.lightbox-cancellation-popout .lightbox-vertical-center {
  max-width: 500px;
}
@media screen and (max-width: 767px) {
  .lightbox-cancellation-popout .lightbox-title-bar {
    display: none;
  }
}

.car-name-wrapper {
  margin: auto 0;
}

.car-img-wrapper {
  align-self: center;
  margin-right: 0.5rem;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.car-name {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.price-wrapper {
  align-self: center;
}

.car-price {
  margin-right: 0.5rem;
}

.car-img {
  border-radius: 3px;
  max-height: 65px;
}

.book-button {
  margin-left: 14px;
  padding: 10px 14px;
}

.button-wrapper {
  align-self: center;
}

.loading {
  background: url("/assets/common/images/loader.gif") no-repeat center;
}

.include-list {
  margin-bottom: 0;
  padding-left: 5px;
  list-style: none;
}
.include-list li {
  position: relative;
  margin-bottom: 5px;
}
.include-list .icon-check-solid {
  color: #3fde45;
}
.include-list .icon-warning {
  color: #f8ba10;
}

.rental-info-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.rental-info-wrapper .features {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dee2e6;
  background-color: #f0f0f0;
  padding: 5px 10px;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .rental-info-wrapper .features {
    border-right: 1px solid #dee2e6;
  }
}
.rental-info-wrapper .supplier {
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
}
.rental-info-wrapper .supplier .supplier-name {
  padding: 0 10px;
}
@media (min-width: 576px) {
  .rental-info-wrapper .supplier .supplier-name {
    max-width: 130px;
  }
}

.features .car-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.features .car-features li {
  display: flex;
  margin: 0 6px;
  text-align: center;
  color: #7c7c7c;
}
.features .car-features li [class*=icon-] {
  font-size: 120%;
}
@media (min-width: 1700px) {
  .features .car-features li [class*=icon-] {
    font-size: 150%;
  }
}
.features .car-features li span {
  display: block;
  margin-top: 3px;
}
@media (min-width: 1700px) {
  .features .car-features li {
    margin: 0 8px;
  }
}

.vendor-brand {
  --cnx-supplier-logo-height: 37px;
  display: block;
  float: right;
  margin: 0 0 0 5px;
  padding: 0;
  max-width: 100%;
}
.vendor-brand .vendor-img {
  display: block;
  position: relative;
  width: 100%;
  height: var(--cnx-supplier-logo-height);
}
.vendor-brand img {
  max-width: 100%;
  max-height: 100%;
}

.opening-hours tr {
  vertical-align: top;
}
.opening-hours th {
  min-width: 90px;
}
.opening-hours ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.handover-day {
  outline: 2px dotted #02bceb;
}

.read-more-wrapper {
  color: #000;
  line-clamp: 1;
}
.read-more-wrapper input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.read-more-wrapper label {
  margin-bottom: 0.5rem;
  color: #02bceb;
}
.read-more-wrapper .read-more-content {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.read-more-wrapper input:focus ~ label {
  outline: -webkit-focus-ring-color auto 5px;
}
.read-more-wrapper input:checked + .read-more-content {
  -webkit-line-clamp: unset;
}
.read-more-wrapper input:checked ~ label {
  display: none;
}

.insurance-box {
  background: #ffecac;
  user-select: none;
}

.btn-link {
  font-weight: 400;
  color: #02bceb;
  text-decoration: none;
}
.btn-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #004d70;
}

.optional-input-wrapper .optional-input {
  display: none;
  width: 100%;
}
.optional-input-wrapper .optional-input .optional-input-field {
  margin-top: 6px;
}
.optional-input-wrapper.active .have-optional {
  display: none;
}
.optional-input-wrapper.active .optional-input {
  display: block;
}

.promo-code-wrapper .promo-code {
  display: none;
  width: 100%;
}
.promo-code-wrapper .promo-code .promo-code-field {
  margin-top: 6px;
}
.promo-code-wrapper.active .have-promo-code {
  display: none;
}
.promo-code-wrapper.active .promo-code {
  display: block;
}

@media screen and (min-width: 768px) {
  .landing-container.promo-november-2018 {
    background-image: url("../images/bg-autumn-2017.jpg");
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 20%, rgba(50, 50, 50, 0.3) 50%), url("../images/bg-autumn-2017.jpg");
  }
  .landing-container.promo-november-2018 .homepage-marketing-copy-wrapper {
    display: none;
  }
}
.promotion-banner-wrapper {
  margin: 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
}
.promotion-banner-wrapper h2, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h2,
.promotion-banner-wrapper h3,
.promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h3,
:where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h3,
.promotion-banner-wrapper p {
  margin: 0;
}
.promotion-banner-wrapper h2, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h2 {
  margin-bottom: 10px;
  font-size: 1.8em;
}
.promotion-banner-wrapper h3, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h3, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h3 {
  margin-bottom: 15px;
  font-size: 1.3em;
}
.promotion-banner-wrapper h3 span, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h3 span, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h3 span {
  vertical-align: -0.13em;
  color: #f2b119;
  font-size: 1.4em;
}
.promotion-banner-wrapper p {
  font-size: 1em;
}
@media screen and (min-width: 768px) {
  .promotion-banner-wrapper {
    margin: 0 0 80px;
    background: none;
    padding: 50px 0 0;
    text-align: center;
  }
  .promotion-banner-wrapper h2, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h2 {
    margin-bottom: 15px;
    font-size: 3.5em;
  }
  .promotion-banner-wrapper h3, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h3, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h3 {
    font-size: 2.8em;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .promotion-banner-wrapper {
    margin-bottom: 50px;
    text-align: center;
  }
  .promotion-banner-wrapper h2, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h2, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h2 {
    margin-bottom: 10px;
    font-size: 1.7em;
  }
  .promotion-banner-wrapper h3, .promotion-banner-wrapper :where([data-cnx-widget], [data-cnx-container]) .h3, :where([data-cnx-widget], [data-cnx-container]) .promotion-banner-wrapper .h3 {
    font-size: 1.5em;
  }
}

.car-acriss {
  display: none;
  margin-bottom: 6px;
  border-bottom: 1px solid #e0e0e0;
  padding: 6px 12px;
  font-size: 1.1em;
  font-weight: bold;
}

.car-category {
  font-size: 1.1em;
  font-weight: bold;
}

.car-features-basic,
.car-features-cargo,
.car-features-descriptive {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 6px;
  /* some trickery to add margin only when any content */
}
.car-features-basic:has(*),
.car-features-cargo:has(*),
.car-features-descriptive:has(*) {
  margin-bottom: 6px;
}

@media screen and (min-width: 768px) {
  .car-features-basic,
  .car-features-cargo {
    display: inline-flex;
  }
}
.car-feature {
  white-space: nowrap;
}
.car-feature > .icon-dimensions-3d::before,
.car-feature > .icon-weight::before {
  font-size: 1.3em;
}
.car-feature > .icon-dimensions-3d::before {
  font-weight: 600;
}

.car-pickup-location {
  display: flex;
  align-items: center;
  margin: 3px;
  overflow: hidden;
  white-space: nowrap;
}

.car-pickup-location-icon {
  flex: 0 0 auto;
}

.car-price-wrapper {
  display: block;
}
@media screen and (min-width: 768px) {
  .car-price-wrapper {
    margin-top: 32px;
  }
}
@media screen and (max-width: 767px) {
  .car-price-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    padding: 12px;
  }
  .car-price-wrapper:last-child {
    margin-right: 0;
  }
}

.car-price-information,
.car-price-range {
  display: block;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
}

.car-price-range {
  padding-top: 2px;
  font-weight: bold;
}

.car-price-savings-badge {
  display: block;
  width: fit-content;
  font-size: 12.6px;
  margin-inline: auto;
}

.car-price-price {
  display: inline-block;
  padding-top: 3.5px;
  height: 30.5px;
  line-height: 30.5px;
  font-size: 30.5px;
  font-weight: bold;
}
.car-price-price.old-price {
  height: 15.25px;
  text-decoration: line-through;
  line-height: 15.25px;
  color: rgba(118, 118, 118, 0.922);
  font-size: 15.25px;
}

.car-price-total {
  display: block;
  padding-top: 7px;
  font-size: 14px;
}

.badge-ev {
  align-self: flex-start;
  background-color: #02bceb;
  padding: 0.4em 0.5em;
  font-size: 0.7rem;
}
.badge-ev .badge-icon-ev {
  padding-right: 0.25em;
  font-size: inherit;
}

.car-image-wrapper .more-details-wrapper,
.car-image-wrapper .pickup-location-wrapper {
  padding-bottom: 12px;
  text-align: center;
}
.car-image-wrapper .more-details-wrapper + .pickup-location-wrapper {
  margin-top: -12px;
}
.car-image-wrapper .car-image img {
  vertical-align: unset;
}
@media screen and (max-width: 767px) {
  .car-image-wrapper {
    margin-left: 12px;
  }
  .car-image-wrapper img {
    max-width: 220px;
  }
}
@media screen and (min-width: 768px) {
  .car-image-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 27.4966634515%;
    padding-left: 12px;
  }
  .car-image-wrapper:last-child {
    margin-right: 0;
  }
  .car-image-wrapper .car-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 120px;
    overflow: hidden;
  }
  .car-image-wrapper img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
  }
}

.package-more-info {
  display: block;
  cursor: pointer;
}
.package-more-info::after {
  clear: both;
  content: "";
  display: table;
}
.package-more-info .package-title {
  margin: 12px 0 0 0;
  padding-left: 12px;
}
.package-more-info .package-title i {
  float: right;
  margin-right: 12px;
}
.package-more-info .package-title.inclusive-header {
  color: #10ad12;
}
.package-more-info .package-title.special-offer-header {
  color: #0abad6;
}
@media screen and (max-width: 767px) {
  .package-more-info .package-title {
    margin: 0;
    padding: 12px 12px 0;
    text-align: center;
  }
  .package-more-info .package-title.special-offer-header {
    border-top: 0;
    background: #0abad6;
    padding-bottom: 12px;
    color: rgb(255, 255, 255);
    font-weight: normal;
  }
  .package-more-info .package-title.inclusive-header {
    border-top: 0;
    background: #10ad12;
    padding-bottom: 12px;
    color: rgb(255, 255, 255);
    font-weight: normal;
  }
}
@media screen and (max-width: 767px) {
  .package-more-info {
    border: 2px solid #e0e0e0;
    border-width: 2px 0 0;
  }
  .special-offer .package-more-info, .inclusive .package-more-info {
    border-top: 0;
  }
}

.package-more-info:nth-child(n+2) {
  margin-top: 12px;
}

.package-description {
  padding: 6px 12px;
  font-style: italic;
}
@media screen and (max-width: 767px) {
  .package-description {
    margin-top: 12px;
    font-size: 11px;
  }
}

.extra-charges {
  display: block;
  margin: 0 12px;
  padding: 12px 0 0;
  list-style: none;
}
.extra-charges::after {
  clear: both;
  content: "";
  display: table;
}

.extra-charge,
.extra-inclusion {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-bottom: 12px;
}
.extra-charge:last-child,
.extra-inclusion:last-child {
  margin-right: 0;
}
.extra-charge:nth-child(n),
.extra-inclusion:nth-child(n) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .extra-charge,
  .extra-inclusion {
    font-size: 13px;
  }
}

.extra-charge i {
  color: #000;
}

.extra-inclusion i {
  color: #424242;
}

.extra-charges.inclusive .extra-inclusion i,
.extra-charges.inclusive .inclusion-detail i,
.package.inclusive .extra-inclusion i,
.package.inclusive .inclusion-detail i {
  color: #10ad12;
}

.special-offer-more-info {
  border-bottom: 1px solid #0abad6;
}
.special-offer-more-info .extra-inclusion i,
.special-offer-more-info .inclusion-detail i {
  color: #0abad6;
}

.charge-details {
  margin-top: 3px;
  margin-left: 6px;
  border-left: 1px solid #e0e0e0;
  padding-left: 6px;
  font-size: 0.9em;
}

.car-insurance {
  border: 1px solid #e3ab31;
  border-radius: 4px;
  background: rgba(227, 171, 49, 0.05);
  display: none;
  margin-bottom: 6px;
  padding: 6px 12px;
}
.car-insurance .insurance-header {
  color: #e3ab31;
  font-size: 1.1em;
  font-weight: bold;
}
.car-insurance .car-marketing-item-icon {
  color: #e3ab31;
}
.car-insurance.has-cdw, .car-insurance.has-tp {
  display: block;
}
@media screen and (max-width: 767px) {
  .car-insurance {
    padding-bottom: 12px;
  }
}

.inclusive.car-insurance {
  border: 1px solid #10ad12;
  border-radius: 4px;
  background: rgba(16, 173, 18, 0.05);
}
.inclusive.car-insurance .insurance-header,
.inclusive.car-insurance .car-marketing-item-icon {
  color: #10ad12;
}

.car-insurance,
.car-marketing {
  padding-left: 12px;
  min-height: 29px;
}
.car-insurance::after,
.car-marketing::after {
  clear: both;
  content: "";
  display: table;
}

.car-marketing {
  position: relative;
  margin-top: 12px;
}

.car-marketing-single-item-line,
.car-marketing-two-line-item {
  float: left;
  padding-top: 6px;
  padding-right: 12px;
  width: 100%;
  min-height: 3rem;
}
.car-marketing-single-item-line .car-marketing-item,
.car-marketing-two-line-item .car-marketing-item {
  width: 100%;
}
.car-marketing-single-item-line .car-marketing-item-caption,
.car-marketing-two-line-item .car-marketing-item-caption {
  margin-bottom: 2px;
  font-size: 0.9em;
}

.car-marketing-two-line-item {
  width: 50%;
}
.car-marketing-two-line-item:last-of-type {
  padding-right: 0;
}

.car-marketing-item {
  float: left;
  width: 50%;
  font-size: 14px;
}
.car-marketing-item .car-marketing-item-content {
  display: block;
  float: none;
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 15px;
}
.car-marketing-item .car-marketing-item-icon-text,
.car-marketing-item .car-marketing-item-icon {
  display: block;
  float: left;
  width: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
}
.car-marketing-item .car-marketing-item-icon-text {
  display: block;
  float: left;
  line-height: 25px;
  font-size: 25px;
  font-weight: bold;
}
.car-marketing-item img {
  margin: 6px auto;
  width: 7px;
  height: 7px;
}

.car-marketing-line {
  display: flex;
  column-gap: 12px;
  margin-top: 18px;
  margin-inline: 12px;
}
.car-marketing-line > * {
  width: 100%;
}

.car-marketing-caption {
  margin-bottom: 2px;
  font-size: 0.9em;
}

.car-marketing-content {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.car-marketing-content .car-marketing-item-icon {
  flex: none;
  width: 25px;
  text-align: center;
  font-size: 13px;
}
.car-marketing-content .car-marketing-item-content {
  margin-block: 5px;
}

.car-marketing-terms-and-conditions-line {
  margin-top: 6px;
}
.car-marketing-terms-and-conditions-line > * {
  margin-top: 12px;
}
.car-marketing-terms-and-conditions-line .car-marketing-terms-and-conditions {
  align-self: flex-end;
  margin-top: 0;
  margin-inline: -12px;
}

.car-marketing-terms-and-conditions-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  margin-top: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  height: 35px;
  text-transform: uppercase;
  line-height: 35px;
  color: #f43369;
  display: block;
  margin-top: 0;
  padding: 6px;
  width: 100%;
  height: auto;
  line-height: 21px;
}

.car-marketing-special-offer {
  border: 1px solid #0abad6;
  border-radius: 4px;
  background: rgba(10, 186, 214, 0.05);
  margin-bottom: 12px;
  padding: 6px 12px;
}
.car-marketing-special-offer .car-marketing-special-offer-header {
  margin: 0;
  margin-bottom: 6px;
  color: #0abad6;
  font-size: 1.1em;
  font-weight: bold;
}
.car-marketing-special-offer .car-marketing-item-icon {
  color: #0abad6;
}

.car-marketing-coronavirus .car-marketing-item-content {
  color: #0abad6;
  font-weight: bold;
}

.car-header-text {
  margin-top: 0;
  margin-bottom: 0;
  padding: 18.4px 0 0 12px;
  line-height: 19.6px;
  font-size: 19.6px;
  font-weight: bold;
}
.car-header .car-header-text {
  float: none;
  overflow: hidden;
}
.car-header-text .car-header-subtext {
  white-space: nowrap;
}
.car-header-text small, .car-header-text :where([data-cnx-widget], [data-cnx-container]) .small, :where([data-cnx-widget], [data-cnx-container]) .car-header-text .small {
  color: #757575;
  font-size: 0.8em;
}

.vendor-branding {
  display: block;
  float: right;
  margin: 0 15px;
  padding: 17px 0 3px;
  max-width: 20%;
}
.vendor-branding .vendor-image {
  display: block;
  position: relative;
  width: 100%;
  height: 28px;
}
.vendor-branding img {
  max-width: 100%;
  max-height: 28px;
}
@media screen and (max-width: 767px) {
  .vendor-branding {
    max-width: 23%;
    text-align: center;
  }
}

.car {
  display: block;
  border-radius: 4px;
  background: #f5f5f5;
}
.car::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (max-width: 767px) {
  .car {
    background: #fff;
  }
}

.car-expanded {
  padding: 6px;
}
@media screen and (max-width: 767px) {
  .car-expanded {
    padding-right: 6px;
  }
}

.car-inner-panel,
.car-marketing-terms-and-conditions-button,
.base-book-button,
.more-details-button {
  user-select: none;
}

.car-inner-panel {
  position: relative;
  border-radius: 4px;
  background: #fff;
  min-height: 220px;
}
.car-inner-panel:hover {
  background: #fcfcfc;
}
.car-inner-panel:hover .more-details-button {
  background: #019eb4;
}

.front-panel::after {
  clear: both;
  content: "";
  display: table;
}

.car-image-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.car-info-panel {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 70.1456849506%;
  margin-right: 0;
  padding: 12px;
}
.car-info-panel:last-child {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .car-info-panel {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .car-info-panel:last-child {
    margin-right: 0;
  }
}

.more-details-button {
  position: absolute;
  top: 0;
  right: 0;
  transition: background 0.3s ease-in-out;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 12px;
  background: #01afc8;
  width: 30px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: rgb(255, 255, 255);
}

.car-header {
  display: block;
}
.car-header::after {
  clear: both;
  content: "";
  display: table;
}

.rate-card-with-price::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 768px) {
  .rate-card-with-price {
    margin-right: -6px;
  }
}

.rate-card-with-price .car-inner-panel {
  float: left;
  width: 76.76824%;
}
@media screen and (max-width: 767px) {
  .rate-card-with-price .car-inner-panel {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .rate-card-with-price .car-inner-panel:last-child {
    margin-right: 0;
  }
}

.package-book-wrapper {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 23.2317613015%;
  text-align: center;
}
.package-book-wrapper:last-child {
  margin-right: 0;
}
.package-book-wrapper.on-request {
  position: relative;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .package-book-wrapper.on-request {
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 767px) {
  .package-book-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
    display: flex;
    float: none;
    text-align: left;
  }
  .package-book-wrapper:last-child {
    margin-right: 0;
  }
}

.book-button-wrapper {
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .book-button-wrapper {
    margin-top: 36px;
  }
}
@media screen and (max-width: 767px) {
  .book-button-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    align-self: flex-end;
    padding: 12px;
    height: 72px;
    text-align: center;
  }
  .book-button-wrapper:last-child {
    margin-right: 0;
  }
}

.package-book-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: inline-block;
  padding: 12px;
  line-height: 14px;
  white-space: nowrap;
  font-size: 14px;
}
.package-book-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.package-book-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.package-book-button:hover {
  color: rgb(255, 255, 255);
}
@media screen and (max-width: 767px) {
  .package-book-button {
    padding: 12px;
    width: 100%;
    height: 100%;
    line-height: 24px;
  }
}

.car-package-placeholder {
  padding: 14px 0;
}

.car-package-placeholder,
.car-package-premium,
.car-package-special {
  margin-top: 10px;
  line-height: 14px;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .car-package-placeholder,
  .car-package-premium,
  .car-package-special {
    display: none;
  }
}

.car-package-inner {
  display: inline-block;
  padding: 14px 0;
  text-align: center;
  line-height: 14px;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .car-package-inner {
    position: relative;
    left: 6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 100%;
  }
  .car-package-inner::before {
    position: absolute;
    top: 42px;
    right: 0;
    border: solid;
    border-width: 0 0 6px 6px;
    content: "";
  }
}
@media screen and (max-width: 767px) {
  .car-package-inner {
    width: 100%;
  }
}

.car-package-premium .car-package-inner {
  background: #10ad12;
}
.car-package-premium .car-package-inner::before {
  border-color: transparent transparent transparent #0c7e0d;
}

.car-package-special .car-package-inner {
  background: #0abad6;
}
.car-package-special .car-package-inner::before {
  border-color: transparent transparent transparent #0890a5;
}

.car-on-request {
  display: block;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #dadada;
  padding-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
}
@media screen and (min-width: 768px) {
  .car-on-request {
    border: 0;
    padding-top: 0;
  }
}

.datepicker {
  background: #fff;
  width: 400px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .datepicker {
    width: 100%;
  }
}

.datepicker-selected-dates {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: none;
  background: #f43369;
  color: rgb(255, 255, 255);
}
.datepicker-selected-dates::after {
  clear: both;
  content: "";
  display: table;
}

.datepicker-currently-selecting {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #f43369;
  padding: 8px;
  color: rgb(255, 255, 255);
}
.datepicker-currently-selecting::after {
  clear: both;
  content: "";
  display: table;
}

.datepicker-selected {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 48.821174201%;
  border-bottom: 4px solid #f43369;
  cursor: pointer;
  padding: 12px 0 8px;
  text-align: center;
}
.datepicker-selected:last-child {
  margin-right: 0;
}
.datepicker-selected:hover {
  border-bottom: 4px solid #e0e0e0;
}
.datepicker-selected.selected {
  border-bottom: 4px solid #e0e0e0;
  font-weight: bold;
}

.datepicker-inner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px 60px;
  width: 100%;
  overflow-y: scroll;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
.datepicker-inner::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner:nth-child(7n) {
  margin-right: 0;
}
.datepicker-inner:nth-child(7n+1) {
  clear: left;
}
.datepicker-inner .datepicker-days {
  display: block;
}
.datepicker-inner .datepicker-days::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-days span {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 12.1430157118%;
  padding: 8px;
}
.datepicker-inner .datepicker-days span:last-child {
  margin-right: 0;
}
.datepicker-inner .datepicker-month {
  display: block;
  margin-top: 8px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background: #f43369;
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.datepicker-inner .datepicker-month::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-month span {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  padding: 12px 0;
}
.datepicker-inner .datepicker-month span:last-child {
  margin-right: 0;
}
.datepicker-inner .datepicker-dates {
  display: block;
}
.datepicker-inner .datepicker-dates::after {
  clear: both;
  content: "";
  display: table;
}
.datepicker-inner .datepicker-dates span {
  float: left;
  margin: 4px 0;
  padding: 8px;
  width: 14.2857143%;
}
.datepicker-inner.pickup .datepicker-pickup-date, .datepicker-inner.dropoff .datepicker-dropoff-date {
  border: 2px solid #f43369;
  border-radius: 24px;
  background: #f43369;
  color: rgb(255, 255, 255);
}
.datepicker-inner .datepicker-non-selectable-date {
  cursor: default;
  color: #e0e0e0;
}
.datepicker-inner .datepicker-selectable-date {
  cursor: pointer;
}
.datepicker-inner .datepicker-selectable-date:hover {
  border-radius: 24px;
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (min-width: 768px) {
  .datepicker-inner {
    max-height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .datepicker-inner {
    max-height: 100%;
  }
}

.menu-options {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 18px;
  height: 100%;
  color: #fff;
}
.menu-options::after {
  clear: both;
  content: "";
  display: table;
}

.language-caption, .language, .change-settings {
  margin-left: 10px;
  padding: 8px;
}

.language-caption {
  margin-top: 10px;
  color: #fff;
}

.language label {
  color: #fff;
}
.language .select-language {
  margin: 0.5em 0;
  width: 100%;
}
.language .select-language select {
  display: block;
  width: 100%;
}

.change-settings {
  color: #fff;
}

.submit-new-settings {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  text-align: center;
  text-decoration: none;
}
.submit-new-settings:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.submit-new-settings:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.submit-new-settings:hover {
  color: rgb(255, 255, 255);
}

.optional-input-wrapper .optional-input {
  display: none;
  width: 100%;
}
.optional-input-wrapper .optional-input .optional-input-field {
  margin-top: 6px;
}
.optional-input-wrapper.active .have-optional {
  display: none;
}
.optional-input-wrapper.active .optional-input {
  display: block;
}

.search-form .date-input input,
.search-form .date-input select,
.search-form .time-input input,
.search-form .time-input select, .search-form .autocomplete-box input, .optional-input-wrapper .optional-input .optional-input-field, .promo-code-wrapper .promo-code .promo-code-field {
  outline: none;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  height: 42px;
  text-align: left;
  line-height: 42px;
  appearance: none;
}

.search-form {
  display: block;
}
.search-form::after {
  clear: both;
  content: "";
  display: table;
}
.search-form .vehicle-group-wrapper {
  display: none;
}
.search-form .vehicle-group-wrapper:has(:where(.group-choice:nth-of-type(2))) {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.search-form .vehicle-group-picker {
  display: flex;
  align-items: stretch;
  gap: 12px;
}
.search-form .vehicle-group-picker .group-choice {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
.search-form .vehicle-group-picker .group-choice-radio {
  margin-top: 3%;
}
.search-form .vehicle-group-picker .icon-car {
  font-size: 1.2em;
}
.search-form .vehicle-group-picker .icon-truck {
  font-size: 1.9em;
}
.search-form .default-dropoff-panel {
  margin: 12px 0 6px;
  color: rgba(0, 0, 0, 0.87);
}
.search-form .default-dropoff-panel input {
  margin-right: 6px;
}
.search-form .dropoff-location-panel {
  margin-top: 12px;
}
.search-form .location-header,
.search-form .time-label,
.search-form .date-label {
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
}
.search-form .date-label {
  display: block;
  margin: 6px 0 3px;
  font-size: 14px;
}
.search-form .datetime-input {
  display: block;
}
.search-form .datetime-input::after {
  clear: both;
  content: "";
  display: table;
}
.search-form .date-input {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 57.3509785009%;
  height: 42px;
}
.search-form .date-input:last-child {
  margin-right: 0;
}
.search-form .time-input {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 40.2913699012%;
  height: 42px;
}
.search-form .time-input:last-child {
  margin-right: 0;
}
.search-form .autocomplete-box {
  margin: 6px 0 0;
}
.search-form .autocomplete-box input {
  line-height: 42px;
}
.search-form .date-input,
.search-form .time-input {
  display: inline-table;
  border-radius: 4px;
  background: #fff;
  table-layout: fixed;
}
.search-form .date-input input,
.search-form .date-input select,
.search-form .time-input input,
.search-form .time-input select {
  display: table-cell;
  border: 0;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  cursor: pointer;
  border-right: 1px solid #bdbdbd;
  border-radius: 0 var(--wlp-bs-border-radius) var(--wlp-bs-border-radius) 0;
}
.search-form .date-input select,
.search-form .time-input select {
  cursor: pointer;
  line-height: 42px;
  appearance: none;
}
.search-form .date-input input::-webkit-clear-button,
.search-form .time-input input::-webkit-clear-button {
  display: none;
  appearance: none;
}
.search-form .date-input input::-webkit-inner-spin-button,
.search-form .time-input input::-webkit-inner-spin-button {
  appearance: none;
}
.search-form .date-input input::-webkit-outer-spin-button,
.search-form .time-input input::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
.search-form .input-icon-right,
.search-form .input-icon {
  display: table-cell;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  cursor: pointer;
  width: 25px;
  vertical-align: middle;
  line-height: 40px;
  white-space: nowrap;
  color: #e0e0e0;
}
@media screen and (min-width: 992px) {
  .search-form .input-icon-right,
  .search-form .input-icon {
    width: 22px;
  }
}
.search-form .input-icon-right {
  border-right: 1px solid #bdbdbd;
  border-top-right-radius: var(--wlp-bs-border-radius);
  border-bottom-right-radius: var(--wlp-bs-border-radius);
  padding: 0 8px 0 0;
  display: none;
}
.search-form .input-icon {
  border-left: 1px solid #bdbdbd;
  border-top-left-radius: var(--wlp-bs-border-radius);
  border-bottom-left-radius: var(--wlp-bs-border-radius);
  padding: 0 0 0 8px;
}
.search-form .location-wrapper,
.search-form .timeframe-wrapper,
.search-form .grouped-inputs-wrapper {
  display: block;
  padding-bottom: 12px;
}
.search-form .location-wrapper::after,
.search-form .timeframe-wrapper::after,
.search-form .grouped-inputs-wrapper::after {
  clear: both;
  content: "";
  display: table;
}
.search-form .optional-inputs-wrapper {
  color: rgba(0, 0, 0, 0.87);
}
.search-form .vehicle-group-header,
.search-form .location-header,
.search-form .promo-code-label,
.search-form .source-market-label,
.search-form .reservation-form-label,
.search-form .timeframe-header,
.search-form .optional-input-label {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: rgba(205, 43, 88, 0.974);
}
.search-form .submit-wrapper {
  display: block;
  margin: 12px 0;
}
.search-form .submit-wrapper::after {
  clear: both;
  content: "";
  display: table;
}
.search-form .submit-wrapper .submit-button {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: #fff;
  user-select: none;
}
.search-form .submit-wrapper .submit-button:last-child {
  margin-right: 0;
}
.search-form .submit-wrapper .submit-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.search-form .submit-wrapper .submit-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.search-form .submit-wrapper .submit-button:hover {
  color: #fff;
}
.search-form.inline-autocomplete .overlay-autocomplete-results,
.search-form.inline-autocomplete .overlay-autocomplete {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .search-form.horizontal-portable .date-input,
  .search-form.horizontal-portable .time-input {
    width: 100%;
  }
  .search-form.horizontal-portable .time-input {
    margin-top: 6px;
  }
}
.search-form.horizontal-portable .overlay-autocomplete-results,
.search-form.horizontal-portable .overlay-autocomplete {
  display: none;
}
@media screen and (min-width: 768px) {
  .search-form.horizontal-portable .inline-autocomplete-results {
    position: absolute;
    bottom: 105%;
    z-index: 100;
    border: 1px solid #e0e0e0;
    background: #fff;
    max-height: inherit;
    overflow: inherit;
  }
  .search-form.horizontal-portable .inline-autocomplete-results::before {
    position: absolute;
    z-index: 99;
    border: solid;
    content: "";
    right: 48%;
    bottom: -7px;
    border-width: 7px 7px 0;
    border-color: #e0e0e0 transparent transparent;
  }
  .search-form.horizontal-portable .autocomplete-item {
    padding: 0;
    line-height: 32px;
  }
  .search-form.horizontal-portable .autocomplete-item:last-child {
    border-bottom: 0;
  }
  .search-form.horizontal-portable .location-header,
  .search-form.horizontal-portable .time-label,
  .search-form.horizontal-portable .date-label {
    margin: 0 0 6px;
    line-height: 21px;
  }
  .search-form.horizontal-portable .default-dropoff-panel {
    margin: 6px 0;
    line-height: 21px;
  }
  .search-form.horizontal-portable .date-label {
    font-size: 12px;
    font-weight: bold;
  }
  .search-form.horizontal-portable .location-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
    display: inline-block;
  }
  .search-form.horizontal-portable .location-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .timeframe-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .search-form.horizontal-portable .timeframe-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .timeframe-wrapper .timeframe-header {
    display: none;
  }
  .search-form.horizontal-portable .timeframe-wrapper .search-form-to-date,
  .search-form.horizontal-portable .timeframe-wrapper .search-form-from-date {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    position: relative;
  }
  .search-form.horizontal-portable .timeframe-wrapper .search-form-to-date:last-child,
  .search-form.horizontal-portable .timeframe-wrapper .search-form-from-date:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .grouped-inputs-wrapper,
  .search-form.horizontal-portable .promo-code-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .search-form.horizontal-portable .grouped-inputs-wrapper:last-child,
  .search-form.horizontal-portable .promo-code-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .grouped-inputs-wrapper::after,
  .search-form.horizontal-portable .promo-code-wrapper::after {
    clear: both;
    content: "";
    display: table;
  }
  .search-form.horizontal-portable .age-wrapper,
  .search-form.horizontal-portable .country-of-residence-selector-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .search-form.horizontal-portable .age-wrapper:last-child,
  .search-form.horizontal-portable .country-of-residence-selector-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .submit-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 14.7019570017%;
    margin-top: 27px;
    margin-right: 0;
  }
  .search-form.horizontal-portable .submit-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal-portable .submit-wrapper .submit-button {
    height: 42px;
    line-height: 42px;
  }
  .search-form.horizontal-portable .autocomplete-box {
    margin-top: 0;
  }
}
.search-form.horizontal .overlay-autocomplete-results,
.search-form.horizontal .overlay-autocomplete {
  display: none;
}
@media screen and (min-width: 992px) {
  .search-form.horizontal .inline-autocomplete-results {
    position: absolute;
    bottom: 105%;
    z-index: 100;
    border: 1px solid #e0e0e0;
    background: #fff;
    max-height: inherit;
    overflow: inherit;
  }
  .search-form.horizontal .inline-autocomplete-results::before {
    position: absolute;
    z-index: 99;
    border: solid;
    content: "";
    right: 48%;
    bottom: -7px;
    border-width: 7px 7px 0;
    border-color: #e0e0e0 transparent transparent;
  }
  .search-form.horizontal .autocomplete-item {
    padding: 0;
    line-height: 32px;
  }
  .search-form.horizontal .autocomplete-item:last-child {
    border-bottom: 0;
  }
  .search-form.horizontal .location-header,
  .search-form.horizontal .time-label,
  .search-form.horizontal .date-label {
    margin: 0 0 6px;
    line-height: 21px;
  }
  .search-form.horizontal .default-dropoff-panel {
    margin: 6px 0;
    line-height: 21px;
  }
  .search-form.horizontal .date-label {
    font-size: 12px;
    font-weight: bold;
  }
  .search-form.horizontal .location-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
    display: inline-block;
  }
  .search-form.horizontal .location-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .timeframe-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .search-form.horizontal .timeframe-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .timeframe-wrapper .timeframe-header {
    display: none;
  }
  .search-form.horizontal .timeframe-wrapper .search-form-to-date,
  .search-form.horizontal .timeframe-wrapper .search-form-from-date {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    position: relative;
  }
  .search-form.horizontal .timeframe-wrapper .search-form-to-date:last-child,
  .search-form.horizontal .timeframe-wrapper .search-form-from-date:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .grouped-inputs-wrapper,
  .search-form.horizontal .promo-code-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .search-form.horizontal .grouped-inputs-wrapper:last-child,
  .search-form.horizontal .promo-code-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .grouped-inputs-wrapper::after,
  .search-form.horizontal .promo-code-wrapper::after {
    clear: both;
    content: "";
    display: table;
  }
  .search-form.horizontal .age-wrapper,
  .search-form.horizontal .country-of-residence-selector-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .search-form.horizontal .age-wrapper:last-child,
  .search-form.horizontal .country-of-residence-selector-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .submit-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 14.7019570017%;
    margin-top: 27px;
    margin-right: 0;
  }
  .search-form.horizontal .submit-wrapper:last-child {
    margin-right: 0;
  }
  .search-form.horizontal .submit-wrapper .submit-button {
    height: 42px;
    line-height: 42px;
  }
  .search-form.horizontal .autocomplete-box {
    margin-top: 0;
  }
}
.search-form.fixed {
  position: relative;
}
.search-form.fixed .inline-autocomplete {
  display: none;
}
.search-form.fixed .overlay-autocomplete-results {
  position: absolute;
  background: #fff;
  height: 100%;
  max-height: 100%;
  inset: 0;
}
.search-form.fixed .overlay-autocomplete-results.hidden {
  display: none;
}
.search-form .age-input-panel {
  display: block;
}
.search-form .age-input-panel.hidden {
  display: none;
}
.search-form .age-wrapper,
.search-form .country-of-residence-selector-wrapper {
  margin-bottom: 6px;
}
.search-form .age-wrapper:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .search-form-desktop-panel .input-icon {
    padding-left: 6px;
    width: 20px;
  }
  .search-form-desktop-panel .time-input select,
  .search-form-desktop-panel .time-input input,
  .search-form-desktop-panel .date-input select,
  .search-form-desktop-panel .date-input input {
    padding-left: 6px;
  }
}

.alamo-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo36.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.avis-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo10.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.enterprise-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo61.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.europcar-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo30.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.national-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo62.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sixt-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo11.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hertz-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo8.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.thrifty-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo260.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dollar-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo64.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.global-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo51.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.buchbinder-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo90.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.firefly-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo197.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.goldcar-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo34.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.autoclick-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo323.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ok-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo257.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.keddy-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo290.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.interrent-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo247.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.easirent-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo212.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.autoclick-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo323.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.solmar-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo22.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.jace-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo321.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.maggiore-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo286.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.megadrive-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo252.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.lagorent-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo216.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.greenmotion-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo241.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.budget-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo57.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.centauro-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo59.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sicily-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo219.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.orlando-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo221.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dollar-loading-logo {
  background-image: url("https://static.carhire-solutions.com/images/supplier/logo/logo64.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.partner-loading-logos {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
}
.partner-loading-logos::after {
  clear: both;
  content: "";
  display: table;
}

.partner-loading-logo {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 14.7019570017%;
  position: relative;
  margin-top: 24px;
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  padding: 6px;
  height: 60px;
  overflow: hidden;
}
.partner-loading-logo:last-child {
  margin-right: 0;
}
.partner-loading-logo:nth-child(6n) {
  margin-right: 0;
}
.partner-loading-logo:nth-child(6n+1) {
  clear: left;
}
.partner-loading-logo.partner-loaded {
  border-color: rgba(244, 51, 105, 0.5);
}
@media screen and (max-width: 767px) {
  .partner-loading-logo {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
    padding: 6px;
  }
  .partner-loading-logo:last-child {
    margin-right: 0;
  }
  .partner-loading-logo:nth-child(3n) {
    margin-right: 0;
  }
  .partner-loading-logo:nth-child(3n+1) {
    clear: left;
  }
}

.loading-logo {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0.3;
  background-color: #fff;
  height: 100%;
}
.partner-loaded .loading-logo {
  opacity: 1;
}

.pulse-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pulse {
  margin: 0 auto;
  border-radius: 100%;
  background-color: rgba(244, 51, 105, 0.5);
  width: 60px;
  height: 100%;
  animation: pulse 1.3s infinite cubic-bezier(0.77, 0, 0.175, 1);
}
.partner-loaded .pulse {
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.search-results-info {
  display: block;
  display: block;
  position: relative;
  margin-bottom: 12px;
  line-height: 36px;
  font-size: 1.2em;
  user-select: none;
}
.search-results-info::after {
  clear: both;
  content: "";
  display: table;
}
.search-results-info .popular-search-results {
  display: inline-block;
}
.search-results-info .all-search-results {
  display: none;
}
.search-results-info .location-search-results {
  display: none;
}
.search-results-info .search-results-info-title {
  font-weight: bold;
}
.search-results-info .results-type {
  position: relative;
  z-index: 1;
  position: relative;
  z-index: 1;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  position: relative;
  float: right;
  margin: 0;
  cursor: pointer;
}
.search-results-info .results-type::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.search-results-info .results-type:active::after {
  transition: 0s;
  opacity: 1;
}
.search-results-info .results-type::before {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  content: "";
}
.search-results-info .results-type:hover::before {
  transition: 0s;
  opacity: 0.7;
}
.search-results-info .results-type:hover {
  background-color: #fafafa;
}
.search-results-info .results-type .most-popular,
.search-results-info .results-type .filtered {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: 6px 12px;
  height: 36px;
  line-height: 24px;
}
@media screen and (max-width: 767px) {
  .search-results-info .results-type {
    display: none;
  }
}
.search-results-info .reset-removes-filters {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  display: none;
  position: absolute;
  right: 0;
  bottom: 111%;
  border-radius: 4px;
  background: #fff;
  padding: 6px;
  width: 255px;
  text-align: center;
  line-height: normal;
  font-size: 14px;
}
.search-results-info .reset-removes-filters::before {
  position: absolute;
  z-index: 99;
  border: solid;
  content: "";
  right: 5%;
  bottom: -5px;
  border-width: 5px 5px 0;
  border-color: lightgray transparent transparent;
}
.search-results-info .filtered {
  background: #eee;
  color: #bdbdbd;
  font-weight: normal;
}
.search-results-info .most-popular {
  background: #fff;
  color: #f43369;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.search-results-info .popular-search-results,
.search-results-info .location-search-results,
.search-results-info .all-search-results {
  opacity: 1;
}
.search-results-info:active .popular-search-results,
.search-results-info:active .location-search-results,
.search-results-info:active .all-search-results {
  opacity: 0.6;
}
.search-results-info.show-all-search-results .location-search-results,
.search-results-info.show-all-search-results .popular-search-results {
  display: none;
}
.search-results-info.show-all-search-results .all-search-results {
  display: inline-block;
}
.search-results-info.show-all-search-results .filtered {
  background: #fff;
  color: #f43369;
  font-weight: bold;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.search-results-info.show-all-search-results .most-popular {
  background: #eee;
  color: #bdbdbd;
  font-weight: normal;
}
.search-results-info.show-all-search-results.has-active-filters .results-type:hover .reset-removes-filters {
  display: block;
}
.search-results-info.show-location-results .all-search-results,
.search-results-info.show-location-results .popular-search-results {
  display: none;
}
.search-results-info.show-location-results .location-search-results {
  display: inline-block;
}
.search-results-info.show-location-results .results-type {
  display: none;
}
@media screen and (max-width: 767px) {
  .search-results-info {
    text-align: center;
    line-height: 24px;
    font-size: 0.8em;
  }
}

.search-results-info-toggle {
  margin: 0 auto;
  width: 90%;
}
.search-results-info-toggle .search-results-info-toggle-header {
  margin: 6px 0;
  padding: 12px 0;
  color: rgba(205, 43, 88, 0.974);
}
.search-results-info-toggle .results-type-toggle {
  position: relative;
  z-index: 1;
  position: relative;
  z-index: 1;
  display: inline-block;
  border-radius: 4px;
  width: 100%;
}
.search-results-info-toggle .results-type-toggle::before {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  content: "";
}
.search-results-info-toggle .results-type-toggle:hover::before {
  transition: 0s;
  opacity: 1;
}
.search-results-info-toggle .results-type-toggle::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  content: "";
}
.search-results-info-toggle .results-type-toggle:active::after {
  transition: 0s;
  opacity: 1;
}
.search-results-info-toggle .most-popular,
.search-results-info-toggle .filtered {
  float: left;
  cursor: pointer;
  padding: 8px 0;
  width: 50%;
  text-align: center;
}
.search-results-info-toggle .most-popular {
  background: #fff;
  color: #f43369;
  font-weight: bold;
  border: 1px solid #f43369;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.search-results-info-toggle .filtered {
  background: #eee;
  color: #bdbdbd;
  font-weight: normal;
  border: 1px solid #bdbdbd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.search-results-info-toggle.show-all-search-results .most-popular {
  background: #eee;
  color: #bdbdbd;
  font-weight: normal;
  border: 1px solid #bdbdbd;
}
.search-results-info-toggle.show-all-search-results .filtered {
  background: #fff;
  color: #f43369;
  font-weight: bold;
  border: 1px solid #f43369;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rate-details-wrapper {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
}

.animate-root, .sidebar {
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidebar-shown-left .sidebar, .sidebar-shown-left .animate-root {
  transform: translateX(260px);
}

.sidebar {
  position: fixed;
  z-index: 50;
  background-color: #fff;
  width: 260px;
  height: 100%;
  overflow-y: auto;
}

.sidebar-left {
  left: -260px;
}

.sidebar-right {
  right: -260px;
}

.root {
  position: relative;
  z-index: 60;
  overflow: hidden;
  pointer-events: none;
}

.animate-root {
  pointer-events: auto;
}

.sidebar-shown-left {
  overflow-y: hidden;
}
.sidebar-shown-left .animate-root {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.sidebar-shown-right .sidebar, .sidebar-shown-right .animate-root {
  transform: translateX(-260px);
}

.sidebar-shown-right {
  overflow-y: hidden;
}
.sidebar-shown-right .animate-root {
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19), 0 -6px 6px rgba(0, 0, 0, 0.23);
}
.order-total-wrapper {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 40.2913699012%;
}
.order-total-wrapper:last-child {
  margin-right: 0;
}
.order-total-wrapper.sticky-scroll-active, .order-total-wrapper.sticky-scroll-proxy {
  margin-right: 0;
}
.order-total-wrapper .content-panel:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 992px) {
  .order-total-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .order-total-wrapper:last-child {
    margin-right: 0;
  }
}

.order-total-charges-wrapper {
  border-top: 1px solid #e0e0e0;
  background: #fff;
}
.order-total-charges-wrapper::after {
  clear: both;
  content: "";
  display: table;
}

.order-total-charge,
.order-total-charge-included,
.order-total-price-rate,
.order-total-price-now,
.order-total-price-total,
.order-total-price-later {
  display: block;
  padding: 6px 12px;
}
.order-total-charge::after,
.order-total-charge-included::after,
.order-total-price-rate::after,
.order-total-price-now::after,
.order-total-price-total::after,
.order-total-price-later::after {
  clear: both;
  content: "";
  display: table;
}
.order-total-charge .charge-description,
.order-total-charge .price-description,
.order-total-charge-included .charge-description,
.order-total-charge-included .price-description,
.order-total-price-rate .charge-description,
.order-total-price-rate .price-description,
.order-total-price-now .charge-description,
.order-total-price-now .price-description,
.order-total-price-total .charge-description,
.order-total-price-total .price-description,
.order-total-price-later .charge-description,
.order-total-price-later .price-description {
  float: none;
  overflow: hidden;
}
.order-total-charge .charge-price,
.order-total-charge .price-amount,
.order-total-charge-included .charge-price,
.order-total-charge-included .price-amount,
.order-total-price-rate .charge-price,
.order-total-price-rate .price-amount,
.order-total-price-now .charge-price,
.order-total-price-now .price-amount,
.order-total-price-total .charge-price,
.order-total-price-total .price-amount,
.order-total-price-later .charge-price,
.order-total-price-later .price-amount {
  float: right;
  text-align: right;
}

.order-total-charge-included-description {
  padding: 0 12px 6px;
  font-style: italic;
}

.order-total-price-rate .price-description,
.order-total-price-rate .price-amount,
.order-total-price-total .price-description,
.order-total-price-total .price-amount,
.order-total-price-now .price-description,
.order-total-price-now .price-amount {
  font-weight: bold;
}

.order-total-charge,
.order-total-price-total {
  border-top: 1px solid #e0e0e0;
}

.order-total-charge-included {
  padding: 3px 0;
  font-style: italic;
}

.order-total-price-now {
  background-color: #fcc2d2;
  color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid #fcc2d2;
}

.order-total-price-now,
.order-total-price-later,
.order-total-price-total {
  position: relative;
  padding: 12px;
  font-size: 1.1em;
}
.order-total-price-now i,
.order-total-price-later i,
.order-total-price-total i {
  position: absolute;
  left: 12px;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.order-total-price-now .price-description,
.order-total-price-later .price-description,
.order-total-price-total .price-description {
  padding-left: 24px;
}

.order-total-price-later {
  border-top: 1px solid #e0e0e0;
  color: rgba(150, 31, 64, 0.9415);
}
.order-total-price-later .charge-price,
.order-total-price-later .charge-description {
  padding-top: 10px;
  font-size: 0.9em;
  font-style: italic;
}

.order-total-book-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  float: none;
  margin: 0;
  overflow: hidden;
}
.order-total-book-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.order-total-book-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.order-total-book-button:hover {
  color: rgb(255, 255, 255);
}

.confirm-marketing {
  float: left;
  padding-right: 24px;
  line-height: 50px;
  color: #053405;
  font-size: 1.2em;
  font-style: italic;
}
@media screen and (max-width: 992px) {
  .confirm-marketing {
    float: none;
    text-align: center;
  }
}

.confirm-wrapper {
  padding: 0 12px 12px;
}
.confirm-wrapper::after {
  clear: both;
  content: "";
  display: table;
}

.order-total-reassurance {
  border: 1px solid #10ad12;
  background: #cfefd0;
  padding: 6px 12px;
  text-align: center;
  color: #10ad12;
  font-size: 1.1em;
  font-weight: bold;
}
.order-total-reassurance i {
  padding-right: 5px;
}
.no-payment-required .order-total-reassurance {
  display: none;
}

.pay-now-breakdown-open .pay-now-breakdown,
.pay-later-breakdown-open .pay-later-breakdown {
  display: block;
}

.pay-now-breakdown-open .order-total-price-now i,
.pay-later-breakdown-open .order-total-price-later i {
  transform: rotate(-0.5turn);
}

.pay-now-breakdown,
.pay-later-breakdown {
  display: none;
}

.pay-later-terms,
.pay-later-exchange {
  text-align: center;
  font-style: italic;
}

.pay-later-exchange-rate {
  font-weight: bold;
}

.comodo-secure {
  display: table-row;
  border-top: 1px solid #e0e0e0;
  background: #fff;
  padding: 12px;
}
.comodo-secure img {
  display: table-cell;
  padding: 12px;
  height: 83px;
  vertical-align: middle;
}
.comodo-secure .content-panel-subheader {
  display: table-cell;
  padding-right: 12px;
  width: 100%;
  vertical-align: middle;
}

.order-total-contact-detail {
  display: block;
  padding-top: 6px;
}
.order-total-contact-detail i {
  margin: 0 4px;
}
.order-total-contact-detail a {
  color: #f43369;
}

.order-total-contact-language-info {
  padding: 10px 0;
}

.marketing-notice {
  margin-bottom: 12px;
  border: 1px solid #10ad12;
  border-radius: 4px;
  padding: 6px 12px;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.map-search-page .marketing-notice {
  display: none;
}
.marketing-notice i {
  padding-right: 5px;
}
.marketing-notice .link-button {
  text-decoration: underline;
  font-weight: bold;
}
.marketing-notice.positive {
  border-color: #10ad12;
  background: #cfefd0;
  color: #10ad12;
}
.marketing-notice.urgent {
  border-color: #db2118;
  background: #f8d3d1;
  color: #db2118;
}
.marketing-notice.info, .marketing-notice.covid {
  border-color: rgba(205, 43, 88, 0.974);
  background: rgba(245, 214, 223, 0.9948);
  color: rgba(205, 43, 88, 0.974);
}
.marketing-notice.covid {
  font-weight: normal;
}
.marketing-notice.covid:not(.active) .text {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.marketing-notice.covid:not(.active) .read-less {
  display: none;
}
.marketing-notice.covid.active .read-more {
  display: none;
}
@media screen and (max-width: 767px) {
  .marketing-notice {
    font-size: 16px;
  }
}

.sticky-scroll-active {
  position: fixed;
}

.sticky-scroll-proxy.inactive {
  display: none;
}

.landing-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.landing-container::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 768px) {
  .landing-container {
    flex-direction: row;
    padding: 24px 12px;
  }
}

.homepage-marketing-panel {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 65.8807828007%;
  position: relative;
  text-shadow: 1px 1px 0 #000;
  color: #fff;
}
.homepage-marketing-panel:last-child {
  margin-right: 0;
}
.homepage-marketing-panel:nth-child(n) {
  margin-right: 0;
}
.homepage-marketing-panel .partners {
  background: none;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .homepage-marketing-panel {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .homepage-marketing-panel:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .homepage-marketing-panel {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .homepage-marketing-panel:last-child {
    margin-right: 0;
  }
}

.landing-content-wrapper {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 31.7615656014%;
  padding: 12px;
  position: relative;
  text-shadow: none;
}
.landing-content-wrapper:last-child {
  margin-right: 0;
}
.landing-content-wrapper:nth-child(3n) {
  margin-right: 0;
}
.landing-content-wrapper:nth-child(3n+1) {
  clear: left;
}
.landing-content-wrapper:has(.search-form .vehicle-group-wrapper .group-choice:nth-of-type(2)) > .desktop-autocomplete-results {
  --vehicle-group-height: 43px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .landing-content-wrapper:has(.search-form .vehicle-group-wrapper .group-choice:nth-of-type(2)) > .desktop-autocomplete-results {
    --vehicle-group-height: 51px;
  }
}
.landing-content-wrapper .date-input input {
  padding: 0 0 0 6px;
}
.landing-content-wrapper .autocomplete-item {
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .landing-content-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .landing-content-wrapper:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .landing-content-wrapper {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
  }
  .landing-content-wrapper:last-child {
    margin-right: 0;
  }
}

.homepage-marketing-copy-wrapper {
  display: table;
  margin: 0 auto;
}

.desktop-tagline {
  text-align: center;
  font-size: 1.3em;
}
.desktop-tagline .header-flavour-text {
  margin: 0.83em 0;
}
@media screen and (max-width: 767px) {
  .desktop-tagline {
    display: none;
  }
}

.mobile-tagline {
  text-align: center;
  text-shadow: 1px 1px 0 #000;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .mobile-tagline {
    display: none;
  }
}

.usps {
  margin: 0;
  padding: 12px;
  list-style: none;
  text-align: center;
  color: inherit;
  font-weight: bold;
}
@media screen and (min-width: 768px) {
  .usps .usp-item {
    margin-bottom: 8px;
    padding-right: 12px;
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .usps .usp-item {
    padding-top: 12px;
  }
}
.usps i {
  padding-right: 4px;
  color: #01afc8;
}

@media screen and (min-width: 768px) {
  .homepage-search-wrapper .inline-autocomplete-results {
    display: none !important;
  }
}

.desktop-autocomplete-results {
  --vehicle-group-height: 0px;
  display: none;
  position: absolute;
  top: 0;
  right: -105%;
  z-index: 999;
  margin-top: calc(22px + var(--vehicle-group-height));
}
.desktop-autocomplete-results.visible {
  display: block;
}
.desktop-autocomplete-results.dropoff-results {
  margin-top: calc(125px + var(--vehicle-group-height));
}
@media screen and (min-width: 992px) {
  .desktop-autocomplete-results {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
    display: none;
  }
  .desktop-autocomplete-results:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .desktop-autocomplete-results {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
    display: none;
    margin-top: calc(26px + var(--vehicle-group-height));
  }
  .desktop-autocomplete-results:last-child {
    margin-right: 0;
  }
  .desktop-autocomplete-results.dropoff-results {
    margin-top: calc(136px + var(--vehicle-group-height));
  }
}
@media screen and (max-width: 767px) {
  .desktop-autocomplete-results {
    display: none !important;
  }
}

.datepicker-pointer.dropoff::before, .datepicker-pointer.pickup::before {
  position: absolute;
  left: -12px;
  z-index: 99;
  border: solid;
  border-width: 12px 12px 12px 0;
  border-color: transparent #fff transparent transparent;
  content: "";
}

.datepicker-pointer.pickup::before {
  top: 190px;
}

.datepicker-pointer.dropoff::before {
  top: 260px;
}

.autocomplete-results-pointer::before {
  position: absolute;
  top: 19px;
  left: -12px;
  z-index: 99;
  border: solid;
  border-width: 12px 12px 12px 0;
  border-color: transparent #fff transparent transparent;
  content: "";
}

.landing-solid-background-panel {
  background: #fff;
  width: 100%;
}

.landing-more-info-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
}
.landing-more-info-container::after {
  clear: both;
  content: "";
  display: table;
}

.partners {
  display: block;
  background: #fff;
  padding-top: 18px;
  text-align: center;
}
.partners::after {
  clear: both;
  content: "";
  display: table;
}
.partners .partner-images {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  margin-top: 0;
  list-style: none;
}
.partners .partner-images::after {
  clear: both;
  content: "";
  display: table;
}
.partners .partner-image {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 14.7019570017%;
  height: 50px;
}
.partners .partner-image:last-child {
  margin-right: 0;
}
.partners .partner-image:nth-child(6n) {
  margin-right: 0;
}
.partners .partner-image:nth-child(6n+1) {
  clear: left;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .partners .partner-image {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
    margin-top: 12px;
  }
  .partners .partner-image:last-child {
    margin-right: 0;
  }
  .partners .partner-image:nth-child(3n) {
    margin-right: 0;
  }
  .partners .partner-image:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (max-width: 767px) {
  .partners .partner-image {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
    margin-top: 12px;
    padding: 2px 18px 0;
  }
  .partners .partner-image:last-child {
    margin-right: 0;
  }
  .partners .partner-image:nth-child(2n) {
    margin-right: 0;
  }
  .partners .partner-image:nth-child(2n+1) {
    clear: left;
  }
}
.partners .heading {
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 1.2em;
}
.partners img {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .partners {
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding-bottom: 0;
    max-width: 80%;
  }
}

.sidebar {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.sidebar-selectors {
  border-bottom: 1px solid #f43369;
  height: 50px;
  line-height: 42px;
}

.sidebar-selector {
  display: inline-block;
  width: 49%;
  text-align: center;
  font-size: 20px;
}
.sidebar-selector .header-link-currency-picker {
  top: 2px;
}
.sidebar-links-top,
.sidebar-links {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-links {
  border-top: 1px solid #f43369;
  border-bottom: 1px solid #f43369;
}

.sidebar-link {
  position: relative;
  border-top: 1px solid #f43369;
  background-color: #fff;
  padding: 12px;
  font-weight: 300;
}
.sidebar-link a {
  display: block;
  text-decoration: none;
  color: inherit;
}
.sidebar-link a:active {
  background-color: #01afc8;
}
.sidebar-link a:visited {
  color: rgba(0, 0, 0, 0.87);
}

.sidebar-links-top::after {
  clear: both;
  content: "";
  display: table;
}
.sidebar-links-top .sidebar-link-top {
  border-top: 1px solid #f43369;
  padding: 12px;
}

.sidebar-link-icon {
  display: block;
  float: left;
  margin-right: 6px;
}

.sidebar-link-text {
  display: block;
  float: none;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
}

.map-search-page .search-form-desktop-panel .search-form-panel {
  display: none;
}
.map-search-page .search-form-desktop-panel .filter-wrapper {
  margin-top: 0;
}

.accordion-group {
  display: block;
}
.accordion-group::after {
  clear: both;
  content: "";
  display: table;
}

.filter-toggle {
  float: right;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.search-summary-toggle,
.filter-toggle {
  cursor: pointer;
  user-select: none;
  position: relative;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 5px 10px;
  color: #f43369;
}
.search-summary-toggle.open,
.filter-toggle.open {
  border-bottom: 1px solid #fff;
  border-bottom-left-radius: 0;
}
.search-summary-toggle.inactive,
.filter-toggle.inactive {
  opacity: 0.5;
}
.search-summary-toggle .toggle-header,
.filter-toggle .toggle-header {
  float: left;
  overflow: hidden;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
}
.search-summary-toggle .toggle-header-no-emphasis,
.filter-toggle .toggle-header-no-emphasis {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: normal;
}
.search-summary-toggle .toggle-icon,
.filter-toggle .toggle-icon {
  float: right;
  margin-left: 15px;
  line-height: 42px;
}

.search-summary-toggle {
  float: none;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}
.search-summary-toggle .toggle-header {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-summary-toggle .toggle-icon {
  margin-left: 0;
}

.search-summary-wrapper {
  padding: 8px;
}

.search-datepicker-panel {
  display: none;
}
.search-datepicker-panel.visible {
  display: block !important;
}

.search-results-list {
  animation-name: fade-in;
  animation-duration: 0.3s;
}

.search-summary-wrapper,
.filter-wrapper {
  display: none;
  margin-top: -1px;
  border: 1px solid #e0e0e0;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
  padding-bottom: 24px;
  width: 100%;
  overflow-y: hidden;
}
.search-summary-wrapper.visible,
.filter-wrapper.visible {
  display: block;
}

.search-loading-container,
.search-results-container {
  min-height: 500px;
  overflow: hidden;
}
.search-loading-container::after,
.search-results-container::after {
  clear: both;
  content: "";
  display: table;
}

.search-results-container {
  display: none;
}
.search-results-container.loaded {
  display: block;
}
.search-results-container .autcomplete-item {
  color: rgba(0, 0, 0, 0.87);
}

.search-loading-container {
  display: none;
}
.search-loading-container.visible {
  display: block;
}
.search-loading-container .loading-text {
  margin-top: 12px;
  margin-bottom: 0;
  text-align: center;
  color: #f43369;
}

.search-form-panel {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 12px;
  background: #fff;
  padding-top: 12px;
}
.search-form-panel .search-form {
  display: block;
  display: block;
  margin: auto;
  width: 90%;
}
.search-form-panel .search-form::after {
  clear: both;
  content: "";
  display: table;
}

.search-form-desktop-panel .search-filters-panel {
  position: relative;
  z-index: 1;
}
.search-form-desktop-panel .search-filters-panel::after {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: 0 0 12px #e3ab31;
  width: 100%;
  height: 100%;
  animation-name: highlight;
  animation-duration: 4s;
  content: "";
}
@media screen and (min-width: 992px) {
  .search-form-desktop-panel {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 23.2317613015%;
  }
  .search-form-desktop-panel:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 992px) {
  .search-form-desktop-panel {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .search-filters-panel .filter-wrapper {
    border: 1px solid #e0e0e0;
    border-bottom: 2px solid #d5d5d5;
    margin-bottom: 24px;
    border-radius: 4px;
    background: #fff;
    display: block;
    background: #fff;
  }
}

@media screen and (min-width: 992px) {
  .search-results {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 74.4105871005%;
  }
  .search-results:last-child {
    margin-right: 0;
  }
}

.car-size-group {
  display: block;
  display: inline-block;
  margin-bottom: 12px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 12px;
  width: 100%;
}
.car-size-group::after {
  clear: both;
  content: "";
  display: table;
}
.car-size-group:nth-child(n+2) {
  margin-top: 12px;
}
.car-size-group:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}
.car-size-group .car-size-group-price-from {
  white-space: nowrap;
  color: #757575;
}

.promo-code-selection,
.car-size-group-title {
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
  margin: 0 0 12px;
  background: #fff;
  padding: 12px;
  width: 100%;
  color: rgba(150, 31, 64, 0.9415);
  font-size: 20px;
  font-weight: bold;
}

.promo-code-selection .promo-code {
  color: rgba(205, 43, 88, 0.974);
}

.car-size-group-subtext {
  margin: 0 0 12px;
  font-size: 16px;
  font-weight: normal;
}

.search-result-more-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  margin-top: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #fff;
  height: 35px;
  text-transform: uppercase;
  line-height: 35px;
  color: #f43369;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: block;
  margin: 12px;
}

.search-result-wrapper {
  margin-bottom: 12px;
}

.search-result {
  display: block;
  border: 1px solid #e0e0e0;
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 24px;
  border-radius: 4px;
  background: #fff;
}
.search-result::after {
  clear: both;
  content: "";
  display: table;
}

.feature-selected {
  display: none;
}

.reservation-page-section {
  display: none;
}
.reservation-page-section::after {
  clear: both;
  content: "";
  display: table;
}
.reservation-page-section.loaded {
  display: block;
}
.reservation-page-section:nth-child(1) {
  margin-bottom: 24px;
}
.reservation-page-section .content-panel-content label {
  display: block;
}
.reservation-page-section .content-panel-content p {
  margin: 1rem 0;
}
@media screen and (max-width: 992px) {
  .reservation-page-section {
    margin: 0 auto;
    max-width: 700px;
  }
}

.reservation-root {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 57.3509785009%;
}
.reservation-root:last-child {
  margin-right: 0;
}
@media screen and (max-width: 992px) {
  .reservation-root {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 100%;
    margin-bottom: 24px;
  }
  .reservation-root:last-child {
    margin-right: 0;
  }
}

.reservation-form-label {
  display: block;
  margin-top: 12px;
  font-size: 1.08em;
  font-weight: bold;
}

.reservation-form-label-subtext {
  display: block;
  margin: 4px 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.92em;
}

.reservation-form-input {
  margin: 4px 0;
  width: 100%;
  font-weight: normal;
}

.reservation-terms {
  display: block;
  padding-top: 12px;
}

.reservation-terms-checkbox {
  position: relative;
  top: 6px;
  float: left;
  margin-right: 12px;
}

.reservation-terms-text {
  display: block;
  float: none;
  overflow: hidden;
  font-weight: normal;
}
.reservation-terms-text a {
  color: #f43369;
}

.reservation-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  display: block;
  margin: 15px 0 0;
  width: 100%;
  text-align: center;
}
.reservation-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.reservation-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.reservation-button:hover {
  color: rgb(255, 255, 255);
}
.reservation-button i {
  font-size: 16px;
}

.reservation-form.loading .reservation-button {
  position: relative;
}
.reservation-form.loading .reservation-button .reservation-button-label,
.reservation-form.loading .reservation-button .icon-chevron-right {
  visibility: hidden;
}
.reservation-form.loading .reservation-button .reservation-button-loading {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  display: none;
  transform: translateZ(0);
  margin: 1em auto 3em;
  animation-delay: -0.16s;
  color: rgb(255, 255, 255);
  font-size: 16px;
  display: block;
  position: absolute;
  margin: 8px auto;
  inset: 0;
}
@keyframes load {
  0%, 80%, 100% {
    box-shadow: 0 1em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
}
.reservation-form.loading .reservation-button .reservation-button-loading.visible {
  display: block;
}
.reservation-form.loading .reservation-button .reservation-button-loading::before, .reservation-form.loading .reservation-button .reservation-button-loading::after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: load 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  content: "";
}
.reservation-form.loading .reservation-button .reservation-button-loading::before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.reservation-form.loading .reservation-button .reservation-button-loading::after {
  left: 1.5em;
}

.reservation-payment-providers {
  display: inline-block;
  margin-top: 12px;
}
.reservation-payment-providers .reservation-payment-providers-label {
  display: inline-block;
  float: left;
  margin: 0;
  line-height: 36px;
  font-weight: bold;
}

.reservation-age::after {
  clear: both;
  content: "";
  display: table;
}
.reservation-age .reservation-age-label {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 31.7615656014%;
}
.reservation-age .reservation-age-label:last-child {
  margin-right: 0;
}
.reservation-age .reservation-age-label select {
  margin-top: 6px;
  width: 100%;
}

.reservation-failure-contact-wrapper .reservation-failure-subtext {
  margin-bottom: 1em;
}
.reservation-failure-contact-wrapper .contact-detail {
  display: block;
  margin: 8px 0;
  text-align: center;
  font-weight: bold;
}

.reservation-failure .api-error-paragraph {
  margin: 20px;
  padding: 12px;
}
.reservation-failure .api-error-button {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  font-weight: bold;
  border: none 0;
  background-color: #f43369;
  color: rgb(255, 255, 255);
  user-select: none;
  border: 1px solid #f43369;
  padding: 10px 12px;
  line-height: 14px;
  font-size: 14.5px;
}
.reservation-failure .api-error-button:hover {
  background-color: #dc2e5f;
  color: rgb(255, 255, 255);
}
.reservation-failure .api-error-button:active {
  background-color: #c32954;
  color: rgb(255, 255, 255);
}
.reservation-failure .api-error-button:hover {
  color: rgb(255, 255, 255);
}
.reservation-failure .api-error-button.home-btn {
  background: #fff;
  color: #f43369;
}

.confirmation-banner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  position: relative;
  background: linear-gradient(to bottom, rgba(50, 50, 50, 0.3) 40%, rgba(0, 0, 0, 0.5) 90%), url("https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  text-shadow: 1px 1px 0 #000;
  color: #fff;
}
.confirmation-banner::after {
  clear: both;
  content: "";
  display: table;
}
.confirmation-banner .confirmation-header {
  margin: 10px 0;
}
.confirmation-banner .confirmation-header-subtext {
  margin: 0;
  font-size: 1.1em;
}
@media screen and (max-width: 767px) {
  .confirmation-banner {
    background-position: center center;
  }
}

.confirmation-header-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  text-align: center;
}
.confirmation-header-wrapper::after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (min-width: 768px) {
  .confirmation-section {
    margin: 0 auto;
    max-width: 700px;
  }
}

.confirmation-section-header {
  margin: 12px 13px 8px;
}

.confirmation-section-subtext {
  margin: 8px 13px 16px;
}

.confirmation-details-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.confirmation-details-wrapper .confirmation-details-subheader {
  background: #f5f5f5;
  overflow: hidden;
}
.confirmation-details-wrapper .confirmation-details-subheader:nth-child(1) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.confirmation-details-wrapper .confirmation-details-subheader-text {
  margin: 0;
  padding: 8px 13px;
  font-size: 1em;
}
.confirmation-details-wrapper .confirmation-details-section {
  background: #fff;
  padding: 10px 13px;
}
.confirmation-details-wrapper .confirmation-details-section:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.confirmation-container .charge-remove-button {
  display: none;
}

.text-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  padding: 12px;
}
.text-content::after {
  clear: both;
  content: "";
  display: table;
}

.text-content-header {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 150px;
}
.text-content-header::after {
  clear: both;
  content: "";
  display: table;
}
.text-content-header .text-content-tagline {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}

.jumbotron {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  background: #fff;
  height: 200px;
}
.jumbotron::after {
  clear: both;
  content: "";
  display: table;
}
.jumbotron h1, .jumbotron :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .jumbotron .h1 {
  float: left;
  margin: 20px 0 0 -12px;
  background: #01afc8;
  padding: 10px 36px;
  font-size: 1.8em;
}
@media screen and (min-width: 992px) {
  .jumbotron h1, .jumbotron :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .jumbotron .h1 {
    margin-left: -24px;
  }
}
.jumbotron a {
  color: rgba(0, 0, 0, 0.87);
}

.content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
  box-shadow: 0 10px 15px -5px #d9d9d9;
  background: #fff;
  padding: 12px 24px;
}
.content::after {
  clear: both;
  content: "";
  display: table;
}
.content h1, .content :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .content .h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 2.5em;
}
@media screen and (min-width: 992px) {
  .content h1, .content :where([data-cnx-widget], [data-cnx-container]) .h1, :where([data-cnx-widget], [data-cnx-container]) .content .h1 {
    margin-bottom: 0.75em;
  }
}
.content a {
  color: #f43369;
}
.content p {
  line-height: 1.6em;
}
.content ul,
.content ol {
  line-height: 1.6em;
}
.content table {
  line-height: 1.6em;
}
.content table th {
  text-align: left;
}
.content .image-frame {
  margin: 0 0 12px;
  border: 3px solid #fff;
  box-shadow: 1px 2px 2px 1px #ccc;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .content .image-frame {
    float: right;
    margin: 12px 0 24px 24px;
    max-width: 350px;
  }
  .content .image-frame.align-left {
    float: left;
  }
}
@media screen and (min-width: 992px) {
  .content {
    padding: 36px 48px;
  }
}

.manage-booking-wrapper .text-content-header {
  background-image: url("https://static.carhire-solutions.com/images/wlp/jumbotron-road.jpg");
}
.manage-booking-wrapper .text-content-header .text-content-tagline {
  top: 40%;
  text-shadow: 1px 1px 0 #000;
}
.manage-booking-wrapper .card {
  margin-top: -30px;
  max-width: 400px;
}

.header {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  height: 50px;
  color: #000;
}
.header::after {
  clear: both;
  content: "";
  display: table;
}

.simple-header-content {
  display: flex;
  justify-content: space-between;
  padding: 4px 12px;
  height: 100%;
}

.partner-logo {
  margin-left: 12px;
  white-space: nowrap;
}
.partner-logo a {
  text-decoration: none;
}

.partner-logo-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
}

.header-link-carhire {
  padding-left: 20px;
  vertical-align: middle;
  color: #01afc8;
  font-size: 1.5rem;
  font-weight: bolder;
  display: none;
}
@media screen and (max-width: 992px) {
  .header-link-carhire {
    overflow: hidden;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  .header-link-carhire {
    display: none;
  }
}

.header-links-wrapper {
  display: flex;
  align-items: center;
}

.header-links,
.header-selectors {
  display: inline-block;
}

.header-selectors {
  padding: 0 6px;
  font-size: 18px;
}

.header-selector {
  display: inline-block;
  vertical-align: middle;
}

.header-link-contact-info {
  display: inline-block;
  white-space: nowrap;
  font-size: 0.9rem;
}
.header-link-contact-info a {
  padding: 5px;
  text-decoration: none;
  color: #01afc8;
}
@media screen and (max-width: 767px) {
  .header-link-contact-info {
    display: none;
  }
}

.header-links {
  font-size: 18px;
}

.header-link {
  display: inline-block;
}

.header-link,
.header-link-language-picker,
.header-link-currency-picker {
  position: relative;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.header-link i,
.header-link-language-picker i,
.header-link-currency-picker i {
  padding: 4px;
  vertical-align: middle;
}

.header-link-language-picker,
.header-link-currency-picker {
  display: table;
  position: relative;
  margin: 0 auto;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.header-link-language-picker i,
.header-link-currency-picker i {
  padding: 4px;
  vertical-align: middle;
}
.header-link-language-picker[data-display-mode=ul]:hover,
.header-link-currency-picker[data-display-mode=ul]:hover {
  background-color: #f77096;
}
.header-link-language-picker[data-display-mode=ul]:hover .currency-hidden-list,
.header-link-currency-picker[data-display-mode=ul]:hover .currency-hidden-list {
  display: block;
}
.header-link-language-picker[data-display-mode=ul]:hover .language-picker-selected,
.header-link-language-picker[data-display-mode=ul]:hover .currency-picker-selected,
.header-link-currency-picker[data-display-mode=ul]:hover .language-picker-selected,
.header-link-currency-picker[data-display-mode=ul]:hover .currency-picker-selected {
  color: rgba(0, 0, 0, 0.87);
}
.header-link-language-picker .language-picker-selected,
.header-link-language-picker .currency-picker-selected,
.header-link-currency-picker .language-picker-selected,
.header-link-currency-picker .currency-picker-selected {
  display: table-row;
}
.header-link-language-picker .language-hidden-select,
.header-link-language-picker .currency-hidden-select,
.header-link-currency-picker .language-hidden-select,
.header-link-currency-picker .currency-hidden-select {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
  width: 100%;
  min-width: 50px;
  color: rgba(0, 0, 0, 0.87);
}
.header-link-language-picker .language-hidden-list,
.header-link-language-picker .currency-hidden-list,
.header-link-currency-picker .language-hidden-list,
.header-link-currency-picker .currency-hidden-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  margin: 0;
  box-shadow: 0 0 4px #ccc;
  background: #fff;
  padding: 0;
  width: 140px;
  list-style: none;
}
.header-link-language-picker .language-hidden-list-item,
.header-link-language-picker .currency-hidden-list-item,
.header-link-currency-picker .language-hidden-list-item,
.header-link-currency-picker .currency-hidden-list-item {
  border-bottom: 1px solid #e4e4e4;
}
.header-link-language-picker .language-hidden-list-item a,
.header-link-language-picker .currency-hidden-list-item a,
.header-link-currency-picker .language-hidden-list-item a,
.header-link-currency-picker .currency-hidden-list-item a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: #666;
}
.header-link-language-picker .language-hidden-list-item a:hover,
.header-link-language-picker .currency-hidden-list-item a:hover,
.header-link-currency-picker .language-hidden-list-item a:hover,
.header-link-currency-picker .currency-hidden-list-item a:hover {
  background-color: #f4f4f4;
}

.header-link-language-picker .language-picker-flag {
  display: inline-block;
  max-height: 32px;
  vertical-align: middle;
}

.sidebar-toggle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 12px;
  height: 50px;
  line-height: 50px;
}
.sidebar-toggle i {
  display: block;
  font-size: 28px;
}

.btn-manage-booking {
  --wlp-bs-btn-color: #fff;
  --wlp-bs-btn-bg: #f43369;
  --wlp-bs-btn-border-color: #fff;
  --wlp-bs-btn-hover-color: #fff;
  --wlp-bs-btn-hover-bg: #cf2b59;
  --wlp-bs-btn-hover-border-color: #cccccc;
  --wlp-bs-btn-focus-shadow-rgb: 255, 255, 255;
  --wlp-bs-btn-active-color: #fff;
  --wlp-bs-btn-active-bg: #c32954;
  --wlp-bs-btn-active-border-color: #bfbfbf;
  --wlp-bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --wlp-bs-btn-disabled-color: #fff;
  --wlp-bs-btn-disabled-bg: #f43369;
  --wlp-bs-btn-disabled-border-color: #fff;
}

@media screen and (max-width: 767px) {
  .partner-logo {
    display: block;
    margin: 0 auto;
    max-width: 50%;
    text-align: center;
  }
  .header-links-wrapper {
    display: none;
  }
  .sidebar-toggle {
    display: block;
  }
}
.footer {
  margin-top: 25px;
  background: #fff;
}

.footer-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px 12px;
}
.footer-content::after {
  clear: both;
  content: "";
  display: table;
}

@media screen and (min-width: 768px) {
  .fixed-bg {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .text-content-header,
  .landing-container {
    background-image: url("https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg");
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 20%, rgba(50, 50, 50, 0.3) 50%), url("https://static.carhire-solutions.com/images/wlp/other/homepage-bg-legacy.jpg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .header,
  .text-content-header,
  .text-content,
  .breadcrumbs-panel,
  .view-container,
  .landing-container,
  .header-content-desktop,
  .confirmation-banner {
    position: relative;
  }
  .header::before,
  .text-content-header::before,
  .text-content::before,
  .breadcrumbs-panel::before,
  .view-container::before,
  .landing-container::before,
  .header-content-desktop::before,
  .confirmation-banner::before {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
  }
}